type Props = {
    className?: string,
}

export const Bolt = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} width="20" height="20" viewBox="0 0 20 20">
            <g className="nc-icon-wrapper" fill="currentColor">
                <polygon points="11 2.5 10.5 8 16 8 9 17.5 9.5 12 4 12 11 2.5" fill="none" stroke="currentColor"
                         strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polygon>
            </g>
        </svg>
    )
}