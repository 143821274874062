
type Props = {
    className?: string,
}

export const Write = ({className}: Props) => {
    return (
        <svg className={'w-5 h-5 ' + (className || '')} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0703125" y="-0.00585938" width="18" height="18" rx="4" fill="currentColor" fillOpacity="0.3" />
            <path d="M11.6613 6.49622L5.93201 12.2255" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7.84155 6.49591H11.661V10.3154L7.84155 6.49591Z" fill="currentColor" stroke="currentColor" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
