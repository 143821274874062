import { BaseObject } from '../common/BaseObject';
import { Repository } from '../common/Repository';
import { BaseSymbol } from '../symbols/BaseSymbol';

export interface SerializedLink {
	id: string;
	type: string;
	source?: string;
	target?: string;
	attributes: unknown;
	isDuplicate?: boolean;
	forShared?: boolean;
	allLinks: SerializedLink[];
	sourceAttributes: Record<string, unknown>;
	targetAttributes: Record<string, unknown>;
}

export abstract class BaseLink extends BaseObject{
	readonly isShared: boolean = false;

	originalData: SerializedLink;

	protected _source?: BaseSymbol;
	protected _target?: BaseSymbol;

    constructor (json: SerializedLink) {
        super();

		this.originalData = json;
		this.originalData.attributes = this.originalData.attributes || {};
		this.id = json.id;

		this.source = Repository.symbols.get(json.source!) || Repository.sharedSymbols.get(json.source!);
		this.target = Repository.symbols.get(json.target!) || Repository.sharedSymbols.get(json.target!);
	}

	get source() {
		return this._source;
	}
	set source(value) {
		if (this._source === value) return;
		if (this._source) {
			this._source.unlinked.dispatch('output', this);
		}
		this._source = value;
		if (this._source) {
			this._source.linked.dispatch('output', this);
		}
	}

	get target() {
		return this._target;
	}

	set target(value) {
		if (this._target === value) return;
		if (this._target) {
			this._target.unlinked.dispatch('input', this);
		}
		this._target = value;
		if (this._target) {
			this._target.linked.dispatch('input', this);
		}
	}
}