import * as signals from 'signals';

import { BaseObject } from '../common/BaseObject';
import { BaseLink } from '../links/BaseLink';

export interface SerializedSymbol {
    id: string;
	external_id: string;
	type: string;
	typeLabel?: string,
	theme?: string;
	title?: string;
	url?: string;
	attributes: unknown;
	isAutomation: boolean; // this flag should be in database soon
	isDuplicate?: boolean;
	originalId?: string;
	isShared?: boolean;
}

export abstract class BaseSymbol extends BaseObject{
	readonly isSymbol: boolean = true;
	readonly isShared: boolean = false;

	originalData: SerializedSymbol;

	readonly links: BaseLink[] = [];
	readonly inputLinks: BaseLink[] = [];
	readonly outputLinks: BaseLink[] = [];

	linked: signals.Signal;
	unlinked: signals.Signal;

    constructor (json: SerializedSymbol) {
		super();

		this.linked = new signals.Signal();
		this.linked.add(this.onLinked, this);

		this.unlinked = new signals.Signal();
		this.unlinked.add(this.onUnlinked, this);

		this.originalData = json;
		this.originalData.attributes = this.originalData.attributes || {};
		this.id = json.id;
	}

	/**
	 * Iterate over all links and find the neighboring symbols.
	 */
	get neighbors(): BaseSymbol[] {
		const symbols = this.links.map(link => link.target === this ? link.source! : link.target! );
		return symbols.filter(symbol => !!symbol);
	}

	get shared(): BaseSymbol | undefined {
		const shared = this.neighbors.filter(symbol => symbol.isShared);

		if(shared.length > 0){
			return shared[0] as BaseSymbol;
		}else{
			return undefined;
		}
	}

	/**
	 * Iterate over all neighbors, find the shared symbol, and then find all duplicates by getting all neighbors of the shared symbol minus itself.
	 */
	get duplicates(): BaseSymbol[] {
		const duplicates: BaseSymbol[] = [];

		this.shared?.neighbors.forEach((neighborSymbol) => {
			if(neighborSymbol.id !== this.id){
				duplicates.push(neighborSymbol);
			}
		});

		return duplicates;
	}

	onLinked(type: string, link: BaseLink){
		if(type === "input"){
			this.inputLinks.push(link);
		} else if(type === "output"){
			this.outputLinks.push(link);
		}

		this.links.push(link);
	}

	onUnlinked(type: string, link: BaseLink){
		if(type === "input"){
			this.inputLinks.splice(this.inputLinks.indexOf(link));
		} else if(type === "output"){
			this.outputLinks.splice(this.outputLinks.indexOf(link));
		}

		this.links.splice(this.links.indexOf(link));
	}

}