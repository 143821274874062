import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { LINK_INDICATOR_SIZE } from '../common/constants';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';
import { ExtensionTint } from '../utils/materials/extensions/ExtensionTint';
import { CosmosThree } from '../CosmosThree';

export class LinkIndicator extends BaseObject{

    constructor(){
        super();
    }

    static getGeometry(): THREE.BufferGeometry {
        const shape = new THREE.Shape();
        const size = LINK_INDICATOR_SIZE;
    
        // Define the vertices of the triangle with one point 1.5 times longer
        shape.moveTo(0, size * 0.65); // Top vertex (1.5 times longer)
        shape.lineTo(-size / 2, -size / 2); // Bottom left vertex
        shape.lineTo(size / 2, -size / 2); // Bottom right vertex
        shape.lineTo(0, size * 0.65); // Close the path
    
        const geometry = new THREE.ShapeGeometry(shape, 1).rotateX( -Math.PI / 2 );
    
        geometry.clearGroups();

        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionTint, ExtensionOpacity],
            {
                color: CosmosThree.linksIndicatorColor,
                transparent: true,
                alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                //wireframe: true
            },
            { debug: false }
        );

        return material;
    }
}