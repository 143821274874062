import type {LayoutCoordinates} from './LayoutManager.ts';
import type {WorkerSymbol} from './LayoutWorker.ts';

/*import {FEATURES} from '../../../store/Features.ts';*/
import {Repository} from '../../common/Repository.ts';
import { useAppStore } from '../../../store/Store.ts';
/*import { md5 } from 'js-md5';*/
import {Logger} from '../../../utils/Logger.ts';
import { Symbol } from '@/three/symbols/Symbol.ts';
import { Link } from '@/three/links/Link.ts';

export interface StoredLayout {
    symbolsAndLinksIndex: string // symbols and links index computed by symbol/link ids and symbol group ids, links target/source ids
    // whenever this index will change for a mesh/space, the old stored layouts should be discarded
    layoutCoordinates: LayoutCoordinates,
    dateStored: string,
    dateLastUsed?: string
}

export interface CacheResult {
    symbolsAndLinksIndex: string,
    layoutCoordinates?: LayoutCoordinates
}

export class LayoutCacheManager {

    private _storedLayouts: Map<string, StoredLayout>;

    constructor() {

        this._storedLayouts = new Map();

        // load from local storage
        const storageKey = LayoutCacheManager.getStorageKey();
        if (storageKey) {
            try {
                const rawData = localStorage.getItem(storageKey);
                if (rawData) {
                    const storedLayouts = JSON.parse(rawData) as StoredLayout[];
                    // hydrate to Map
                    this.hydrateStoredLayouts(storedLayouts);
                }
            } catch (e) {/* */}
        }
    }

    private hydrateStoredLayouts(storedLayouts: StoredLayout[]) {
        if (storedLayouts.length) {
            for (const storedLayout of storedLayouts) {
                this._storedLayouts.set(storedLayout.symbolsAndLinksIndex, storedLayout);
            }
        }
    }

    public static getStorageKey(): string | null {

        let activeEntityId = '';
        const activeSpaceId = useAppStore.getState().activeSpace?.id;

        let entityName = '';
        if (activeSpaceId) {
            entityName = 'space';
            activeEntityId = activeSpaceId;
        }
        if (!entityName) {
            return null;
        }

        return `${entityName}:${activeEntityId}:layouts`;

    }

    public load(symbols: Symbol[], links: Link[]): CacheResult {
        const symbolsAndLinksIndex = this.getSymbolsAndLinksIndex(symbols, links);
        const storedLayout = this._storedLayouts.get(symbolsAndLinksIndex);

        if (storedLayout) {
            storedLayout.dateLastUsed = (new Date()).toISOString();
            this._storedLayouts.set(symbolsAndLinksIndex, storedLayout);
            this.toStorage();
        }

        return {
            symbolsAndLinksIndex,
            layoutCoordinates: storedLayout?.layoutCoordinates ?? undefined
        }
    }

    public persist(symbols: WorkerSymbol[], symbolsAndLinksIndex: string) {

        const coordinates = this.symbolsToCoordinates(symbols);

        this._storedLayouts.set(symbolsAndLinksIndex, {
            symbolsAndLinksIndex,
            layoutCoordinates: coordinates,
            dateStored: (new Date()).toISOString()
        });

        Logger.log('persisting layout coordinates');
        // @TODO go over all stored layouts and remove those not used couple days

        this.toStorage();
    }

    private toStorage() {
        const storageKey = LayoutCacheManager.getStorageKey();
        if (!storageKey) {
            Logger.warn(`Cannot store layout for missing storage key`);
            return;
        }
        try {
           localStorage.setItem(storageKey, JSON.stringify(Array.from(this._storedLayouts.values())));
        } catch (e) {/* */}

    }

    private symbolsToCoordinates(symbols: WorkerSymbol[]) {
        return Object.assign({}, ...symbols.map(symbol => {
            return {[symbol.id]: {x: symbol.x, y: symbol.y}};
        }, {}));
    }

    private getSymbolsAndLinksIndex(symbols: Symbol[], links: Link[]) {
        return `${Repository.getSymbolsIndex(symbols)}:${Repository.getLinksIndex(links)}`;
    }

    /*private getFilterAndFeaturesIndex() {

        const layoutFeatures = [
            FEATURES.LAYOUT_USE_GROUPING,
            FEATURES.LAYOUT_COMPACTNESS_FACTOR,
            FEATURES.LAYOUT_GROUP_COMPACTNESS_FACTOR,
            FEATURES.LAYOUT_CLUSTERING_POLICY,
            FEATURES.LAYOUT_GROUP_SUBSTRUCTURE_SCOPE,
            FEATURES.LAYOUT_QUALITY_TIME_RATIO,
            FEATURES.LAYOUT_CLUSTERING_QUALITY
        ];

        const featuresData = layoutFeatures.map((featureIndex) => {
            return {
                id: featureIndex,
                value: useAppStore.getState().getFeatureValue(featureIndex),
            }
        });

        const featuresIndex = md5(JSON.stringify(featuresData));

        let activeFilters = useAppStore.getState().getActiveFilters();
        if (!activeFilters) {
            activeFilters = [];
        }

        const simplifiedFilters: {[key: string]: string[]} = {};
        for (const group of activeFilters) {

            simplifiedFilters[group.id] = group.items.map((groupItem) => {
                return groupItem.id
            }).sort();
        }

        const sortedFilters = Object.keys(simplifiedFilters).sort().reduce((result: {[key: string]: string[]}, key) => {
            result[key] = simplifiedFilters[key];
            return result;
        }, {});

        const filtersIndex = md5(JSON.stringify(sortedFilters));

        return `${filtersIndex}:${featuresIndex}`;
    }*/

    static clearStorage() {
        const key = LayoutCacheManager.getStorageKey();
        if (key) {
            try {
                Logger.log('clearing cached positions local storage')
                localStorage.removeItem(key);
            } catch (e) {/* */}
        }
    }


}