import * as THREE from 'three';

export const ExtensionTint = {
    name: "extension-tint",
    uniforms: {
      tintAmount: 0,
      tintColor: new THREE.Color()
    },
    vertexShader: (shader: any) => {
      shader = `
        attribute float instTintAmounts; // Define tint amount attribute
        varying float vTintAmount; // Define varying variable to pass to fragment shader
        ${
            shader.replace(
              '#include <project_vertex>',
              `
              #include <project_vertex>
              vTintAmount = instTintAmounts; // Pass tint amount attribute to fragment shader
				      `
            )
        }
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
        shader = `
          uniform float tintAmount;
          uniform vec3 tintColor;
          varying float vTintAmount; // Receive tint amount value from vertex shader
          ${
              shader.replace(
                '#include <color_fragment>',
                `
                #include <color_fragment>
                vec3 color = diffuseColor.rgb;
                vec3 finalColor = mix(color, tintColor, (vTintAmount != 0.0 ? vTintAmount : tintAmount));
                diffuseColor.rgb = finalColor;
                `
              )
          }
        `;
        return shader;
    },
};