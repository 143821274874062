import * as THREE from 'three';
import type { BaseInstancedMesh } from './BaseInstancedMesh';
import { BaseBatchedMesh } from './BaseBatchedMesh';

export abstract class BaseObject{

    static idCount = 0;

	id: string = "";

	three: THREE.Mesh;

    protected _instancedOrBatchedMesh: BaseInstancedMesh | BaseBatchedMesh | null = null;
	instanceId = -1;
    private _globalInstanceId = -1;

	protected _color = new THREE.Color();
	protected _opacity = 1;
	protected _tint = 0;
	protected _drawProgress = 0;
	protected _active = true;

	protected _matrixNeedsUpdate = false;
	protected _colorNeedsUpdate = false;
	protected _opacityNeedsUpdate = false;
	protected _tintNeedsUpdate = false;
	protected _drawProgressNeedsUpdate = false;

    constructor () {
        this.id = `${this.constructor.name}_${BaseObject.idCount}`;
		BaseObject.idCount += 1;

        this.three = new THREE.Mesh();

		// this.three.matrixAutoUpdate = false;
		// this.three.matrixWorldAutoUpdate = false;
    }

	get instancedOrBatchedMesh(): BaseInstancedMesh | BaseBatchedMesh | null {
		return this._instancedOrBatchedMesh;
	}
	set instancedOrBatchedMesh(value: BaseInstancedMesh | BaseBatchedMesh) {
		if(value === this._instancedOrBatchedMesh){return;}
		this._instancedOrBatchedMesh = value;

		this._instancedOrBatchedMesh?.elems.push(this);

		// If it's a BatchedMesh the instanceId will be set in the update method so we don't set it now
		if(!(this._instancedOrBatchedMesh instanceof BaseBatchedMesh)){
			this.instanceId = this._instancedOrBatchedMesh?.elems.length - 1;
		}
	}

	get globalInstanceId(): number {
		return this._globalInstanceId;
	}
	set globalInstanceId(value: number) {
		if(value === this._globalInstanceId){return;}
		this._globalInstanceId = value;
	}

	get color() {
		return this._color;
	}
	set color(value: THREE.Color) {
		this._color = value;
		this.colorNeedsUpdate = true;
	}

	get opacity() {
		return this._opacity;
	}
	set opacity(value) {
		if(this._opacity === value) return;
		this._opacity = value;
		this.opacityNeedsUpdate = true;
	}

	get tint() {
		return this._tint;
	}
	set tint(value) {
		if(this._tint === value) return;
		this._tint = value;
		this.tintNeedsUpdate = true;
	}

	get drawProgress() {
		return this._drawProgress;
	}
	set drawProgress(value) {
		if(this._drawProgress === value) return;
		this._drawProgress = value;
		this.drawProgressNeedsUpdate = true;
	}

	get active() {
		return this._active;
	}
	set active(value) {
		if (value === this._active) return;
		this._active = value;
		/* const prevColor = this.color.getHSL({} as THREE.HSL);
		prevColor.s = this._active ? prevColor.s : 0;
		this.color = new THREE.Color().setHSL(prevColor.h, prevColor.s, prevColor.l); */

		this.color = new THREE.Color("#8C8C8C");
	}

	get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}

	set matrixNeedsUpdate(value) {
		if(this._matrixNeedsUpdate === value) return;
		this._matrixNeedsUpdate = value;
		if(this._instancedOrBatchedMesh && value){
			this._instancedOrBatchedMesh.matrixNeedsUpdate = value;
		}
	}

	get colorNeedsUpdate() {
		return this._colorNeedsUpdate;
	}

	set colorNeedsUpdate(value) {
		if(this._colorNeedsUpdate === value) return;
		this._colorNeedsUpdate = value;
		if(this._instancedOrBatchedMesh && value){
			this._instancedOrBatchedMesh.colorNeedsUpdate = value;
		}
	}

	get opacityNeedsUpdate() {
		return this._opacityNeedsUpdate;
	}

	set opacityNeedsUpdate(value) {
		if(this._opacityNeedsUpdate === value) return;
		this._opacityNeedsUpdate = value;
		if(this._instancedOrBatchedMesh && value){
			this._instancedOrBatchedMesh.opacityNeedsUpdate = value;
		}
	}

	get tintNeedsUpdate() {
		return this._tintNeedsUpdate;
	}

	set tintNeedsUpdate(value) {
		if(this._tintNeedsUpdate === value) return;
		this._tintNeedsUpdate = value;
		if(this._instancedOrBatchedMesh && value){
			this._instancedOrBatchedMesh.tintNeedsUpdate = value;
		}
	}

	get drawProgressNeedsUpdate() {
		return this._drawProgressNeedsUpdate;
	}

	set drawProgressNeedsUpdate(value) {
		if(this._drawProgressNeedsUpdate === value) return;
		this._drawProgressNeedsUpdate = value;
		if(this._instancedOrBatchedMesh && value){
			this._instancedOrBatchedMesh.drawProgressNeedsUpdate = value;
		}
	}

    dispose(){
		this.three.geometry.dispose();
		(this.three.material as THREE.Material).dispose();
		this.three.clear();
	}

}