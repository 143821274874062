import type { Space } from '../store/Space.ts'
import type { User } from '../store/User.ts';
import type { ActionFunctionArgs } from 'react-router-dom';

import { LocalStorageKeys } from '../types/common.ts'
import { useState, useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabasePublic } from '../utils/supabase-client';
import { Outlet, redirect } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import Cosmos from '../components/Cosmos.tsx';
import Modal from '../components/utils/Modal.tsx';
import Notifications from '../components/utils/Notifications.tsx';
import { Logger } from '../utils/Logger';
// import SpaceList from '../components/spaces/SpaceList.tsx'
// import ConnectionList from '../components/connections/ConnectionList.tsx'
import CosmosControls from '../components/CosmosControls.tsx';
import FiltersPanel from '../components/utils/filters/FiltersPanel.tsx';
import FilterBadges from '../components/utils/filters/FilterBadges.tsx';
import RightPanels from '../components/ui/panels/RightPanels.tsx';
import CosmosToolbar from '../components/CosmosToolbar.tsx';
import { useAppStore } from '../store/Store.ts'
import { decodeFiltersFromQuery, decodeSpotlightFromQuery } from '../utils/url-query-parser.ts';

function RootLayout() {

	const [session, setSession] = useState(null);
	const { setUser } = useAppStore((state) => {
		return {
			setUser: state.setUser
		}
	});

	const setUserData = (userData: Record<string, string>) => {
		setUser(userData ? {
			email: userData.email,
			name: userData.name,
			avatar: userData.avatar_url
		} as User : undefined);
	}

    useEffect(() => {
		supabasePublic.auth.getSession().then(({ data }: {data: any}) => {
			setSession(data.session);
			setUserData(data.session.user.user_metadata);
		});

      const {
        data: { subscription },
      } = supabasePublic.auth.onAuthStateChange((_event, session: any) => {
        setSession(session);
		setUserData(session.user.user_metadata);
      });
      
      return () => subscription.unsubscribe();
    }, []);

    if (!session) {
		return (
			<div className='flex h-screen justify-center items-center flex-col'>
				<div className='flex items-center'>
					<img src='/images/make-logo.png' className='h-4'></img>
					<span className='text-slate-500'>| Labs</span>
				</div>
				<Auth 
					supabaseClient={supabasePublic}
					appearance={{ theme: ThemeSupa }}
					onlyThirdPartyProviders={true}
					providers={['google']}
					queryParams={{ prompt: 'select_account' }} />
			</div>
		)
    }
    
	return (
		<>
			<div id="view" className="w-[100svw] h-[100svh] fixed overflow-hidden" >
				<Cosmos />
				<div id="ui" className="w-[100svw] h-[100svh] fixed overflow-hidden pointer-events-none">
					<MainHeader />
					<div className="flex flex-wrap">
						{/*<SpaceList />*/}
						{/*<ConnectionList />*/}
					</div>
					<CosmosControls />
					<FilterBadges />
					<FiltersPanel />
					<RightPanels />
					<CosmosToolbar />
					<Outlet />
					<Modal />
					<Notifications />
				</div>
			</div>
		</>
	)
}

export default RootLayout;

export async function loader({ request, params }: ActionFunctionArgs) {
	const spaces = await useAppStore.getState().fetchSpaces();
	const activeSpace = useAppStore.getState().activeSpace;

	const requestUrl = new URL(request.url);

	// parse query params
	const filtersFromQuery = decodeFiltersFromQuery(requestUrl.searchParams);
	if (Object.keys(filtersFromQuery).length) {
		useAppStore.getState().setFiltersFromUrl(filtersFromQuery);
	}

	useAppStore.getState().setSpotlightFromUrl(decodeSpotlightFromQuery(requestUrl.searchParams));

	// resolve active space
	if (spaces?.length && !activeSpace) {

		const spaceIds = spaces.map((space) => {
			return space.id;
		});

		Logger.log('resolving active space in root loader');
		let lastActiveFound: string | null = null;
		let spaceExists = false;
		const activeIdFromLocalStorage = localStorage.getItem(LocalStorageKeys.LAST_ACTIVE_SPACE);
		if (activeIdFromLocalStorage) {
			spaces.forEach((space: Space) => {
				if (space.id === activeIdFromLocalStorage) {
					lastActiveFound = space.id;
				}
				if (space.id === params?.spaceId) {
					spaceExists = true;
					return;
				}
			});
		}

		if (!spaceExists) {
			if (lastActiveFound && spaceIds.includes(lastActiveFound)) {
				return redirect(`/spaces/${lastActiveFound}`);
			} else {
				return redirect(`/spaces/${spaces[0].id}`);
			}
		}
	}
	return {
		spaces: spaces
	};
}
