type Props = {
    className?: string,
}

export const UnCheckbox = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
            <g className="nc-icon-wrapper" fill="currentColor" fillOpacity="0.4">
                <rect x="3" y="3" width="14" height="14" rx="3" ry="3" transform="translate(0 20) rotate(-90)"
                      fill="none"
                      stroke="currentColor" strokeOpacity="0.4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></rect>
                <line x1="7.5" y1="12.5" x2="12.5" y2="7.5" fill="none" stroke="currentColor" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="2" data-color="color-2"></line>
                <line x1="7.5" y1="7.5" x2="12.5" y2="12.5" fill="none" stroke="currentColor" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="2" data-color="color-2"></line>
            </g>
        </svg>
    )
}