
type Props = {
    className?: string,
}

export const Delete = ({className}: Props) => {
    return (
        <svg className={'w-5 h-5 ' + (className || '')} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0703125" y="-0.00585938" width="18" height="18" rx="4" fill="currentColor" fillOpacity="0.3" />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.5453 12.8832C11.9358 13.2738 12.569 13.2738 12.9595 12.8832C13.35 12.4927 13.35 11.8595 12.9595 11.469L10.4846 8.99414L12.9595 6.51927C13.35 6.12875 13.35 5.49558 12.9595 5.10506C12.569 4.71453 11.9358 4.71453 11.5453 5.10506L9.07041 7.57993L6.59554 5.10506C6.20502 4.71453 5.57185 4.71453 5.18133 5.10506C4.7908 5.49558 4.7908 6.12875 5.18133 6.51927L7.6562 8.99414L5.18132 11.469C4.7908 11.8595 4.7908 12.4927 5.18132 12.8832C5.57184 13.2738 6.20501 13.2738 6.59553 12.8832L9.07041 10.4084L11.5453 12.8832Z"
                  fill="currentColor"/>
        </svg>
    )
}
