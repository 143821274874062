type Props = {
    className?: string,
}

export const Checkbox = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3070_4021)">
                <path
                    d="M4.78571 1.50488H12.2143C14.0714 1.50488 15 2.43345 15 4.2906V11.7192C15 13.5763 14.0714 14.5049 12.2143 14.5049H4.78571C2.92857 14.5049 2 13.5763 2 11.7192V4.2906C2 2.43345 2.92857 1.50488 4.78571 1.50488Z"
                    stroke="currentColor" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.25 8.46826L7.33265 10.7897L11.75 5.21826" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3070_4021">
                    <rect width="16" height="16" fill="transparent" transform="translate(0.5 0.00585938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
