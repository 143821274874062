
type Props = {
    className?: string,
}

export const Read = ({className}: Props) => {
    return (
        <svg className={'w-5 h-5 ' + (className || '')} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0703125" y="-0.00585938" width="18" height="18" rx="4" fill="currentColor" fillOpacity="0.3" />
            <path d="M13.3194 13.2441L10.4003 10.325" stroke="currentColor" strokeWidth="1.88889" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M8.08954 11.2826C9.89509 11.2826 11.3588 9.81892 11.3588 8.01337C11.3588 6.20782 9.89509 4.74414 8.08954 4.74414C6.284 4.74414 4.82031 6.20782 4.82031 8.01337C4.82031 9.81892 6.284 11.2826 8.08954 11.2826Z"
                stroke="currentColor" strokeWidth="1.88889" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
