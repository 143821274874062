import * as THREE from 'three';

import { SYMBOL_CIRCLE_RADIUS, SYMBOL_HEIGHT, SYMBOL_SIZE } from '../../common/constants';
import { SymbolsShapesIM } from '../SymbolsShapesIM';
import { SerializedSymbol } from '../BaseSymbol';
import { Symbol } from '@/three/symbols/Symbol';

export interface SerializedApp {
	slug: string;
	theme: string;
}

export interface ScenarioFolder {
	id: string,
	name: string
}

export interface SerializedScenario extends SerializedSymbol {
	attributes: {
		instant: boolean;
		active: boolean;
		apps: SerializedApp[];
		folder?: ScenarioFolder,
		organization? : {
			id: string,
			name: string,
		},
		team?: {
			id: string,
			name: string,
		},
	}
}

export class MakeScenarioSymbol extends Symbol {

	private _instant = false;

	constructor(json: SerializedSymbol, shapeMesh: SymbolsShapesIM){
		json.theme = '#9933CC';
		// json.typeLabel = 'Make Scenario';

		super(json, shapeMesh);

		this.active = (json.attributes as any).active;
	}

	get instant() {
		return this._instant;
	}

	static override getGeometry() {
		const shape = new THREE.Shape();
		const angleStep = Math.PI * 0.5;
		const size = SYMBOL_SIZE;
		const radius = SYMBOL_CIRCLE_RADIUS;

		shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
		shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
		shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
		shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: SYMBOL_HEIGHT,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).rotateX(-Math.PI/2);

        geometry.clearGroups();

        return geometry;
    }
}