import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LinkDuplicatesShape } from './LinkDuplicatesShape';

export class LinksDuplicatesShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        super(size, size * 186, size * 180, LinkDuplicatesShape.getMaterial());

        this.layers.enable( LinkDuplicatesShape.interactionLayer );

        this.castShadow = false;
        this.receiveShadow = false;

        this.name = "linksDuplicatesShapesBM";
    }
}