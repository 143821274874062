import { AppEnv } from './utils/AppEnv';
AppEnv.resolve();

import './css/index.css';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootLayout, { loader as rootLoader } from './routes/RootLayout';
import ErrorPage from './routes/Error';

import SpaceDetail, { loader as spaceDetailLoader } from './routes/spaces/SpaceDetail';
import { spriteSheetIcons } from '@/utils/SpriteSheetIcons.ts';

/* import LicenseFile from '../license.json';
import { Class, License, LayoutExecutor } from '@celonis/yfiles';

License.value = LicenseFile;

// Reason for the next line: https://docs.yworks.com/yfileshtml/#/dguide/yfiles-modules#es-modules-loading_missing-modules
Class.ensure(LayoutExecutor); */

await spriteSheetIcons.init();

const router = createBrowserRouter([
	{ 
		path: '/',
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		id: 'root',
		loader: rootLoader,
		children: [
			{
				path: 'spaces/:spaceId',
				id: 'space-detail',
				loader: spaceDetailLoader,
				element: <SpaceDetail />,
			},
			{
				path: 'spaces',
				id: 'spaces',
				loader: spaceDetailLoader,
				element: <SpaceDetail />,
			}
		]}
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<RouterProvider router={router} />
)
