import * as THREE from 'three';
import { BaseObject } from './BaseObject';

export class BaseBatchedMesh extends THREE.BatchedMesh{

    elems: BaseObject[] = [];

    protected _tintAmountsTexture: THREE.DataTexture | null = null;
    protected _opacitiesTexture: THREE.DataTexture | null = null;
    protected _drawProgressTexture: THREE.DataTexture | null = null;

    matrixNeedsUpdate = false;
	colorNeedsUpdate = false;
    opacityNeedsUpdate = false;
    tintNeedsUpdate = false;
    drawProgressNeedsUpdate = false;

    shouldUpdateBoundingSphere = false;

    protected mtx = new THREE.Matrix4();
    protected vector = new THREE.Vector3();
    protected transform = new THREE.Object3D();

    constructor (maxInstanceCount: number, maxVertexCount: number, maxIndexCount: number, material: THREE.Material){
        super(maxInstanceCount, maxVertexCount, maxIndexCount, material);

        // this.matrixAutoUpdate = false;
        // this.matrixWorldAutoUpdate = false;
        this.frustumCulled = false;

        if((this.material as any).uniforms.tintAmountsTexture){this.inittintAmountsTexture();}
        if((this.material as any).uniforms.opacitiesTexture){this.initOpacityTexture();}
        if((this.material as any).uniforms.drawProgressTexture){this.initDrawProgressTexture();}
    }

    inittintAmountsTexture(){
        const tintAmountsTexture = new THREE.DataTexture( new Float32Array( this.maxInstanceCount ).fill( 0.0 ), 1, this.maxInstanceCount, THREE.RedFormat, THREE.FloatType );
		this._tintAmountsTexture = tintAmountsTexture;

        (this.material as any).uniforms.tintAmountsTexture.value = this._tintAmountsTexture;

        this.tintNeedsUpdate = true;
        this._tintAmountsTexture.needsUpdate = true;
    }
    
    initOpacityTexture(){
        const opacitiesTexture = new THREE.DataTexture( new Float32Array( this.maxInstanceCount ).fill( 1.0 ), 1, this.maxInstanceCount, THREE.RedFormat, THREE.FloatType );
		this._opacitiesTexture = opacitiesTexture;

        (this.material as any).uniforms.opacitiesTexture.value = this._opacitiesTexture;

        this.opacityNeedsUpdate = true;
        this._opacitiesTexture.needsUpdate = true;
    }

    initDrawProgressTexture(){
        const drawProgressTexture = new THREE.DataTexture( new Float32Array( this.maxInstanceCount ).fill( 0.0 ), 1, this.maxInstanceCount, THREE.RedFormat, THREE.FloatType );
		this._drawProgressTexture = drawProgressTexture;

        (this.material as any).uniforms.drawProgressTexture.value = this._drawProgressTexture;

        this.drawProgressNeedsUpdate = true;
        this._drawProgressTexture.needsUpdate = true;
    }

    sync(){
        if((this.matrixNeedsUpdate || this.colorNeedsUpdate || this.opacityNeedsUpdate || this.tintNeedsUpdate || this.drawProgressNeedsUpdate)){
            let matricesWhereUpdated = false;

            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(elem.instanceId !== -1){
                    if(this.matrixNeedsUpdate && elem.matrixNeedsUpdate){
                        this.syncMatrix(elem);

                        matricesWhereUpdated = true;
                    }
                    if(this.colorNeedsUpdate && elem.colorNeedsUpdate){
                        this.syncColor(elem);
                    }
                    if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate && this._opacitiesTexture){
                        this.syncOpacity(elem);
                    }
                    if(this.tintNeedsUpdate && elem.tintNeedsUpdate && this._tintAmountsTexture){
                        this.syncTint(elem);
                    }
                    if(this.drawProgressNeedsUpdate && elem.drawProgressNeedsUpdate && this._drawProgressTexture){
                        this.syncDrawProgress(elem);
                    }
                }
            }
            
            if(matricesWhereUpdated && this.shouldUpdateBoundingSphere){
                // needs to be computed for the raycasting and frustrum culling to work (that's why frustrum culling is disabled by default).
                this.computeBoundingSphere();
                this.matrixNeedsUpdate = false;
            }

            this.colorNeedsUpdate = false;
            this.opacityNeedsUpdate = false;
            this.tintNeedsUpdate = false;
            this.drawProgressNeedsUpdate = false;
        }
    }

    syncMatrix(elem: BaseObject){
        elem.matrixNeedsUpdate = false;

        if(elem.three.parent){
            elem.three.updateMatrix();
            elem.three.parent.updateMatrix();
            this.setMatrixAt(elem.instanceId, this.mtx.multiplyMatrices(elem.three.parent.matrix, elem.three.matrix));
        }
	}

    syncColor(elem: BaseObject){
        elem.colorNeedsUpdate = false;
        this.setColorAt(elem.instanceId, elem.color);
    }

    syncTint(elem: BaseObject){
        elem.tintNeedsUpdate = false;
        this.setTintAt(elem.instanceId, elem.tint);
    }

    setTintAt( instanceId: number, tint: number ) {
        const tintAmountsTexture = this._tintAmountsTexture!;
		const tintAmountsArray = this._tintAmountsTexture!.image.data;
		const drawInfo = (this as any)._drawInfo;
		if ( instanceId >= drawInfo.length || drawInfo[ instanceId ].active === false ) {
			return this;
		}

		tintAmountsArray[ instanceId ] = tint;
		tintAmountsTexture.needsUpdate = true;

		return this;
	}

    syncOpacity(elem: BaseObject){
        elem.opacityNeedsUpdate = false;

        this._opacitiesTexture!.needsUpdate = true;

        this.setOpacityAt(elem.instanceId, elem.opacity);
    }

    setOpacityAt( instanceId: number, opacity: number ) {
        const opacitiesTexture = this._opacitiesTexture!;
		const opacitiesArray = this._opacitiesTexture!.image.data;
		const drawInfo = (this as any)._drawInfo;

		if ( instanceId >= drawInfo.length || drawInfo[ instanceId ].active === false ) {
			return this;
		}

		opacitiesArray[ instanceId ] = opacity;
		opacitiesTexture.needsUpdate = true;

		return this;
	}

    syncDrawProgress(elem: BaseObject){
        elem.drawProgressNeedsUpdate = false;
        this.setDrawProgressAt(elem.instanceId, elem.drawProgress);
    }

    setDrawProgressAt( instanceId: number, drawProgress: number ) {
        const drawProgressTexture = this._drawProgressTexture!;
		const drawProgressArray = this._drawProgressTexture!.image.data;
		const drawInfo = (this as any)._drawInfo;
		if ( instanceId >= drawInfo.length || drawInfo[ instanceId ].active === false ) {
			return this;
		}

		drawProgressArray[ instanceId ] = drawProgress;
		drawProgressTexture.needsUpdate = true;

		return this;
	}

    override dispose() : this{
        this.geometry.dispose();
		(this.material as THREE.Material).dispose();

        if(this._tintAmountsTexture){
            this._tintAmountsTexture!.dispose();
            this._tintAmountsTexture = null;
        }

        if(this._opacitiesTexture){
            this._opacitiesTexture!.dispose();
            this._opacitiesTexture = null;
        }

        if(this._drawProgressTexture){
            this._drawProgressTexture!.dispose();
            this._drawProgressTexture = null;
        }

        this.elems = [];

        return super.dispose();
    }

}