import {useAppStore} from '@/store/Store.ts';
import {Symbol} from '@/three/symbols/Symbol.ts';
import {Link} from '@/three/links/Link.ts';
import SymbolDetail from './SymbolDetail.tsx';
import LinkDetail from './LinkDetail.tsx';
import {classNames} from '@/utils/helpers.ts';
import {useEffect, useState} from 'react';


export default function NodeDetail() {

    const [lastNodeDetail, setLastNodeDetail] = useState<Symbol | Link | null>(null);
    const [showPanel, setShowPanel] = useState<boolean>(false);

    const {
        nodeDetail
    } = useAppStore((state) => {
        return {
            nodeDetail: state.nodeDetail as Symbol | Link | null
        }
    });

    const onNodeReady = () => {
        setShowPanel(true);
    }

    useEffect(() => {
        if (nodeDetail) {
            setLastNodeDetail(nodeDetail);
            if (lastNodeDetail?.id === nodeDetail.id) {
                setShowPanel(true);
            }
        } else {
            setShowPanel(false);
        }
    }, [nodeDetail]);

  return (
    <div className={classNames(showPanel ? 'translate-x-0' : 'translate-x-[110%]', 'transition-transform duration-300 ease-in-out min-w-[24rem]')}>
      <div className={'flex flex-row gap-2'}>
          {lastNodeDetail && lastNodeDetail instanceof Symbol && (
              <SymbolDetail nodeDetail={lastNodeDetail} onReady={onNodeReady} />
          )}
          {lastNodeDetail && lastNodeDetail instanceof Link && (
              <LinkDetail nodeDetail={lastNodeDetail} onReady={onNodeReady} />
          )}
      </div>
    </div>
  )
}