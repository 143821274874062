import * as THREE from 'three';

export const ExtensionFog = {
    name: "extension-fog",
    uniforms: {
      fPlane: {value: new THREE.Vector4(0, 0, 0, 0)},
      fDepth: {value: 0},
      fColor: {value: new THREE.Color()},
      fActive: {value: true}
    },
    fragmentShader: (shader: any) => {
      shader = `
        uniform vec4 fPlane;
        uniform float fDepth;
        uniform vec3 fColor;
        uniform bool fActive;
        ${
            shader.replace(
              `#include <clipping_planes_fragment>`,
              `#include <clipping_planes_fragment>
              float planeFog = 0.0;
              planeFog = smoothstep(0.0, -fDepth, dot( vViewPosition, fPlane.xyz) - fPlane.w);
              `
            ).replace(
              `#include <fog_fragment>`,
              `#include <fog_fragment>
              if(fActive){
                gl_FragColor.rgb = mix( gl_FragColor.rgb, fColor, planeFog );
              }
              `
            )
        }
      `;
      return shader;
    },
};