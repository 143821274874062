import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { INTERACTION_ANIMATION_SPEED_FAST, INTERACTION_ANIMATION_SPEED_SLOW, LINK_INDICATOR_VELOCITY, LINK_INDICATOR_Y_POSITION, LINK_PORT_Y_POSITION, LINK_SHAPE_Y_POSITION, SYMBOL_HIDE_Y_POSITION, SYMBOL_PAD_RADIUS, SYMBOL_Y_POSITION } from '../common/constants';
import { ContextMenu } from '../utils/interaction/ContextMenu';
import { LinkIndicator } from './LinkIndicator';
import { LinkShape } from './LinkShape';
import { linkTypeMap, readLink, triggerLink, writeLink } from '@/utils/link';
import { CosmosThree } from '../CosmosThree';
import { LinkPort } from './LinkPort';
import { BaseLink, SerializedLink } from './BaseLink';

export abstract class Link extends BaseLink{
	private _isTrigger = false;
	private _isRead = false;
	private _isWrite = false;

	static interactionLayer = 1;

	private _type = "";

	private _portSource: LinkPort;
	private _portTarget: LinkPort;
	private _shape: LinkShape;
	indicator: LinkIndicator;

	tlIndicator: gsap.core.Timeline;

	private _hover = false;
	private _click = false;
	private _doubleclick = false;
	private _select = false;

	private _visible = false;
	private _filtered = false;
	private _muted = false;
	private _spotlight = false;

	hovered: signals.Signal;
	unhovered: signals.Signal;

	clicked: signals.Signal;
	doubleclicked: signals.Signal;

	selected: signals.Signal;
	unselected: signals.Signal;

	contextmenued: signals.Signal;

	private _tintTo: gsap.QuickToFunc;
	private _yTo: gsap.QuickToFunc;

	private _opacityIndicatorTo: gsap.QuickToFunc;

    constructor (json: SerializedLink) {
        super(json);

		this._portSource = new LinkPort();
		this._portSource.instancedOrBatchedMesh = Repository.linksPortsMesh!;
		this._portSource.three.position.y = LINK_PORT_Y_POSITION;
		this.three.add(this._portSource.three);

		this._portTarget = new LinkPort();
		this._portTarget.instancedOrBatchedMesh = Repository.linksPortsMesh!;
		this._portTarget.three.position.y = LINK_PORT_Y_POSITION;
		this.three.add(this._portTarget.three);

		this._shape = new LinkShape();
		this._shape.instancedOrBatchedMesh = Repository.linksShapesMesh!;
		this._shape.three.position.y = LINK_SHAPE_Y_POSITION;
		this.three.add(this._shape.three);

		this.indicator = new LinkIndicator();
		this.indicator.instancedOrBatchedMesh = Repository.linksIndicatorsMesh!;
		this.indicator.three.position.y = LINK_INDICATOR_Y_POSITION;
		this.three.add(this.indicator.three);

		this.hovered = new signals.Signal();
		this.unhovered = new signals.Signal();

		this.clicked = new signals.Signal();
		this.doubleclicked = new signals.Signal();

		this.selected = new signals.Signal();
		this.unselected = new signals.Signal();

		this.contextmenued = new signals.Signal();
		this.contextmenued.add(this.onContextMenu, this);

		// Quick tween functions to boost performance even more
		this._tintTo = gsap.quickTo(this, "tint", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });
		this._yTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED_SLOW, ease: "power2.inOut", onUpdate: () => {
			this.matrixNeedsUpdate = true;
		} });
    
		this._opacityIndicatorTo = gsap.quickTo(this.indicator, "opacity", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });

		this.tlIndicator = new gsap.core.Timeline({repeat: -1, repeatDelay: 0, paused: true});

		if(linkTypeMap[this.originalData.type] === triggerLink){
			this.isTrigger = true;
		}else if(linkTypeMap[this.originalData.type] === readLink){
			this.isRead = true;
		}else if(linkTypeMap[this.originalData.type] === writeLink){
			this.isWrite = true;
		}

		for(let i = 0 ; i < this.originalData.allLinks.length; i++){
			const linkData = this.originalData.allLinks[i];

			if(linkTypeMap[linkData.type] === triggerLink){
				this.isTrigger = true;
			}else if(linkTypeMap[linkData.type] === readLink){
				this.isRead = true;
			}else if(linkTypeMap[linkData.type] === writeLink){
				this.isWrite = true;
			}
		}

		this.type = json.type;
	}

	get type() {
		return this._type;
	}

	set type(value) {
		if(value === this._type){return}
		this._type = value;
	}

	public get isTrigger() {
		return this._isTrigger;
	}
	public set isTrigger(value) {
		if (value === this._isTrigger) return;

		this._isTrigger = value;
	}

	public get isRead() {
		return this._isRead;
	}
	public set isRead(value) {
		if (value === this._isRead) return;

		this._isRead = value;
	}

	public get isWrite() {
		return this._isWrite;
	}
	public set isWrite(value) {
		if (value === this._isWrite) return;

		this._isWrite = value;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this._portSource.matrixNeedsUpdate = value;
		this._portTarget.matrixNeedsUpdate = value;
		this._shape.matrixNeedsUpdate = value;
		this.indicator.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}
	override set color(value: THREE.Color) {
		this._color = value;
		this.colorNeedsUpdate = true;


		this._shape.color = value;
	}

	override get tint() {
		return this._tint;
	}

	override set tint(value) {
		if(this._tint === value) return;
		this._tint = value;
		this.tintNeedsUpdate = true;

		this._portSource.tint = value;
		this._portTarget.tint = value;
		this._shape.tint = value;
		this.indicator.tint = value;
	}

	get hover() {
		return this._hover;
	}
	set hover(value) {
		if(this._hover === value){ return }

		this._hover = value;

		if(this._hover){
			this.hovered.dispatch(this);
		}else{
			this.unhovered.dispatch(this);
		}
	}

	get click() {
		return this._select;
	}

	set click(value) {
		if(this._filtered){ return }

		this._click = value;

		if(this._click){
			this.clicked.dispatch(this);
		}
	}

	get doubleclick() {
		return this._select;
	}

	set doubleclick(value) {
		if(this._filtered){ return }

		this._doubleclick = value;

		if(this._doubleclick){
			this.doubleclicked.dispatch(this);
		}
	}

	get select() {
		return this._select;
	}
	set select(value) {
		if(this._select === value){ return }

		this._select = value;

		if(this._select){
			this.selected.dispatch(this);
		}else{
			this.unselected.dispatch(this);
		}
	}

	get visible() {
		return this._visible;
	}
	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			if(!this._visible){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			if(this._filtered){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;

		if(this._muted){
			this.muteIn();
		}else{
			this.muteOut();
		}
	}

	get spotlight() {
		return this._spotlight;
	}
	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;

		if(this._spotlight){
			this.muted = false;
			this._opacityIndicatorTo(1);
			this.tlIndicator.play(0);
		}else{
			this.muted = false;
			this._opacityIndicatorTo(0);
			this.tlIndicator.pause(0);
		}
	}

	update(){
		if(!this.source || !this.target){ return }

		// Define the start and end points
		const pointSource = new THREE.Vector3(0, 0, 0);

		// Calculate the direction vector from source to target
		const directionVectorSource = this.target.three.position.clone().sub(this.source.three.position);

		// Normalize the direction vector
		directionVectorSource.normalize();

		// Offset the start point slightly in the direction of the target by the radius of the symbol pad
		pointSource.add(directionVectorSource.clone().multiplyScalar(SYMBOL_PAD_RADIUS));

		const pointTarget = this.target.three.position.clone().sub(this.source.three.position);

		// Calculate the direction vector from target to source
		const directionVectorTarget = this.target.three.position.clone().sub(this.source.three.position);

		// Normalize the direction vector
		directionVectorTarget.normalize();

		// Offset the start point slightly in the direction of the target by the radius of the symbol pad
		pointTarget.sub(directionVectorTarget.clone().multiplyScalar(SYMBOL_PAD_RADIUS));

		/* const random = Math.random();
		pointSource.y = 0 + (random * 0.1) / SCALE_FACTOR; // Very important to avoid z-fighting
		pointTarget.y = 0 + (random * 0.1) / SCALE_FACTOR; // Very important to avoid z-fighting */

		this.three.position.x = this.source.three.position.x;
		this.three.position.z = this.source.three.position.z;
		this.matrixNeedsUpdate = true;

		this._shape.updateGeometry(pointSource, pointTarget);
	
		if(this._isTrigger){
			this.color = CosmosThree.linksTriggerColor;
		}else{
			this.color = CosmosThree.linksDefaultColor;
		}

		// Position the ports
		this._portSource.three.position.x = pointSource.x;
		this._portSource.three.position.z = pointSource.z;
		this._portSource.matrixNeedsUpdate = true;

		this._portTarget.three.position.x = pointTarget.x;
		this._portTarget.three.position.z = pointTarget.z;
		this._portTarget.matrixNeedsUpdate = true;

		if(!this.source.active || !this.target.active){ return }

		// Define start and end points
        const startPoint = new THREE.Vector3(0, 0, 0);
        const endPoint = this.target.three.position.clone().sub(this.source.three.position);

        // Calculate direction vector
        const direction = new THREE.Vector3().subVectors(endPoint, startPoint).normalize();

        // Calculate the angle of rotation around the Y-axis
        const angle = Math.atan2(direction.x, direction.z) + Math.PI;

        this.indicator.three.rotation.y = angle;

        // Calculate the length of the link
        const linkLength = startPoint.distanceTo(endPoint);

        // Set the duration based on the length of the link
        const duration = linkLength / LINK_INDICATOR_VELOCITY; // Define SOME_VELOCITY_CONSTANT based on your needs

		this.tlIndicator.clear();
        this.tlIndicator.add(gsap.to(this.indicator.three.position, {duration: duration, x: endPoint.x, z: endPoint.z, ease: "none", onUpdate: () => { this.matrixNeedsUpdate = true; } }));
	}

	onContextMenu(point: THREE.Vector2){
		const cm = new ContextMenu();
		this.createContextMenu(cm, point);
	}

	muteIn(){
		this._tintTo(1);
	}

	muteOut(){
		this._tintTo(0);
	}

	hide(){
		this.three.position.y = SYMBOL_HIDE_Y_POSITION;
		this.matrixNeedsUpdate = true;
	}

	hideIn(){
		this._yTo(SYMBOL_HIDE_Y_POSITION);
		this._opacityIndicatorTo(0);
	}

	hideOut(){
		this._yTo(SYMBOL_Y_POSITION);
		this._opacityIndicatorTo(1);
	}

	protected createContextMenu(cm: ContextMenu, point: THREE.Vector2): void {
		if (this.type) {
			cm.addItem(`Link type: ${this.type}`, 'arrow-up-right-from-square', () => {
				//
			})
		}

		cm.updatePosition(point);
	}

	override dispose(){
		this.hovered.removeAll();
		this.unhovered.removeAll();
		this.selected.removeAll();
		this.unselected.removeAll();
		this.contextmenued.removeAll();

		this._portSource.dispose();
		this._portTarget.dispose();
		this._shape.dispose();
		this.indicator.dispose();

		super.dispose();
	}
}