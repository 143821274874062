import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { SymbolIcon } from './SymbolIcon';
import { ICON_Y_POSITION, INTERACTION_ANIMATION_SPEED_FAST, INTERACTION_ANIMATION_SPEED_SLOW, LEGEND_POSITION_Y_OFFSET, SCALE_FACTOR, SYMBOL_HEIGHT, SYMBOL_HIDE_Y_POSITION, SYMBOL_PAD_Y_POSITION, SYMBOL_SHAPE_Y_POSITION, SYMBOL_Y_POSITION } from '../common/constants';
import { SymbolLegend } from './SymbolLegend';
import { SymbolShape } from './SymbolShape';
import { ContextMenu } from '../utils/interaction/ContextMenu';
import { getMakeIconUrl } from '../utils/utils';
import { SymbolPad } from './SymbolPad';
import { CosmosThree } from '../CosmosThree';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionTint } from '../utils/materials/extensions/ExtensionTint';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { SymbolsShapesIM } from './SymbolsShapesIM';
import { symbolPropsMap } from './types/SymbolPropsMap';
import { BaseSymbol, SerializedSymbol } from './BaseSymbol';
import { LinkDuplicates } from '../links/LinkDuplicates';
import { LinksDuplicatesShapesBM } from '../links/LinksDuplicatesShapesBM';

export abstract class Symbol extends BaseSymbol{

	override readonly isShared = false;

	static globalCount = 0;

	protected _depth = 1;

	static interactionLayer = 1;

	private _type = "";

	private _title = "";
	private _url = "";

	groupId: string = "";

	protected _imgIconUrl?: string;
	protected _preventOpenUrl = false;
	protected _openUrlNewTab = true;

	pad: SymbolPad;

	shape: SymbolShape;

	icon: SymbolIcon;
	private _iconSlug = "";
	private _iconURL = "";

	legend: SymbolLegend;

	private _hover = false;
	private _click = false;
	private _doubleclick = false;
	private _select = false;

	private _visible = false;
	private _filtered = false;
	private _muted = false;
	private _spotlight = false;

	spotlightLevel = 0;

	hovered: signals.Signal;
	unhovered: signals.Signal;

	clicked: signals.Signal;
	doubleclicked: signals.Signal;

	selected: signals.Signal;
	unselected: signals.Signal;

	contextmenued: signals.Signal;

	linked: signals.Signal;
	unlinked: signals.Signal;

	private _tintTo: gsap.QuickToFunc;
	private _yTo: gsap.QuickToFunc;
	private _shapeScaleTo: gsap.QuickToFunc;
	private _iconPositionTo: gsap.QuickToFunc;
	private _iconOpacityTo: gsap.QuickToFunc;
	private _legendOpacityTo: gsap.QuickToFunc;

    constructor (json: SerializedSymbol, shapeMesh: SymbolsShapesIM) {
		super(json);

		this.pad = new SymbolPad();
		this.shape = new SymbolShape();
		this.icon = new SymbolIcon();
		this.legend = new SymbolLegend();

		this.pad.instancedOrBatchedMesh = Repository.symbolsPadsMesh!;
		this.pad.three.position.y = SYMBOL_PAD_Y_POSITION;
		this.three.add(this.pad.three);

		this.shape.instancedOrBatchedMesh = shapeMesh;
		this.shape.three.position.y = SYMBOL_SHAPE_Y_POSITION;
		this.three.add(this.shape.three);

		this.icon.instancedOrBatchedMesh = Repository.symbolsIconsMesh!;
		this.icon.three.position.y = ICON_Y_POSITION;
		this.three.add(this.icon.three);

		this.legend.instancedOrBatchedMesh = Repository.symbolsLegendsMesh!;
		this.legend.three.position.y = -LEGEND_POSITION_Y_OFFSET;
		this.three.add(this.legend.three);

		this.globalInstanceId = Symbol.globalCount++;

		this.hovered = new signals.Signal();
		this.unhovered = new signals.Signal();

		this.clicked = new signals.Signal();
		this.doubleclicked = new signals.Signal();

		this.selected = new signals.Signal();
		this.unselected = new signals.Signal();

		this.contextmenued = new signals.Signal();
		this.contextmenued.add(this.onContextMenu, this);

		this.linked = new signals.Signal();
		this.linked.add(this.onLinked, this);

		this.unlinked = new signals.Signal();
		this.unlinked.add(this.onUnlinked, this);

		// Quick tween functions to boost performance even more
		this._tintTo = gsap.quickTo(this, "tint", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });
		this._yTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED_SLOW, ease: "power2.inOut", onUpdate: () => {
			this.matrixNeedsUpdate = true;
			CosmosThree.directionalLight1.shadow.autoUpdate = true;
		} });
		this._shapeScaleTo = gsap.quickTo(this.shape.three.scale, "y", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "power2.inOut", onUpdate: () => {
			this.shape.matrixNeedsUpdate = true;
			CosmosThree.directionalLight1.shadow.autoUpdate = true;
		} });
		this._iconPositionTo = gsap.quickTo(this.icon.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "power2.inOut", onUpdate: () => {
			this.icon.matrixNeedsUpdate = true;
		} });
		this._iconOpacityTo = gsap.quickTo(this.icon, "opacity", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });
		this._legendOpacityTo = gsap.quickTo(this.legend, "opacity", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });

		this.groupId = (json.attributes as any).folder.id;
		this.type = json.type;
		this.color = new THREE.Color(json.theme);
		this.url = json.url || "";
		this.title = json.title || "[Name unknown]";
		this.originalData.typeLabel = symbolPropsMap.get(json.type)?.filterLabel || "";
		this.iconSlug = symbolPropsMap.get(this.type)?.slug || "";
	}

	get type() {
		return this._type;
	}

	set type(value) {
		if(value === this._type){return}
		this._type = value;
	}

	override get globalInstanceId(): number {
		return super.globalInstanceId;
	}

	override set globalInstanceId(value: number) {
		super.globalInstanceId = value;

		this.shape.globalInstanceId = value;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}

	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this.pad.matrixNeedsUpdate = value;
		this.shape.matrixNeedsUpdate = value;
		this.icon.matrixNeedsUpdate = value;
		this.legend.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}

	override set color(value: THREE.Color) {
		if (value === this._color) return;
		this._color = value;
		this.colorNeedsUpdate = true;

		this.shape.color = value;
	}

	override get tint() {
		return this._tint;
	}

	override set tint(value) {
		if(this._tint === value) return;
		this._tint = value;
		this.tintNeedsUpdate = true;

		this.shape.tint = value;
	}

	get preventOpenUrl() {
		return this._preventOpenUrl;
	}

	get visible() {
		return this._visible;
	}

	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			this.highlightOut();
			if(!this._visible){
				this.hideIn();
			}else{
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			this.highlightOut();
			if(this._filtered){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;

		if(this._muted){
			this.muteIn();
		}else{
			this.muteOut();
		}
	}

	get spotlight() {
		return this._spotlight;
	}

	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;

		if(this._spotlight){
			this.muted = false;
			this.highlightIn();
		}else{
			this.muted = false;
			this.highlightOut();
		}
	}

	get url(): string {
		return this._url;
	}

	set url(value: string) {
		if (value === this._url) return;
		this._url = value;
	}

	get title(): string {
		return this._title;
	}

	set title(value: string) {
		if (value === this._title) return;
		this._title = value;
		this.legend.title = value;
	}

	get iconSlug() {
		return this._iconSlug;
	}

	set iconSlug(value) {
		if (value === this._iconSlug) return;
		this._iconSlug = value;
		this.icon.slug = value;
		this.iconURL = getMakeIconUrl(value);
	}

	get iconURL() {
		return this._iconURL;
	}

	set iconURL(value) {
		if (value === this._iconURL) return;
		this._iconURL = value;
	}

	get hover() {
		return this._hover;
	}

	set hover(value) {
		if(this._hover === value || this._filtered){ return }

		this._hover = value;

		if(this._hover){
			this.hovered.dispatch(this);
		}else{
			this.unhovered.dispatch(this);
		}
	}

	get click() {
		return this._select;
	}

	set click(value) {
		if(this._filtered){ return }

		this._click = value;

		if(this._click){
			this.clicked.dispatch(this);
		}
	}

	get doubleclick() {
		return this._select;
	}

	set doubleclick(value) {
		if(this._filtered){ return }

		this._doubleclick = value;

		if(this._doubleclick){
			this.doubleclicked.dispatch(this);
		}
	}

	get select() {
		return this._select;
	}

	set select(value) {
		if(this._filtered){ return }

		this._select = value;

		if(this._select){
			this.selected.dispatch(this);
		}else{
			this.unselected.dispatch(this);
		}
	}

	onContextMenu(point: THREE.Vector2){
		const cm = new ContextMenu();
		this.createContextMenu(cm, point);
	}

	muteIn(){
		this._tintTo(1);
		if(Repository.mesh?.activeTheme === 'cosmosDark'){
			this._iconOpacityTo(0.15);
		}
		this._legendOpacityTo(0);
	}

	muteOut(){
		this._tintTo(0);
		if(Repository.mesh?.activeTheme === 'cosmosDark'){
			this._iconOpacityTo(1);
		}
		this._legendOpacityTo(1);
	}

	highlightIn(){
		const scale =  THREE.MathUtils.lerp(2, 1.5, this.spotlightLevel );
		this.spotlightLevel = 0;
		this._shapeScaleTo(scale);
		this._iconPositionTo(ICON_Y_POSITION * scale);
	}

	highlightOut(){
		this._shapeScaleTo(1);
		this._iconPositionTo(ICON_Y_POSITION);
	}

	hide(){
		this.three.position.y = SYMBOL_HIDE_Y_POSITION;
		this.matrixNeedsUpdate = true;
		CosmosThree.directionalLight1.shadow.autoUpdate = true;
		this.legend.opacity = 0;
	}

	hideIn(){
		this._yTo(SYMBOL_HIDE_Y_POSITION);
		this._legendOpacityTo(0);
	}

	hideOut(){
		this._yTo(SYMBOL_Y_POSITION);
		this._legendOpacityTo(1);
	}

	protected createContextMenu(cm: ContextMenu, point: THREE.Vector2): void {
		if (this.url) {
			cm.addItem('Open URL', 'arrow-up-right-from-square', () => {
				window.open(this.url, '_blank');
			})
		}

		if(this.shared){
			cm.addItem('Show all uses', 'diagram-project', () => {
				Repository.mesh!.showUsages = true;
				this.select = true;
			})
		}

		cm.updatePosition(point);
	}

	showDuplicatesLinks(): BaseSymbol[]{
		if(Repository.linksDuplicatesShapesMesh) return [];

		const duplicates = this.duplicates.filter(symbol => !(symbol as Symbol).filtered);

		if(duplicates.length > 0){
			const linksDuplicates: LinkDuplicates[] = [];

			Repository.linksDuplicatesShapesMesh = new LinksDuplicatesShapesBM(duplicates.length);
			Repository.mesh!.add(Repository.linksDuplicatesShapesMesh);
			Repository.linksDuplicatesShapesMesh.renderOrder = 11;

			for(let i = 0; i < duplicates.length; i++){
				const linkDuplicates = new LinkDuplicates();
				linkDuplicates.source = this;
				linkDuplicates.target = duplicates[i];
				linkDuplicates.update();
				linksDuplicates.push(linkDuplicates);
			}

		}

		return duplicates;
	}

	hideDuplicatesLinks() {
		if(Repository.linksDuplicatesShapesMesh){
			Repository.mesh!.remove(Repository.linksDuplicatesShapesMesh);
			Repository.linksDuplicatesShapesMesh.dispose();
			Repository.linksDuplicatesShapesMesh = null;
		}
	}

	static getGeometry (): THREE.BufferGeometry {
		const geometry = new THREE.ExtrudeGeometry(CosmosThree.symbolDefaultSVGShape, {
			depth: SYMBOL_HEIGHT * SCALE_FACTOR,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR, 1 / SCALE_FACTOR).rotateX(-Math.PI/2).rotateY(Math.PI/2);
		
		geometry.clearGroups();
		geometry.computeBoundingBox();
		const offset = new THREE.Vector3();
		geometry.boundingBox!.getCenter(offset).negate();

		geometry.translate( offset.x, 0, offset.z );

        return geometry;
	}

	static getMaterial () {
		const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionTint, ExtensionFog],
            {
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
				fActive: true,
                // gradientMap: CosmosThree.gradientMapThree,
                tintColor: CosmosThree.symbolsMutedTint,
                // wireframe: false
            },
            { debug: false }
        );

        return material;
	}

	override dispose(){
		this.hovered.removeAll();
		this.unhovered.removeAll();
		this.selected.removeAll();
		this.unselected.removeAll();
		this.contextmenued.removeAll();
		this.linked.removeAll();
		this.unlinked.removeAll();

		this.shape?.dispose();
		this.icon?.dispose();
		this.legend?.dispose();

		super.dispose();
	}

}