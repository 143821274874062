import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { LINK_PORT_OUTLINE_RADIUS } from '../common/constants';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { CosmosThree } from '../CosmosThree';

export class LinkPort extends BaseObject{

    constructor(){
        super();
    }

    static getGeometry(): THREE.BufferGeometry {
        const geometry = new THREE.CircleGeometry(LINK_PORT_OUTLINE_RADIUS, 16).rotateX(-Math.PI / 2);

        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionFog],
            {
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
                fActive: true,
                color: CosmosThree.linksPortColor,
                //wireframe: true
            },
            { debug: false }
        );

        return material;
    }
}