import { Repository } from "../../common/Repository";
import { Symbol } from "../../symbols/Symbol";
import { Link } from "@/three/links/Link";

export const getElementsToSpotlight = (startingSymbols: Symbol[], level: number) => {
    const elementsToHighlight: {level: number, symbols: Symbol[], links: Link[]}[] = [];
    const visitedSymbols = new Set();
    const visitedLinks = new Set();
    const queue = startingSymbols.map(symbol => ({ symbol, level: 0 }));

    while (queue.length > 0) {
        const item = queue.shift();

        if (!item || !item.symbol || visitedSymbols.has(item.symbol) || item.symbol.filtered || !item.symbol.visible) {
            continue; // Skip if item, symbol is undefined, or symbol has been visited
        }

        visitedSymbols.add(item.symbol);

        const { symbol, level: currentLevel } = item;

        if (currentLevel > level) {
            break; // Stop BFS if we exceed the desired level
        }

        if (!elementsToHighlight[currentLevel]) {
            elementsToHighlight[currentLevel] = { level: currentLevel, symbols: [], links: [] };
        }

        elementsToHighlight[currentLevel].symbols.push(symbol);

        if (currentLevel < level) {
            symbol.neighbors.forEach((neighborSymbol) => {
                if (!visitedSymbols.has(neighborSymbol)) {
                    queue.push({ symbol: neighborSymbol as Symbol, level: currentLevel + 1 });
                }
            });
            symbol.links.forEach((link) => {
                if (!visitedLinks.has(link)) {
                    visitedLinks.add(link);
                    elementsToHighlight[currentLevel].links.push(link as Link);
                }
            });
        }
    }

    return elementsToHighlight;
}

export const getUnmatchedSymbolsAndLinks = ( symbols: Symbol[], links: Link[]) => {
    const matchedSymbols = new Set();
    const matchedLinks = new Set();

    // Collect symbols and links
    for (let i = 0; i < symbols.length; i++){
        symbols.forEach(symbol => matchedSymbols.add(symbol));
    }

    for (let i = 0; i < links.length; i++){
        links.forEach(links => matchedLinks.add(links));
    }

    // Filter out symbols and links not present in the object
    const unmatchedSymbols: Symbol[] = Repository.mesh!.symbols.filter(symbol => !matchedSymbols.has(symbol));
    const unmatchedLinks: Link[] = Repository.mesh!.links.filter(link => !matchedLinks.has(link));

    return { unmatchedSymbols, unmatchedLinks };
}