
type Props = {
    className?: string,
}

export const Scheduled = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3070_4030)">
                <path d="M8.45679 5.21997V8.00568L10.3139 9.86282" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M3.2218 14.4602L2.67627 10.978L6.1468 11.5816L3.2218 14.4602Z" fill="currentColor" stroke="currentColor" strokeOpacity="0.4"
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M3.81385 12.5562C4.78209 13.5442 6.04027 14.1975 7.40539 14.4211C8.77051 14.6447 10.1714 14.427 11.4042 13.7996C12.6371 13.1722 13.6376 12.1678 14.2603 10.9326C14.883 9.69735 15.0954 8.29568 14.8666 6.93142C14.6378 5.56717 13.9797 4.31149 12.988 3.34702C11.9964 2.38255 10.7229 1.75959 9.35284 1.56875C7.98276 1.37791 6.58752 1.62915 5.37006 2.28592C4.1526 2.94268 3.17642 3.97073 2.5835 5.22053"
                    stroke="currentColor" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3070_4030">
                    <rect width="16" height="16" fill="transparent" transform="translate(0.5 0.00585938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
