import * as THREE from 'three';
import { BaseObject } from '../common/BaseObject';
import { ICON_SIZE } from '../common/constants';
import { SymbolsIconsIM } from './SymbolsIconsIM';
import { CosmosThree } from '../CosmosThree';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionAtlas } from '../utils/materials/extensions/ExtensionAtlas';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';

export class SymbolIcon extends BaseObject{

    private _slug = "";    
    private _iconNeedsUpdate = false;

    constructor(){
        super();
    }

    get slug (){
        return this._slug;
    }

    set slug (value: string){
        if(this._slug === value) return;
        this._slug = value;

        if(CosmosThree.iconsAtlasMap?.get(value)){ this.iconNeedsUpdate = true; }
    }

    get iconNeedsUpdate() {
        return this._iconNeedsUpdate;
    }
    set iconNeedsUpdate(value) {
        if(this._iconNeedsUpdate === value) return;
        this._iconNeedsUpdate = value;
        if(this._instancedOrBatchedMesh){
			(this._instancedOrBatchedMesh as SymbolsIconsIM).iconNeedsUpdate = value;
		}
    }

    static getGeometry(): THREE.BufferGeometry {
        return new THREE.PlaneGeometry(ICON_SIZE, ICON_SIZE, 1, 1).rotateX(-Math.PI / 2);
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionAtlas, ExtensionFog, ExtensionOpacity],
            {
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
                fActive: true,
                atlasSize: new THREE.Vector2(CosmosThree.iconsAtlasJson!.meta.size.w, CosmosThree.iconsAtlasJson!.meta.size.h),
                map: CosmosThree.iconsAtlasTexture,
                transparent: true,
                premultipliedAlpha: false
                //wireframe: true
            },
            { debug: false }
        );

        return material;
    }
}