import {ArrowLeft} from '@/assets/icons/ArrowLeft.tsx';
import {ArrowRight} from '@/assets/icons/ArrowRight.tsx';
import {ArrowBoth} from '@/assets/icons/ArrowBoth.tsx';
import {Folders} from '@/assets/icons/Folders.tsx';
import {classNames} from '@/utils/helpers.ts';
import type {LinkType} from '@/utils/link.ts';
import {linkTypeMap} from '@/utils/link.ts';
import { Symbol } from '@/three/symbols/Symbol';
import { Link } from '@/three/links/Link';
import AppIcon from '@/components/utils/AppIcon.tsx';

type Props = {
    nodeDetail: Symbol;
    link: Link,
    otherFolder?: boolean
}

export default function NodeLink({nodeDetail, link, otherFolder}: Props) {

    let rightNode: null | Symbol = null;

    const resolveLinks = () => {
        const linkMap: Map<string, LinkType> = new Map();
        const mainLinkType = linkTypeMap[link.type];
        if (mainLinkType) {
            const mainLink: LinkType = {
                id: mainLinkType.id,
                label: mainLinkType.label,
                bgColor: mainLinkType.bgColor,
                contentColor: mainLinkType.contentColor,
                direction: link.target?.id === nodeDetail.id ? 'toNodeDetail' : 'fromNodeDetail'
            };
            linkMap.set(mainLinkType.id, mainLink);
        }
        if (link.originalData?.allLinks?.length) {
            for (let i = 0; i < link.originalData.allLinks.length; i++) {
                const anotherLink = link.originalData.allLinks[i];
                const type = linkTypeMap[anotherLink.type];
                if (!type) {
                    continue;
                }
                const direction = anotherLink.target === nodeDetail.id ? 'toNodeDetail' : 'fromNodeDetail';
                const linkTypeAlready = linkMap.get(type.id);
                if (linkTypeAlready && linkTypeAlready.direction !== direction) {
                    linkTypeAlready.direction = 'both';
                    linkMap.set(type.id, linkTypeAlready);
                    continue;
                }
                linkMap.set(type.id, {...type, direction: direction});
            }
        }
        return Array.from(linkMap.values());
    }

    const getRightNode = (): Symbol => {
        if (rightNode) {
            return rightNode;
        }
        let node: Symbol;
        if (link.target?.id === nodeDetail.id) {
            node = link.source! as Symbol;
        } else {
            node = link.target! as Symbol
        }
        rightNode = node;
        return node;
    }

    const onClickLink = (link: Link) => {
        link.select = true;
    }

    return (
        <div
            className={'flex flex-row gap-3 py-3 hover:bg-base-200 cursor-pointer'}
            onClick={() => onClickLink(link)}
        >
            <div className={'flex items-center'}>
                <AppIcon
                    iconSlug={nodeDetail.iconSlug}
                    className={'rounded-badge w-5 h-5 shrink-0 p-1'}
                    gradientColor={`#${nodeDetail.color.getHexString()}`}
                    iconWidth={12}
                    iconHeight={12}
                    iconSrc={nodeDetail.iconURL}
                    title={nodeDetail.originalData?.title}
                />
            </div>
            <div className={'flex flex-col gap-0.5 items-center justify-center'}>
                {resolveLinks().map((linkType) => (
                    <div key={linkType.id} className={classNames(linkType.contentColor, 'flex items-center relative h-5')}>
                        {linkType.direction === 'both' ? (
                            <ArrowBoth/>
                        ) : linkType.direction === 'toNodeDetail' ? (
                            <ArrowLeft/>
                        ) : (
                            <ArrowRight/>
                        )}
                        <div
                            className={classNames(linkType.bgColor, linkType.contentColor, 'absolute min-w-[3.75rem] text-center border-l-2 border-r-2 border-base-100 h-full left-0 right-0 mx-auto w-max text-sm leading-3 py-1 px-2 rounded-badge')}>
                            {linkType.label}
                        </div>
                    </div>
                ))}
            </div>
            <div className={'flex items-center'}>
                <AppIcon
                    iconSlug={getRightNode().iconSlug}
                    className={'rounded-badge w-6 h-6 shrink-0 p-1'}
                    gradientColor={`#${getRightNode().color.getHexString()}`}
                    iconWidth={16}
                    iconHeight={16}
                    iconSrc={getRightNode().iconURL}
                    title={getRightNode().originalData?.title}
                />
            </div>
            <div className={'flex items-center pl-1 [word-break:break-word]'}>
                {getRightNode().title}
            </div>
            {otherFolder && (
                <div className={'flex flex-col justify-center text-base-content-400 ml-auto'}>
                    <Folders className={'w-4 h-4'} />
                </div>
            )}
        </div>
    );
}