import * as THREE from 'three';

import { Repository } from '../common/Repository';
import { LINK_SHAPE_Y_POSITION } from '../common/constants';

import { BaseObject } from '../common/BaseObject';
import { BaseSymbol } from '../symbols/BaseSymbol';
import { LinkDuplicatesShape } from './LinkDuplicatesShape';

export class LinkDuplicates extends BaseObject{

	static interactionLayer = 1;

	private _shape: LinkDuplicatesShape;

	protected _source?: BaseSymbol;
	protected _target?: BaseSymbol;

    constructor () {
        super();

		this._shape = new LinkDuplicatesShape();
		this._shape.instancedOrBatchedMesh = Repository.linksDuplicatesShapesMesh!;
		this._shape.three.position.y = LINK_SHAPE_Y_POSITION;
		this.three.add(this._shape.three);
	}

	get source() {
		return this._source;
	}
	set source(value) {
		if (this._source === value) return;
		this._source = value;
	}

	get target() {
		return this._target;
	}

	set target(value) {
		if (this._target === value) return;
		this._target = value;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this._shape.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}
	override set color(value: THREE.Color) {
		this._color = value;
		this.colorNeedsUpdate = true;

		this._shape.color = value;
	}

	override get tint() {
		return this._tint;
	}

	override set tint(value) {
		if(this._tint === value) return;
		this._tint = value;
		this.tintNeedsUpdate = true;

		this._shape.tint = value;
	}

	update(){
		if(!this.source || !this.target){ return }

		// Define the start and end points
		const pointSource = new THREE.Vector3(0, 0, 0);
		const pointTarget = this.target.three.position.clone().sub(this.source.three.position);

		this.three.position.x = this.source.three.position.x;
		this.three.position.y = this.source.three.position.y;
		this.three.position.z = this.source.three.position.z;
		this.matrixNeedsUpdate = true;

		this._shape.updateGeometry(pointSource, pointTarget);

		this.color = this.source.color;
	}

	override dispose(){
		this._shape.dispose();

		super.dispose();
	}
}