import Tooltip from './utils/Tooltip.tsx'
import SpaceSelect from './spaces/SpaceSelect.tsx'
/*import SpaceForm from './spaces/SpaceForm.tsx'*/
import SpaceSettings from './spaces/SpaceSettings.tsx'
import UserMenu from './utils/UserMenu.tsx';
import ExtendedSearch from './utils/ExtendedSearch.tsx';

/*import { PlusIcon } from '@heroicons/react/20/solid';*/
import { Cog6ToothIcon, ArrowPathIcon, FunnelIcon } from '@heroicons/react/24/outline'
import { useAppStore } from '../store/Store.ts'
import { classNames } from '../utils/helpers.ts'
import UseFiltersState from './utils/filters/FiltersState.ts';
import {MakeLogo} from '../assets/icons/MakeLogo.tsx';

function MainHeader() {

	const {
		activeSpace,
		showSpaceSettings,
		toggleSpaceSettings,
		reloadCosmos,
		showSettings,
		toggleSettings,
		uiInteractive
	} = useAppStore((state) => {
		return {
			activeSpace: state.activeSpace,
			showSpaceSettings: state.showSpaceSettings,
			toggleSpaceSettings: state.toggleSpaceSettings,
			reloadCosmos: state.reloadCosmos,
			showSettings: state.showSettings,
			toggleSettings: state.toggleSettings,
			enableUIInteractions: state.enableUIInteractions,
			disableUIInteractions: state.disableUIInteractions,
			uiInteractive: state.uiInteractive
		}
	});
	const {activeFiltersCount} = UseFiltersState();
	//const openModal = useAppStore((state) => state.openModal);

	/*const newSpace = () => {
		openModal(SpaceForm);
	}*/

	const reloadMesh = () => {
		reloadCosmos();
	}

	// TODO
	const isAuthed = true; // (useRouteLoaderData('root') as {isAuthed: boolean}).isAuthed;

	return (
		<header className='relative flex items-center justify-between p-6 pb-3 z-20 w-screen select-none'>
			<div className="ui-panel ui-pointer-events flex flex-wrap gap-3 px-3 py-1.5 items-center p-4">
				<div className='flex items-center gap-2 h-12 w-12 -m-4 px-2 box-content'>
					<MakeLogo />
				</div>
				{isAuthed && (
					<>
						<div className="h-5 w-px bg-base-content/30"></div>
						<ul className='flex items-center gap-2'>
							<>
								{/*<li>
									<Tooltip message={"Back to Space"}>
										<Link
											to={`/spaces/${activeSpace?.id}/`}
											className="rounded-md bg-transparent p-1 text-gray-400 hover:text-gray-600"
										>
											<HomeIcon className="h-5 w-5" aria-hidden="true" />
										</Link>
									</Tooltip>
								</li>*/}
								{/*<li>
									<Tooltip message={"Search"}>
										<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
									</Tooltip>
								</li>*/}
							<li className={'flex items-center'}>
								<SpaceSelect />
							</li>
							</>
						</ul>
						{activeSpace && (
							<>
								<div className="h-5 w-px bg-base-content/30"></div>
								<ul className='flex items-center gap-2'>
									<li className="relative">
										<Tooltip message={"Space Settings"}>
											<button
												type="button"
												className={classNames(showSpaceSettings ? 'active' : '', 'ui-btn-icon')}
												onClick={toggleSpaceSettings}
												disabled={!uiInteractive}
											>
												<Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
											</button>
										</Tooltip>
										<SpaceSettings />
									</li>
									{/*<li>
										<Tooltip message={"New Space"}>
											<button
												type="button"
												className="ui-btn-icon"
												onClick={newSpace}
												disabled={!uiInteractive}
											>
												<PlusIcon className="h-5 w-5" aria-hidden="true" />
											</button>
										</Tooltip>
									</li>*/}
									<li>
										<Tooltip message={"Reload Space"}>
											<button
												type="button"
												className="ui-btn-icon"
												onClick={reloadMesh}
												disabled={!uiInteractive}
											>
												<ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
											</button>
										</Tooltip>
									</li>
								</ul>
							</>
						)}
						
				</>
				)}
				<div className="h-5 w-px bg-base-content/30"></div>
				<ul className='flex items-center gap-2'>
					<li className='relative'>
						{/*<div className="relative rounded-md shadow-sm">
							<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
								<MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
							</div>
							<input
								type="text"
								name="searchGlobal"
								id="searchGlobal"
								className="block w-48 rounded-md border-0 py-0.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none"
								placeholder="Search"
								onChange={(e) => currentMeshInstance?.search(e.target.value)}
							/>
						</div>*/}
						<ExtendedSearch />
					</li>
					<li className='relative'>
						<Tooltip message={"Filters"}>
							<button
								type="button"
								className={classNames(showSettings ? 'active' : '', 'ui-btn-icon')}
								onClick={toggleSettings}
								disabled={!uiInteractive}
							>
								<FunnelIcon className="h-5 w-5" aria-hidden="true"/>
							</button>
						</Tooltip>
						{activeFiltersCount > 0 &&
							<div
								className={classNames('ui-active-count-indicator-circle')}
							>{activeFiltersCount}</div>
						}
					</li>
				</ul>
			</div>

			{!isAuthed && (
				<div
					className="flex gap-4 px-4 py-2 bg-white/95 items-center rounded-md p-4 shadow-lg shadow-black/5 ring-1 ring-slate-700/10 ui-pointer-events">
					<a href="/login">Log In</a>
				</div>
			)}
			{isAuthed && (
				<UserMenu />
			)}
		</header>
	);
}

export default MainHeader;
