import { SerializedLink } from "@/three/links/BaseLink";
import { Symbol } from '@/three/symbols/Symbol';
import { Link } from "@/three/links/Link";

export type LinkDirection = 'fromNodeDetail' | 'toNodeDetail' | 'both';

export interface LinkModuleMapping {
    module: string;
    moduleId: number;
    customLabel: string;
}

export type LinkItem = {
    node: Symbol,
    link: Link,
    otherFolder?: boolean
}

export type LinkType = {
    id: string;
    label: string;
    bgColor: string;
    contentColor: string;
    direction?: LinkDirection;
}

export const triggerLink: LinkType = {
    id: 'trigger',
    label: 'Trigger',
    bgColor: 'bg-trigger',
    contentColor: 'text-trigger-content'
}

export const readLink: LinkType = {
    id: 'read',
    label: 'Read',
    bgColor: 'bg-success',
    contentColor: 'text-success-content'
}

export const writeLink: LinkType = {
    id: 'write',
    label: 'Write',
    bgColor: 'bg-info',
    contentColor: 'text-info-content'
}

export const linkTypeMap: {[key:string]: LinkType} = {
    'system:listen': triggerLink,
    'system:invoke': triggerLink,
    'system:read': readLink,
    'system:update': writeLink,
    'system:base': readLink,
    'system:arrow': triggerLink,
    'system:create': writeLink,
    'system:delete': writeLink,
    'system:stream': writeLink
}

export interface LinkModule {
    name: string;
    id: number;
    originalType: string;
    customLabel?: string;
}

export interface LinkDetailItem {
    leftSymbol: Symbol,
    rightSymbol: Symbol,
    linkType: LinkType,
    modules: LinkModule[],
}

export interface LinkDetailData {
    items: LinkDetailItem[]
}

export const getAllLinksByLink = (link: Link): SerializedLink[] => {

    const allLinks: SerializedLink[] = [];
    if (link.originalData) {
        allLinks.push(link.originalData);

        for (const otherLink of link.originalData.allLinks) {
            allLinks.push(otherLink);
        }
    }

    return allLinks;
}

const getPriorityLinkItem = (obj: LinkItem) => {
    if (obj.link.isTrigger) return 1;
    if (obj.link.isWrite) return 2;
    if (obj.link.isRead) return 3;
    return 4;
};

const getPriorityLinkDetailItem = (obj: LinkDetailItem) => {
    if (obj.linkType.id === 'trigger') return 1;
    if (obj.linkType.id === 'write') return 2;
    if (obj.linkType.id === 'read') return 3;
    return 4;
};

export const sortLinkItems = (a: LinkItem, b: LinkItem) => {
    if (a.otherFolder && !b.otherFolder) return 1;
    if (!a.otherFolder && b.otherFolder) return -1;
    if (a.otherFolder && b.otherFolder) return 0;

    return getPriorityLinkItem(a) - getPriorityLinkItem(b);
}

export const sortLinkDetailItems = (a: LinkDetailItem, b: LinkDetailItem) => {
    return getPriorityLinkDetailItem(a) - getPriorityLinkDetailItem(b);
}

export const getLinkDetailData = (mainLink: Link): LinkDetailData => {

    const allLinks = getAllLinksByLink(mainLink);
    const linkItemsMap: Map<string, LinkDetailItem> = new Map();

    for (const link of allLinks) {

        const modules: LinkModule[] = [];

        if ((link.sourceAttributes.mappings as LinkModuleMapping[])?.length) {
            for (const moduleMapping of link.sourceAttributes.mappings as LinkModuleMapping[]) {
                modules.push({
                    name: moduleMapping.module,
                    id: moduleMapping.moduleId,
                    originalType: link.type,
                    customLabel: moduleMapping.customLabel ?? undefined,
                })
            }
        }

        const linkType = linkTypeMap[link.type];
        if (!linkType) {
            continue;
        }
        const linkDirection = link.target === mainLink.source!.id ? 'toNodeDetail' : 'fromNodeDetail';

        const linkTypeAlready = linkItemsMap.get(linkType.id);
        if (linkTypeAlready) {
            linkTypeAlready.modules = [...linkTypeAlready.modules, ...modules];
            if (linkTypeAlready.linkType.direction !== linkDirection) {
                linkTypeAlready.linkType.direction = 'both';
            }
            linkItemsMap.set(linkType.id, linkTypeAlready);
        } else {
            linkType.direction = linkDirection;
            linkItemsMap.set(linkType.id, {
                leftSymbol: mainLink.source! as Symbol,
                rightSymbol: mainLink.target! as Symbol,
                linkType: linkType,
                modules: modules
            });
        }
    }

    const items = Array.from(linkItemsMap.values());

    items.sort(sortLinkDetailItems);

    return {
        items
    }
}