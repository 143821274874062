import * as THREE from 'three';

// Common
export const SCALE_FACTOR = 100;

export const PREVENT_Z_FIGHTING = 0.1 / SCALE_FACTOR; // Prevents glitch when two objects are in the same position because of unavoidable rounding errors in the shaders.

// Renderer
export const RENDERER_SHADOW_MAP_WIDTH = 2048;
export const RENDERER_SHADOW_MAP_HEIGHT = 2048;

// Symbol in node panel
export const SYMBOL_CANVAS_WIDTH = 128;
export const SYMBOL_CANVAS_HEIGHT = 128;

// Ground
export const GROUND_FILL_Y_POSITION = 0 / SCALE_FACTOR + PREVENT_Z_FIGHTING;
export const GROUND_PADDING = 500 / SCALE_FACTOR;

export const GROUND_SHADOW_Y_POSITION = GROUND_FILL_Y_POSITION + PREVENT_Z_FIGHTING;
export const GROUND_SHADOW_X_OFFSET = 50 / SCALE_FACTOR;
export const GROUND_SHADOW_Z_OFFSET = -25 / SCALE_FACTOR;
export const GROUND_SHADOW_MAP_WIDTH = 512;
export const GROUND_SHADOW_MAP_HEIGHT = 512;

// Grid
export const GRID_SHORT_SUBDIV = 50 / SCALE_FACTOR;
export const GRID_LARGE_SUBDIV = 500 / SCALE_FACTOR;

// Groups
export const GROUP_ROUNDED_RECT_RADIUS = 64 / SCALE_FACTOR;
export const GROUP_HEIGHT = 20 / SCALE_FACTOR;
export const GROUP_Y_POSITION = GROUND_SHADOW_Y_POSITION + (64 / SCALE_FACTOR) * 2;
export const GROUP_SIDE_Y_POSITION = 0;
export const GROUP_TOP_Y_POSITION = GROUP_HEIGHT + PREVENT_Z_FIGHTING;

// Symbols
export const SYMBOL_SIZE = 64 / SCALE_FACTOR;
export const SYMBOL_Y_POSITION = GROUP_Y_POSITION + GROUP_TOP_Y_POSITION + PREVENT_Z_FIGHTING;

export const SYMBOL_SHAPE_Y_POSITION = PREVENT_Z_FIGHTING;

export const SYMBOL_ROUNDED_RECT_RADIUS = 12 / SCALE_FACTOR;
export const SYMBOL_CIRCLE_RADIUS = 32 / SCALE_FACTOR;
export const SYMBOL_ROUNDED_TRIANGLE_RADIUS = 12 / SCALE_FACTOR;

export const SYMBOL_PAD_SIZE = SYMBOL_SIZE * 2;
export const SYMBOL_PAD_RADIUS = SYMBOL_CIRCLE_RADIUS * 2;
export const SYMBOL_PAD_Y_POSITION = PREVENT_Z_FIGHTING;

export const SYMBOL_HEIGHT = 20 / SCALE_FACTOR;

export const GROUP_PADDING = SYMBOL_PAD_SIZE * 1.5;

export const ICON_SIZE = 30 / SCALE_FACTOR;
export const ICON_Y_POSITION = SYMBOL_SHAPE_Y_POSITION + SYMBOL_HEIGHT + PREVENT_Z_FIGHTING;

export const SYMBOL_HIDE_Y_POSITION = -ICON_Y_POSITION;
export const GROUP_HIDE_Y_POSITION = -ICON_Y_POSITION - GROUP_HEIGHT - PREVENT_Z_FIGHTING;

export const SELECCTION_INDICATOR_Y_POSITION = SYMBOL_PAD_Y_POSITION + PREVENT_Z_FIGHTING;

// Fog
export const FOG_Y_POSITION = GROUND_FILL_Y_POSITION;
export const FOG_HEIGHT = GROUP_HEIGHT * 2;

// Shared symbols
export const SYMBOL_SHARED_SIZE = 64 / SCALE_FACTOR;
export const SYMBOL_SHARED_HEIGHT = SYMBOL_SHARED_SIZE / 2;
export const SYMBOL_SHARED_Y_OFFSET = 400 / SCALE_FACTOR;
export const SYMBOL_SHARED_Y_POSITION = GROUP_Y_POSITION + GROUP_TOP_Y_POSITION + SYMBOL_SHARED_Y_OFFSET + PREVENT_Z_FIGHTING;

// Links
export const LINK_PORT_OUTLINE_RADIUS = 12 / SCALE_FACTOR;
export const LINK_PORT_RADIUS = 8 / SCALE_FACTOR;
export const LINK_PORT_Y_POSITION = SELECCTION_INDICATOR_Y_POSITION + PREVENT_Z_FIGHTING;

export const LINK_COLOR = new THREE.Color("#7F7F7F");
export const LINK_TRIGGER_COLOR = new THREE.Color("#93C");
export const LINK_WIDTH = 7 / SCALE_FACTOR;
export const LINK_HEIGHT = 6 / SCALE_FACTOR;
export const LINK_Y_POSITION = LINK_PORT_Y_POSITION + PREVENT_Z_FIGHTING;

export const LINK_SHAPE_Y_POSITION = LINK_Y_POSITION + PREVENT_Z_FIGHTING;

// Link indicator
export const LINK_INDICATOR_SIZE = 20 / SCALE_FACTOR;
export const LINK_INDICATOR_VELOCITY = 200 / SCALE_FACTOR;
export const LINK_INDICATOR_Y_POSITION = LINK_SHAPE_Y_POSITION + PREVENT_Z_FIGHTING;

// Duplicates links
export const LINK_DUPLICATES_MAX_Y = 1000 / SCALE_FACTOR;

// Selection indicator
export const SELECTION_INDICATOR_OUTER_RADIUS = SYMBOL_PAD_SIZE / 2 + ((LINK_WIDTH / 1.5) / 2);
export const SELECTION_INDICATOR_INNER_RADIUS = SYMBOL_PAD_SIZE / 2 - ((LINK_WIDTH / 1.5) / 2);

// Symbol Legends
export const LEGEND_TEXT_WEIGHT = "400";
export const LEGEND_BACKGROUND_RADIUS = 6;
export const LEGEND_BORDER_SIZE = 1.5;
export const LEGEND_BORDER_RADIUS = LEGEND_BACKGROUND_RADIUS + LEGEND_BORDER_SIZE;
export const LEGEND_FONT_SIZE = 13;
export const LEGEND_WIDTH = 238; // Fits 18 ems + '...', should be 244 to be accurate but we reduced it by a few pixels to fit an entire more column
export const LEGEND_HEIGHT = 28;
export const LEGEND_HORIZONTAL_PADDING = 10;
export const LEGEND_MAX_UNTRIMMED_CHARS = 18;
export const LEGEND_POSITION_Y_OFFSET = 56 / SCALE_FACTOR;
export const LEGENDS_INITIAL_ZOOM = 0.4;
export const LEGENDS_FINAL_ZOOM = 0.5;
export const LEGENDS_ATLAS_SCALE = 1.5; // To prevent text blurriness. 2 Is the crispier but a lower value can be better if it looks good as it speeds up texture loading to the GPU by A LOT.
export const LEGENDS_ATLAS_WIDTH = 4096 * LEGENDS_ATLAS_SCALE;
export const LEGENDS_ATLAS_HEIGHT = 4096 * LEGENDS_ATLAS_SCALE;

// Group Legends
export const LEGEND_GROUPS_TEXT_WEIGHT = "500";
export const LEGEND_GROUPS_BACKGROUND_RADIUS = 6;
export const LEGEND_GROUPS_BORDER_SIZE = 1.5;
export const LEGEND_GROUPS_BORDER_RADIUS = LEGEND_GROUPS_BACKGROUND_RADIUS + LEGEND_GROUPS_BORDER_SIZE;
export const LEGEND_GROUPS_FONT_SIZE = 15;
export const LEGEND_GROUPS_WIDTH = 390;
export const LEGEND_GROUPS_HEIGHT = 34;
export const LEGEND_GROUPS_OFFSET_X = 80 / SCALE_FACTOR;
export const LEGEND_GROUPS_OFFSET_Z = 80 / SCALE_FACTOR;
export const LEGEND_GROUPS_FOLDER_WIDTH = 14;
export const LEGEND_GROUPS_FOLDER_HEIGHT = 14;
export const LEGEND_GROUPS_MAX_UNTRIMMED_CHARS = 36;
export const LEGENDS_GROUPS_ATLAS_WIDTH = 2048 * LEGENDS_ATLAS_SCALE;
export const LEGENDS_GROUPS_ATLAS_HEIGHT = 1024 * LEGENDS_ATLAS_SCALE;

// Interaction
export const INTERACTION_SPOTLIGHT_DELAY = 0.3; // in seconds
export const INTERACTION_SPOTLIGHT_STAGGERING = 0.15; // in seconds

export const INTERACTION_ANIMATION_SPEED_FAST = 0.3; // in seconds
export const INTERACTION_ANIMATION_SPEED_MEDIUM = 0.6; // in seconds
export const INTERACTION_ANIMATION_SPEED_SLOW = 1.2; // in seconds

export const MAX_GROUPS_TRANSITION_STAGGER = INTERACTION_ANIMATION_SPEED_SLOW; // in seconds

export const PAUSE_TO_FREE_RESOURCES = 0.3; // in seconds

export const CAMERA_FIT_WIDTH_MARGIN = 0.1; // in percentage of the viewport from 0 to 1
export const CAMERA_FIT_HEIGHT_MARGIN = 0.1; // in percentage of the viewport from 0 to 1

export const IDLE_ROATION_SPEED = 60; // in seconds
export const MAX_USER_IDLE_TIME = 300; // in seconds - 60secs * 5mins = 300secs


// Yfiles
export const YFILES_GROUPS_MIN_DISTANCE = GROUP_PADDING * 2 + SYMBOL_PAD_SIZE * 1.5;
export const YFILES_SYMBOLS_MIN_DISTANCE = SYMBOL_PAD_SIZE * 1.5;

// Theme (Light / Dark)
export const GROUND_COLOR_LIGHT = new THREE.Color("#DFD7E0");
export const GROUND_COLOR_DARK = new THREE.Color("#212022");

export const GROUPS_TOP_COLOR_LIGHT = new THREE.Color("#ffffff");
export const GROUPS_TOP_COLOR_DARK = new THREE.Color("#262526");

export const GROUPS_SIDE_COLOR_LIGHT = new THREE.Color("#c76ef0");
export const GROUPS_SIDE_COLOR_DARK = new THREE.Color("#603B66");

export const GROUPS_SIDE_TINT_COLOR_LIGHT = new THREE.Color("#EBEAEC");
export const GROUPS_SIDE_TINT_COLOR_DARK = new THREE.Color("#444245");

export const SYMBOLS_PAD_COLOR_LIGHT = new THREE.Color("#EFEDF0");
export const SYMBOLS_PAD_COLOR_DARK = new THREE.Color("#383638");

export const SYMBOLS_MUTED_TINT_LIGHT = new THREE.Color("#EBEAEC");
export const SYMBOLS_MUTED_TINT_DARK = new THREE.Color("#444245");

export const LINK_PORT_COLOR_LIGHT = new THREE.Color("#ffffff");
export const LINK_PORT_COLOR_DARK = new THREE.Color("#262526");

export const LINK_DEFAULT_COLOR_LIGHT = new THREE.Color("#DFDEE0");
export const LINK_DEFAULT_COLOR_DARK = new THREE.Color("#424042");

export const LINK_TRIGGER_COLOR_LIGHT = new THREE.Color("#CD8FE3");
export const LINK_TRIGGER_COLOR_DARK = new THREE.Color("#774A80");

export const LINKS_MUTED_TINT_LIGHT = new THREE.Color("#EFEDF0");
export const LINKS_MUTED_TINT_DARK = new THREE.Color("#383638");

export const LINK_INDICATOR_COLOR_LIGHT = new THREE.Color("#B04CFF");
export const LINK_INDICATOR_COLOR_DARK = new THREE.Color("#D989E5");

export const LEGEND_BORDER_COLOR_LIGHT = "#EFEDF0";
export const LEGEND_BORDER_COLOR_DARK = "#383638";

export const LEGEND_BACKGROUND_COLOR_LIGHT = "#FFFFFF"; // Is rendered in an offscreen canvas, not color managed by THREE, so it doesn't have to be a THREE.Color instance
export const LEGEND_BACKGROUND_COLOR_DARK = "#2E2C2E"; // Is rendered in an offscreen canvas, not color managed by THREE, so it doesn't have to be a THREE.Color instance

export const LEGEND_TEXT_COLOR_LIGHT = "#393939"; // Is rendered in an offscreen canvas, not color managed by THREE, so it doesn't have to be a THREE.Color instance
export const LEGEND_TEXT_COLOR_DARK = "#D5D5D5"; // Is rendered in an offscreen canvas, not color managed by THREE, so it doesn't have to be a THREE.Color instance

export const SELECCTION_INDICATOR_COLOR_LIGHT = new THREE.Color("#BC5EE8");
export const SELECCTION_INDICATOR_COLOR_DARK = new THREE.Color("#C34AFC");