type Props = {
    className?: string,
}

export const Trigger = ({className}: Props) => {
    return (
        <svg className={'w-5 h-5 ' + (className || '')} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" rx="4" transform="matrix(-1 0 0 1 18.0703 -0.00585938)" fill="currentColor" fillOpacity="0.3"/>
            <path d="M6.20859 8.98213H12.3203M12.3203 8.98213L12.3203 5.75207M12.3203 8.98213L12.3203 12.2361"
                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.15076 6.38206L4.55076 8.98206L7.15076 11.5821V6.38206Z" fill="currentColor" stroke="currentColor"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}