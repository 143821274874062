import * as THREE from 'three';
import { CosmosThree } from '../CosmosThree';
import { LEGEND_BACKGROUND_COLOR_DARK, LEGEND_BACKGROUND_COLOR_LIGHT, LEGEND_BACKGROUND_RADIUS, LEGEND_BORDER_COLOR_DARK, LEGEND_BORDER_COLOR_LIGHT, LEGEND_BORDER_RADIUS, LEGEND_BORDER_SIZE, LEGEND_FONT_SIZE, LEGEND_GROUPS_TEXT_WEIGHT, LEGEND_HEIGHT, LEGEND_HORIZONTAL_PADDING, LEGEND_POSITION_Y_OFFSET, LEGEND_TEXT_COLOR_DARK, LEGEND_TEXT_COLOR_LIGHT, LEGENDS_ATLAS_SCALE, SCALE_FACTOR } from '../common/constants';
import { Symbol } from './Symbol';
import { drawRoundedRect } from '../utils/misc/Canvas2DUtils';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionMapThemeable } from '../utils/materials/extensions/ExtensionMapThemeable';

export class SymbolFullLegend extends THREE.Mesh{

    canvasLight: OffscreenCanvas;
    ctxLight: OffscreenCanvasRenderingContext2D | null;

    canvasDark: OffscreenCanvas;
    ctxDark: OffscreenCanvasRenderingContext2D | null;

    // Everything will be double size to upscale the generated bitmap and avoid blurring of text as much as possible.
    legendWidth = 1;
    legendHeight = 1;

    private _title = "";

    textureLight: THREE.CanvasTexture;
    textureDark: THREE.CanvasTexture;

    private _vector = new THREE.Vector3();
    private _mtx = new THREE.Matrix4();
    private _symbol: Symbol |null = null;

    constructor(){
        super();

        this.canvasLight = new OffscreenCanvas(this.legendWidth, this.legendHeight);
        this.ctxLight = this.canvasLight.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.canvasDark = new OffscreenCanvas(this.legendWidth, this.legendHeight);
        this.ctxDark = this.canvasDark.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.textureLight = new THREE.CanvasTexture(this.canvasLight);
        this.textureDark = new THREE.CanvasTexture(this.canvasDark);

        this.material = new (ExtendedMaterial as any)(
            THREE.MeshBasicMaterial,
            [ExtensionMapThemeable],
            {
				mapLight: this.textureLight,
				mapDark: this.textureDark,
				blendFactor: CosmosThree.legendBlendFactor,
                transparent: true,
				depthWrite: false, // If this is removed something weird happens with the legends when they overlap each other
                //wireframe: true
            },
            { debug: false }
        );

        this.geometry = new THREE.PlaneGeometry(1, 1, 1, 1);

        this.visible = false;
    }

    get title (){
        return this._title || "";
    }

    set title (value: string){
        if(this._title === value) return;
        this._title = value || "";
    }

    setFrom(symbol: Symbol | null){
        // if(this._symbol === symbol || (symbol && symbol.title.length < LEGEND_MAX_UNTRIMMED_CHARS)) return;
        if(this._symbol === symbol) return;   

        if(this._symbol){
            // We restore the scale of the previous symbol legend
            this._symbol.legend.three.scale.set(1 ,1 ,1);
            this._symbol.legend.matrixNeedsUpdate = true;
        }

        this._symbol = symbol;

        if(this._symbol){
            this.visible = true;

            this.title = this._symbol.title;

            // We restore set the scale of the current symbol legend to 0 to 'hide' it while we show the full legend
            this._symbol.legend.three.scale.set(0 ,0 ,0);
            this._symbol.legend.matrixNeedsUpdate = true;

            this.updateLegend();
        }else{
            this.visible = false;
        }
    }

    private getMeasuredWidth(text: string, ctx: OffscreenCanvasRenderingContext2D | null): number {
        if (!ctx) return 0;
    
        ctx.font = `${LEGEND_GROUPS_TEXT_WEIGHT} ${LEGEND_FONT_SIZE * LEGENDS_ATLAS_SCALE}px "Inter var", sans`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.direction = "inherit";

        return ctx.measureText(text).width;
    }

    /* private getMeasuredLineHeight() {
        // Yes, historicaly 'em' unit stands for 'standard size of letter M'.
        // We multiply it by a 1.2 factor to account the height for font ligatures.
        return this.getMeasuredWidth("M") * 1.2;
    } */

    updateLegend(){
        if(this.ctxLight && this.ctxDark){
            // compute the necessary dimensions of the legend
            this.legendWidth = (Math.ceil(this.getMeasuredWidth(this._title, this.ctxLight)) || 1) + (LEGEND_BACKGROUND_RADIUS * LEGENDS_ATLAS_SCALE) * 2 + LEGEND_HORIZONTAL_PADDING * LEGENDS_ATLAS_SCALE;
            this.legendHeight = LEGEND_HEIGHT * LEGENDS_ATLAS_SCALE;
            // this.legendHeight = Math.ceil(this.getMeasuredLineHeight()) || 1;

            // Resize offscreen canvas
            this.canvasLight.width = this.legendWidth;
            this.canvasLight.height = this.legendHeight;
            this.canvasDark.width = this.legendWidth;
            this.canvasDark.height = this.legendHeight;

            // This block is super important so the premultipliedAlpha result from the canvas is done correctly and we get no grey oulines when rendered
            // This is to prevent what is commonly known as 'Alpha bleeding'
            // Light
            this.ctxLight.fillStyle = "rgba(255, 255, 255, 0.002)"; // rgba(255, 255, 255, 0.002)
            this.ctxLight.fillRect(0, 0, this.legendWidth, this.legendHeight);
            // Dark
            this.ctxDark.fillStyle = "rgba(0, 0, 0, 0.002)"; // rgba(0, 0, 0, 0.002)
            this.ctxDark.fillRect(0, 0, this.legendWidth, this.legendHeight);

            // When the canvas is resized the context is reset, so we have to apply the font settings again
            // Light
            this.ctxLight.font = `${LEGEND_GROUPS_TEXT_WEIGHT} ${LEGEND_FONT_SIZE * LEGENDS_ATLAS_SCALE}px "Inter var", sans`;
            this.ctxLight.textAlign = "center";
            this.ctxLight.textBaseline = "middle";
            this.ctxLight.direction = "inherit";
            // Dark
            this.ctxDark.font = `${LEGEND_GROUPS_TEXT_WEIGHT} ${LEGEND_FONT_SIZE * LEGENDS_ATLAS_SCALE}px "Inter var", sans`;
            this.ctxDark.textAlign = "center";
            this.ctxDark.textBaseline = "middle";
            this.ctxDark.direction = "inherit";

            /* this.ctx.fillStyle = "#0f0";
            this.ctx.fillRect(0, 0, this.legendWidth, this.legendHeight);

            // Debug red border
            this.ctx.fillStyle = "#f00";
            this.ctx.fillRect(0, 0, this.legendWidth, 2);
            this.ctx.fillRect(this.legendWidth - 2, 0, 2, this.legendHeight -2);
            this.ctx.fillRect(0, 0, 2, this.legendHeight -2);
            this.ctx.fillRect(0, this.legendHeight - 2, this.legendWidth , 2); */

            // Light
            this.ctxLight.fillStyle = LEGEND_BORDER_COLOR_LIGHT;
            drawRoundedRect(this.ctxLight, 2, 2, this.legendWidth - 4 , this.legendHeight - 4, LEGEND_BORDER_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxLight.fill();
            // Dark
            this.ctxDark.fillStyle = LEGEND_BORDER_COLOR_DARK;
            drawRoundedRect(this.ctxDark, 2, 2, this.legendWidth - 4 , this.legendHeight - 4, LEGEND_BORDER_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxDark.fill();

            // Light
            this.ctxLight.fillStyle = LEGEND_BACKGROUND_COLOR_LIGHT;
            drawRoundedRect(this.ctxLight, 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, this.legendWidth - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, this.legendHeight - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, LEGEND_BACKGROUND_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxLight.fill();
            // Dark
            this.ctxDark.fillStyle = LEGEND_BACKGROUND_COLOR_DARK;
            drawRoundedRect(this.ctxDark, 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, this.legendWidth - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, this.legendHeight - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, LEGEND_BACKGROUND_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxDark.fill();

            // Light
            this.ctxLight.fillStyle = LEGEND_TEXT_COLOR_LIGHT;
            this.ctxLight.fillText(this._title, this.legendWidth / 2, this.legendHeight / 2);
            // Dark
            this.ctxDark.fillStyle = LEGEND_TEXT_COLOR_DARK;
            this.ctxDark.fillText(this._title, this.legendWidth / 2, this.legendHeight / 2);
        }

        this.geometry.dispose();
        this.geometry = new THREE.PlaneGeometry(this.legendWidth / LEGENDS_ATLAS_SCALE / SCALE_FACTOR, this.legendHeight / LEGENDS_ATLAS_SCALE / SCALE_FACTOR , 1, 1);

        this.textureLight.dispose();
        this.textureDark.dispose();

        this.textureLight = new THREE.CanvasTexture(this.canvasLight);
        this.textureLight.premultiplyAlpha = false;
        this.textureLight.colorSpace = THREE.SRGBColorSpace;
        this.textureLight.generateMipmaps = false;
        this.textureLight.anisotropy = CosmosThree.anisotropy;

        this.textureDark = new THREE.CanvasTexture(this.canvasDark);
        this.textureDark.premultiplyAlpha = false;
        this.textureDark.colorSpace = THREE.SRGBColorSpace;
        this.textureDark.generateMipmaps = false;
        this.textureDark.anisotropy = CosmosThree.anisotropy;

        (this.material as any).mapLight = this.textureLight;
        (this.material as any).mapDark = this.textureDark;
    }

    sync(){
        if(this._symbol && this.visible){
			this._vector.setFromMatrixPosition(this._symbol.three.matrix);

			this._vector.add(CosmosThree.meshOffset);
			this._vector.project(CosmosThree.graphCamera); // `camera` is a THREE.PerspectiveCamera

			this._vector.unproject(CosmosThree.guiCamera);

			// Update the matrices of the objects in the scene
			this._vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;
			this._mtx.makeTranslation(this._vector);

            this.position.set(this._vector.x, this._vector.y, this._vector.z);
		}
    }

    downloadTexture(){
        // Downloads the offscreen canvas
        this.downloadAsImage('canvas_image');
    }

    downloadAsImage(filename: string) {
        // Convert OffscreenCanvas to Blob
        this.canvasLight.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename + "Light.png";
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });

        // Convert OffscreenCanvas to Blob
        this.canvasDark.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename + "Dark.png";
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        (this.material as THREE.Material).dispose();
        this.geometry.dispose();

        this.textureLight.dispose();
        this.textureDark.dispose();

        super.clear();

        return this;
    }
}