import * as THREE from 'three';

import { SYMBOL_HEIGHT, SYMBOL_ROUNDED_RECT_RADIUS, SYMBOL_SIZE } from '../../common/constants';
import { BufferGeometryUtils } from 'three/examples/jsm/Addons.js';
import { Symbol } from '@/three/symbols/Symbol';

export class StorageSymbol extends Symbol {

	static override getGeometry() {
		const shape = new THREE.Shape();
		const angleStep = Math.PI * 0.5;
		const size = SYMBOL_SIZE;
		const radius = SYMBOL_ROUNDED_RECT_RADIUS;

		shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
		shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
		shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
		shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

		const geometryBottom = new THREE.ExtrudeGeometry(shape, {
			depth: 2 * (SYMBOL_HEIGHT / 5),
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).rotateX(-Math.PI/2);

		geometryBottom.clearGroups();

		const geometryTop = geometryBottom.clone();
		geometryTop.translate(0, 3 * (SYMBOL_HEIGHT / 5), 0);

		const geometry = BufferGeometryUtils.mergeGeometries([geometryBottom, geometryTop]);

        return geometry;
    }
}