import supabase from '../utils/supabase-client';

import signals from 'signals';

import * as THREE from 'three';

import { gsap } from "gsap";

import _ from 'lodash';

import { Repository } from './common/Repository.ts';

import { Symbol} from './symbols/Symbol.ts';
import { Link } from './links/Link.ts';

import {LayoutManager} from './utils/layout/LayoutManager';

import{ CosmosThree } from './CosmosThree';
import { BaseObject } from './common/BaseObject.ts';
import { CAMERA_FIT_HEIGHT_MARGIN, CAMERA_FIT_WIDTH_MARGIN, GROUND_COLOR_DARK, GROUND_COLOR_LIGHT, GROUPS_SIDE_COLOR_DARK, GROUPS_SIDE_COLOR_LIGHT, GROUPS_SIDE_TINT_COLOR_DARK, GROUPS_SIDE_TINT_COLOR_LIGHT, GROUPS_TOP_COLOR_DARK, GROUPS_TOP_COLOR_LIGHT, IDLE_ROATION_SPEED, INTERACTION_ANIMATION_SPEED_FAST, INTERACTION_ANIMATION_SPEED_MEDIUM, INTERACTION_ANIMATION_SPEED_SLOW, INTERACTION_SPOTLIGHT_DELAY, INTERACTION_SPOTLIGHT_STAGGERING, LINK_DEFAULT_COLOR_DARK, LINK_DEFAULT_COLOR_LIGHT, LINK_INDICATOR_COLOR_DARK, LINK_INDICATOR_COLOR_LIGHT, LINK_PORT_COLOR_DARK, LINK_PORT_COLOR_LIGHT, LINK_TRIGGER_COLOR_DARK, LINK_TRIGGER_COLOR_LIGHT, LINKS_MUTED_TINT_DARK, LINKS_MUTED_TINT_LIGHT, MAX_GROUPS_TRANSITION_STAGGER, PAUSE_TO_FREE_RESOURCES, SCALE_FACTOR, SELECCTION_INDICATOR_COLOR_DARK, SELECCTION_INDICATOR_COLOR_LIGHT, SYMBOL_PAD_SIZE, SYMBOLS_MUTED_TINT_DARK, SYMBOLS_MUTED_TINT_LIGHT, SYMBOLS_PAD_COLOR_DARK, SYMBOLS_PAD_COLOR_LIGHT } from './common/constants.ts';
import { ActiveFilters, FilterItem } from '../store/Filter';
import { FilterManager, FilterResult } from './utils/filter/FilterManager';
import { useAppStore } from '../store/Store';
import { SearchManager } from './utils/search/SearchManager.ts';
import { getElementsToSpotlight, getUnmatchedSymbolsAndLinks } from './utils/graph/GraphUtils.ts';
import CameraControls from 'camera-controls';
import { Logger } from '../utils/Logger';
import { Group } from './groups/Group.ts';
import { SelectionIndicator } from './common/SelectionIndicator.ts';
import { SymbolFullLegend } from './symbols/SymbolFullLegend.ts';
import { BaseInstancedMesh } from './common/BaseInstancedMesh.ts';
import { BaseBatchedMesh } from './common/BaseBatchedMesh.ts';
import { assignSymbolsToGroups } from './utils/grouping/GroupUtils.ts';
import { SerializedSymbol } from './symbols/BaseSymbol.ts';
import { SerializedLink } from './links/BaseLink.ts';
import { SymbolShared } from './symbols/SymbolShared.ts';
import { LinkShared } from './links/LinkShared.ts';

export interface SerializedMesh {
	serializedSymbols: SerializedSymbol[];
	serializedLinks: SerializedLink[];
}

export class Mesh extends THREE.Group{

    spaceID: string;
	cosmos: CosmosThree;

	layoutAfterLoad = true;
	pendingCameraReset = false;
	preventCameraReset = false;
	loaded = false;

	symbols: Symbol[] = [];
	links: Link[] = [];
	groups: Group[] = [];

	sharedSymbols: SymbolShared[] = [];
	sharedLinks: LinkShared[] = [];

	_selected: Symbol | Link | null = null;
	private _multiSelected: (Symbol | Link) [] = [];

	cameraMode: "3d" | "2d" = "3d";
	activeTheme: "cosmosLight" | "cosmosDark" = "cosmosLight";

	bbox: THREE.Box3 = new THREE.Box3();

	selectionIndicator : SelectionIndicator;
	fullLegend: SymbolFullLegend;

	showUsages = false;

	camZoomDummy = 0;

	camInterpolation = 0;

	camState = {
		zoom: 1,
		x: 0,
		y: 0,
		z: 0
	}

	initialZoom = 0;
	initialSymbolsLegendsOpacity = 0;
	initialGroupsLegendsOpacity = 0;
	idleTl?: gsap.core.Timeline;
	intoIdleMode = false;

	loadingStarted: signals.Signal;
	loadingEnded: signals.Signal;
	layoutStarted: signals.Signal;
	layoutEnded: signals.Signal;

	idleModeStarted: signals.Signal;
	idleModeStopped: signals.Signal;

	protected _filterResult: FilterResult = {
		symbols: [],
		links: [],
		activeFilterItems: []
	};
	
	private _activeFilterGroups: ActiveFilters = [];
	public filterIndex = '';

	protected _searchManager: SearchManager | null = null;
	protected _layoutManager: LayoutManager;

	private _spotlightDelayedCalls: gsap.core.Tween[] = [];
	private _interactionDelayedCall: gsap.core.Tween | null = null;
	private _fullLegendDelayedCall: gsap.core.Tween | null = null;
	private _baseLayoutBackgroundPromise: Promise<void> | null = null;

    constructor (spaceID: string, cosmos: CosmosThree) {
        super();

		Repository.mesh = this;

		this.name = "mesh";

        this.spaceID = spaceID;
		this.cosmos = cosmos;

		this.selectionIndicator = new SelectionIndicator();
		this.fullLegend = new SymbolFullLegend();

		this.loadingStarted = new signals.Signal();
		this.loadingEnded = new signals.Signal();
		this.layoutStarted = new signals.Signal();
		this.layoutEnded = new signals.Signal();

		this.idleModeStarted = new signals.Signal();
		this.idleModeStopped = new signals.Signal();

		this._layoutManager = new LayoutManager();

		this.cameraMode = cosmos.camMode;
    }

    async load() {
		this.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		this.layoutAfterLoad = true;

		this.loadingStarted.dispatch();

		// load from backend
		Logger.time('[perf] mesh: load symbols and links');
		const { data, error } = await supabase.functions.invoke('symbols-links-processed', {
			body: JSON.stringify({
				spaceId: this.spaceID,
				useMultiLevelGrouping: false,
			})
		});
		Logger.timeEnd('[perf] mesh: load symbols and links');
		if (error) {
			useAppStore.getState().enableUIInteractions();
			this.loadingEnded.dispatch();
			throw error
		}
		if (!data.symbols.length) {
			useAppStore.getState().enableUIInteractions();
			this.loadingEnded.dispatch();
			return;
		}

		const serializedSymbols = data.symbols;
		const serializedLinks = data.links;

		const serializedSharedSymbols = data.sharedSymbols;
		const serializedSharedLinks = data.sharedLinks;

		Logger.time('[perf] mesh: create symbols types');
		// This will generate and store in the Repository the different symbol types and symbol group hierarchy definitions
		Repository.assignSymbolTypes(serializedSymbols);
		Logger.timeEnd('[perf] mesh: create symbols types');

        Logger.time('[perf] mesh: instantiate objects');
		this.initObjectsFromJSON(serializedSymbols, serializedLinks, serializedSharedSymbols, serializedSharedLinks);
		Logger.timeEnd('[perf] mesh: instantiate objects');

		this._searchManager = new SearchManager(serializedSymbols);

		this.loaded = true;
		this.loadingEnded.dispatch();

		localStorage.setItem(`space:${this.spaceID}:viewed`, new Date().toISOString());
    }

	private initObjectsFromJSON(serializedSymbols: SerializedSymbol[], serializedLinks: SerializedLink[], serializedSharedSymbols: SerializedSymbol[], serializedSharedLinks: SerializedLink[]): void {
		this.initSymbols(serializedSymbols);

		this.initLinks(serializedLinks);
		this.initGroups(serializedSymbols);

		this.initSharedSymbols(serializedSharedSymbols);
		this.initSharedLinks(serializedSharedLinks);
		
		this.add(Repository.groupsShapesSideMesh!);
		Repository.groupsShapesSideMesh!.renderOrder = 1;
		this.add(Repository.groupsShapesTopMesh!);
		Repository.groupsShapesTopMesh!.renderOrder = 2;

		this.add(Repository.symbolsPadsMesh!);
		Repository.symbolsPadsMesh!.renderOrder = 3;

		this.add(this.selectionIndicator);
		this.selectionIndicator.renderOrder = 4;

		this.add(Repository.linksPortsMesh!);
		Repository.linksPortsMesh!.renderOrder = 6;
		this.add(Repository.linksShapesMesh!);
		Repository.linksShapesMesh!.renderOrder = 7;
		this.add(Repository.linksIndicatorsMesh!);
		Repository.linksIndicatorsMesh!.renderOrder = 8;

		Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
			this.add(symbolShapeMesh);
			symbolShapeMesh.renderOrder = 9;
		});

		this.add(Repository.symbolsIconsMesh!);
		Repository.symbolsIconsMesh!.renderOrder = 10;

		// We add the legends to the GUI scene so they are not affected by the grapScene Camera
		CosmosThree.guiScene.add(Repository.symbolsLegendsMesh!);
		Repository.symbolsLegendsMesh!.renderOrder = 12;
		CosmosThree.guiScene.add(Repository.groupsLegendsMesh!);
		Repository.groupsLegendsMesh!.renderOrder = 13;

		// We also forcefully render it so the texture is uploaded to the GPU and there is no 'freeze' when the legends mesh is made visible for the first time depending on camera zoom
		CosmosThree.gpuBusy = true;
		
		const prevVisibilitySymbolsLegends = Repository.symbolsLegendsMesh!.visible;
		const prevVisibilityGroupsLegends = Repository.groupsLegendsMesh!.visible;
		Repository.symbolsLegendsMesh!.visible = true;
		Repository.groupsLegendsMesh!.visible = true;
		CosmosThree.renderer.render(CosmosThree.graphScene, CosmosThree.graphCamera);
		CosmosThree.renderer.render(CosmosThree.guiScene, CosmosThree.guiCamera);
		Repository.symbolsLegendsMesh!.visible = prevVisibilitySymbolsLegends;
		Repository.groupsLegendsMesh!.visible = prevVisibilityGroupsLegends;

		/* Logger.time('[perf] initializing texture 1');
		CosmosThree.renderer.initTexture((Repository.symbolsLegendsMesh!.material as any).map);
		Logger.timeEnd('[perf] initializing texture 1');

		Logger.time('[perf] initializing texture 2');
		CosmosThree.renderer.initTexture((Repository.groupsLegendsMesh!.material as any).map);
		Logger.timeEnd('[perf] initializing texture 2'); */

		// Object that shows the full legend
		CosmosThree.guiScene.add(this.fullLegend);
		this.fullLegend.renderOrder = 14;

		// Repository.symbolsLegendsMesh?.textureGenerator.downloadTexture();
		// Repository.groupsLegendsMesh?.textureGenerator.downloadTexture();
	}

	private initSymbols(serializedSymbols: SerializedSymbol[]){
		this.symbols = Repository.buildSymbols(serializedSymbols);

		for(let i = 0; i < this.symbols.length; i++){
			const symbol = this.symbols[i];

			symbol.hovered.add(this.onSymbolHovered, this);
			symbol.unhovered.add(this.onSymbolUnhovered, this);

			symbol.clicked.add(this.onSymbolClicked, this);
			symbol.doubleclicked.add(this.onSymbolDoubleClicked, this);

			symbol.selected.add(this.onSymbolSelected, this);
			symbol.unselected.add(this.onSymbolUnselected, this);

			symbol.hide();
		}
	}

	private initLinks(serializedLinks: SerializedLink[]){
		this.links = Repository.buildLinks(serializedLinks);

		for(let i = 0; i < this.links.length; i++){
			const link = this.links[i];

			link.clicked.add(this.onLinkClicked, this);

			link.selected.add(this.onLinkSelected, this);
			link.unselected.add(this.onLinkUnselected, this);

			this.links[i].hide();
		}
	}

	private initGroups(serializedSymbols: SerializedSymbol[]){
		this.groups = Repository.buildGroups(serializedSymbols);

		assignSymbolsToGroups(this.symbols);

		for(let i = 0; i < this.groups.length; i++){
			this.groups[i].hide();
		}
	}

	private initSharedSymbols(serializedSharedSymbols: SerializedSymbol[]){
		this.sharedSymbols = Repository.buildSharedSymbols(serializedSharedSymbols);
	}

	private initSharedLinks(serializedSharedLinks: SerializedLink[]){
		this.sharedLinks = Repository.buildSharedLinks(serializedSharedLinks);
	}

	async layout(cacheOnly = false){
        if (!this.loaded) {
            return;
        }
        if (!cacheOnly) {
			// Dispatching this event will show the layout start toast notification
			this.layoutStarted.dispatch();

			// In case something is selected we unselect it
			this.unselectAll();

			// In case this is not the first layout after a load we hide everything
			if(!this.layoutAfterLoad){
				await this.hideAll();
			}else{
				this.layoutAfterLoad = false;
			}
		}
		// If a background layout is being computed we wait for it to finish before doing a new one
		if (this._baseLayoutBackgroundPromise) {
			await this._baseLayoutBackgroundPromise;
			this._baseLayoutBackgroundPromise = null;
		}

		// TODO: for some reason if the arrays are not sorted the resulting graph is different... is this how yFiles is supposed to work?
		const symbols: Symbol[] = _.sortBy(this._filterResult.symbols.length && !cacheOnly ? this._filterResult.symbols : this.symbols, ['id']);

        const links: Link[] = _.sortBy(this._filterResult.links.length && !cacheOnly ? this._filterResult.links : this.links, ['id']);

		// Do the layouting and wait for the result
		await this._layoutManager.layout(symbols, this.groups, links, cacheOnly);

		if (!cacheOnly) {
			// Dispatching this event will show the layout end toast notification
			this.layoutEnded.dispatch();

			// If it's the first layout done after a load with filters applied then we calculate the 'base' layout (with nothing filtered) on background (web worker)
			if (this._activeFilterGroups.length) {
				this._baseLayoutBackgroundPromise = this.layout(true);
			}
		}
	}

	private updateSize(){
		this.updateBBox();
		this.center();
		this.updateWorldSize();
		this.updateCameraBounds();
	}

	/** 
	 * Updates the bounding box of the whole mesh.
	 */
	private updateBBox(){
		Logger.time('[perf] mesh: calculate bounding box');

		// We need to sync the matrices instantly at least once before updating the mesh bbox, otherwise it would happen in the next frame and the bbox would be wrong
		this.cosmos.syncInstances();

		// Recompute the bounding boxes of all the children
		this.children.forEach(elem => {
			if(elem instanceof BaseInstancedMesh || elem instanceof BaseBatchedMesh){
				(elem as any).computeBoundingBox();
			}
		});

		this.bbox.setFromObject(this);
		
		Logger.timeEnd('[perf] mesh: calculate bounding box');
		if(CosmosThree.debug){
			this.cosmos.meshBBoxHelper.box = this.bbox;
			this.cosmos.meshSphereHelper.geometry.dispose();
			this.cosmos.meshSphereHelper.geometry = new THREE.SphereGeometry( CameraControls.createBoundingSphere( this ).radius, 16, 16);
		}
	}

	private center(){
		const vCenter = new THREE.Vector3();
		this.bbox.getSize(vCenter);

		vCenter.x = vCenter.x / 2;
		vCenter.z = vCenter.z / 2;

		this.position.set( -this.bbox.min.x - vCenter.x, 0, -this.bbox.min.z - vCenter.z);
		CosmosThree.meshOffset = this.position.clone();

		this.bbox.setFromObject(this);
	}

	private updateWorldSize(){
		this.cosmos.updateWorldSize(this.bbox);
	}

	private updateCameraBounds(){
		// Create the bounding sphere
		const sphere = CameraControls.createBoundingSphere(this);

		// Extract the center and radius
		const center = sphere.center;
		const radius = sphere.radius;

		// Calculate the side length of the cube that fully contains the sphere
		const diameter = radius * 2;
		const sideLength = diameter;  // Side length of the cube that contains the sphere

		// Calculate the half side length
		const halfSideLength = sideLength / 2;

		// Calculate the minimum and maximum coordinates of the bounding box
		// Ensure that the sphere is contained along one diagonal of the cube
		const min = new THREE.Vector3(center.x - halfSideLength, center.y - halfSideLength, center.z - halfSideLength);
		const max = new THREE.Vector3(center.x + halfSideLength, center.y + halfSideLength, center.z + halfSideLength);

		const box = new THREE.Box3(min, max);

		// Assign the bounding box to the camera bounds helper and set the camera controls boundary
		if(CosmosThree.debug){ this.cosmos.cameraBoundsHelper.box = box; }
		// CosmosThree.camControls.setBoundary(box);
	}

	fitToElems(elemsOrMesh: Symbol[], animate = true, dataOnly = false): {center: THREE.Vector3, zoom: number}{
		// Logger.time('[perf] mesh: fitToElems');
		const box = new THREE.Box3().makeEmpty();

		if(elemsOrMesh.length === 1){
			// Single object case

			// Create de box from the position of the symbol and the symbol size
			box.setFromCenterAndSize(elemsOrMesh[0].three.position, new THREE.Vector3(SYMBOL_PAD_SIZE, 0, SYMBOL_PAD_SIZE));
		}else{
			// Multiple object case

			// Iterate over all objects to expand the bounding box based on their positions
			for (let i = 0; i < elemsOrMesh.length; i++) {
				box.expandByPoint( elemsOrMesh[i].three.position );
			}

			// We expand the resulting box to take into account the symbol size (we take the make scenarios symbols size as they are the biggest)
			box.expandByVector(new THREE.Vector3(SYMBOL_PAD_SIZE / 2, 0 , SYMBOL_PAD_SIZE / 2 ));
		}

		const center = new THREE.Vector3();
		box.getCenter(center);
		
		const centerOffset = new THREE.Vector3(this.position.x, 0, this.position.z);

		box.min.add(centerOffset);
		box.max.add(centerOffset);

		box.getCenter(center);

		// Update the dynamic sphere helper
		if(CosmosThree.debug){ this.cosmos.dynamicBoundsHelper.box = box; }

		// Get the corners of the Box3
		const corners = [
			new THREE.Vector3(box.min.x, box.max.y, box.min.z),
			new THREE.Vector3(box.max.x, box.max.y, box.min.z),
			new THREE.Vector3(box.min.x, box.max.y, box.max.z),
			new THREE.Vector3(box.max.x, box.max.y, box.max.z)
		];

		const screenCoords = [];

		for (let i = 0; i < corners.length; i++) {
			// Clone the corner vector
			const vector = corners[i].clone();

			// Project the vector to screen coordinates
			const widthHalf = 0.5 * (CosmosThree.graphCamera.right - CosmosThree.graphCamera.left);
			const heightHalf = 0.5 * (CosmosThree.graphCamera.top - CosmosThree.graphCamera.bottom);

			vector.project(CosmosThree.graphCamera);

			const screenX = (vector.x * widthHalf) + widthHalf;
			const screenY = -(vector.y * heightHalf) + heightHalf;

			// Store the screen coordinates
			screenCoords.push({ x: screenX, y: screenY });
		}

		// Calculate the projected bounding rectangle
		let minX = Infinity, minY = Infinity;
		let maxX = -Infinity, maxY = -Infinity;

		for (let i = 0; i < screenCoords.length; i++) {
			const coord = screenCoords[i];
			if (coord.x < minX) minX = coord.x;
			if (coord.y < minY) minY = coord.y;
			if (coord.x > maxX) maxX = coord.x;
			if (coord.y > maxY) maxY = coord.y;
		}

		// Calculate the dimensions of the projected bounding rectangle
		const boundingWidth = maxX - minX;
		const boundingHeight = maxY - minY;

		// If an item is selected then the right side panel is open, so we have to take its width into account for the available visible area
		let frustumWidth = 0;
		if(this._selected){
			frustumWidth = (CosmosThree.canvasWidth - 2 * document.getElementById('rightPanel')!.getBoundingClientRect().width) / SCALE_FACTOR;
		}else{
			frustumWidth = (CosmosThree.graphCamera.right - CosmosThree.graphCamera.left);
		}

		const frustumHeight = CosmosThree.graphCamera.top - CosmosThree.graphCamera.bottom;

		// Calculate the zoom factor needed to fit the bounding rectangle in the viewport
		const zoomWidth = (frustumWidth - (frustumWidth * CAMERA_FIT_WIDTH_MARGIN)) / boundingWidth;
		const zoomHeight = (frustumHeight - (frustumWidth * CAMERA_FIT_HEIGHT_MARGIN)) / boundingHeight;

		// Set the camera zoom to the smaller of the two zoom factors, but invert the calculation since higher zoom means smaller view
		const newZoom = Math.min(CosmosThree.graphCamera.zoom * Math.min(zoomWidth, zoomHeight), CosmosThree.camControls.maxZoom);

		if(!dataOnly){
			this.zoomTo(newZoom, center.x, center.y, center.z, animate);
		}
		
		return {center, zoom: newZoom};
		// Logger.timeEnd('[perf] mesh: fitToElems');
	}

	// Interpolation function for position
	private interpolate(start: number, end: number, k: number): number {
		return start + (end - start) * k;
	}

	// Interpolation function for zoom
	private interpolateZoom(startZoom: number, endZoom: number, k: number): number {
		return 1 / this.interpolate(1 / startZoom, 1 / endZoom, k);
	}

	private zoomTo(endZoom: number, endPosX: number, endPosY: number, endPosZ: number, animate = true){
		const initialZoom = CosmosThree.graphCamera.zoom;

		const targetPosition = new THREE.Vector3();
		CosmosThree.camControls.getTarget(targetPosition);

		const initialPositionX = targetPosition.x;
		const initialPositionY = targetPosition.y;
		const initialPositionZ = targetPosition.z;

		this.camInterpolation = 0;

		gsap.to(this, 
			{
				camInterpolation: 1,
				duration: animate ? INTERACTION_ANIMATION_SPEED_MEDIUM : 0,
				ease: "power2.out",
				onUpdate: () => {
					const computedPosX = this.interpolate(initialPositionX, endPosX, this.camInterpolation);
					const computedPosY = this.interpolate(initialPositionY, endPosY, this.camInterpolation);
					const computedPosZ = this.interpolate(initialPositionZ, endPosZ, this.camInterpolation);

					CosmosThree.camControls.moveTo(computedPosX, computedPosY, computedPosZ, false );
					
					const computedZoom = this.interpolateZoom(initialZoom, endZoom, this.camInterpolation);

					CosmosThree.camControls.zoomTo(computedZoom, false);
				}
			}
		);
	}

	private saveCamState(){
		this.camState.zoom = CosmosThree.graphCamera.zoom;

		const targetPosition = new THREE.Vector3();
		CosmosThree.camControls.getTarget(targetPosition);

		this.camState.x = targetPosition.x;
		this.camState.y = targetPosition.y;
		this.camState.z = targetPosition.z;
	}

	private resetCamState(animate = true){
		this.zoomTo(this.camState.zoom, this.camState.x, this.camState.y, this.camState.z, animate);
	}

	private showAll() {
		const filteredGroups = this.groups.filter(group => !group.filtered);
	
		const maxStaggerTime = MAX_GROUPS_TRANSITION_STAGGER / filteredGroups.length;
	
		for (let l = 0; l < filteredGroups.length; l++) {
			gsap.delayedCall(l * maxStaggerTime, () => {
				const group = filteredGroups[l];
				group.visible = true;
	
				for (let j = 0; j < group.symbols.length; j++) {
					const symbol = group.symbols[j];
					if (!symbol.filtered) {
						symbol.visible = true;
						for (let i = 0; i < symbol.links.length; i++) {
							(symbol.links[i] as Link).visible = true;
						}
					}
				}
			});
		}
	
		gsap.delayedCall(MAX_GROUPS_TRANSITION_STAGGER + INTERACTION_ANIMATION_SPEED_SLOW, () => {
			// Restore user interaction on the graph and UI.
			this.enableInteraction();
			useAppStore.getState().enableUIInteractions();
		});
	}
	
	async hideAll(): Promise<void> {
		// In case something is selected we unselect it
		this.unselectAll();
		this.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		return new Promise<void>((resolve) => {
			for(let i = 0; i < this.symbols.length; i++){
				this.symbols[i].visible = false;
			}
	
			for(let i = 0; i < this.links.length; i++){
				this.links[i].visible = false;
			}

			for(let i = 0; i < this.groups.length; i++){
				this.groups[i].visible = false;
			}
	
			gsap.delayedCall(INTERACTION_ANIMATION_SPEED_SLOW, () => {
				// The objects are visually hidden by now, opacities to 0, etc.
				// But now we are going to actually 'hide' them from a geometry perspective, so the bounds of their parent obejcts are right.
				// This is very important to fit the camera correctly and to set the 'world' size to the necessary dimensions.

				Logger.time("[perf] reset all elements");

				for(let i = 0; i < this.symbols.length; i++){
					this.symbols[i].three.position.x = 0;
					this.symbols[i].three.position.z = 0;
					this.symbols[i].matrixNeedsUpdate = true;
				}

				for(let i = 0; i < this.links.length; i++){
					this.links[i].update();
				}

				for(let i = 0; i < this.groups.length; i++){
					this.groups[i].update();
				}
				
				this.position.set(0, 0, 0);
				this.updateBBox();

				Logger.timeEnd("[perf] reset all elements");

				resolve();
			});
		});
	}

	startIdleMode(){
		if(!this.intoIdleMode){
			this.intoIdleMode = true;

			this.disableInteraction();
			useAppStore.getState().disableUIInteractions();

			// Maybe something was selected, so we unselect it
			this.unselectAll();

			this.idleModeStarted.dispatch();

			// We wait for the fast interaction to happen:
			gsap.delayedCall(INTERACTION_ANIMATION_SPEED_FAST, () => {

				CosmosThree.camControls.saveState();

				// We move the camera to the origin and the initial zoom
				CosmosThree.camControls.zoomTo(this.initialZoom, true);
				CosmosThree.camControls.moveTo(0, 0, 0, true);

				this.initialSymbolsLegendsOpacity = (Repository.symbolsLegendsMesh!.material as THREE.Material).opacity;
				this.initialGroupsLegendsOpacity = (Repository.groupsLegendsMesh!.material as THREE.Material).opacity;

				gsap.to((Repository.symbolsLegendsMesh!.material as THREE.Material), {duration: INTERACTION_ANIMATION_SPEED_FAST, opacity: 0});
				gsap.to((Repository.groupsLegendsMesh!.material as THREE.Material), {duration: INTERACTION_ANIMATION_SPEED_FAST, opacity: 0});

				CosmosThree.camControls.addEventListener( 'rest', Repository.mesh!.idleInAnim);
			});
		}
	}

	private idleInAnim(){
		CosmosThree.camControls.removeEventListener( 'rest', Repository.mesh!.idleInAnim);

		this.idleTl = new gsap.core.Timeline({repeat: -1});
		this.idleTl.add(gsap.to(CosmosThree.camControls, {duration: IDLE_ROATION_SPEED, azimuthAngle: Math.PI / 4 + Math.PI * 2, ease: "sine.inOut"}), 0);
		this.idleTl.add(gsap.fromTo(Repository.mesh!, {camZoomDummy: Repository.mesh!.initialZoom}, {duration: IDLE_ROATION_SPEED / 2, camZoomDummy: 0.8, ease: "sine.inOut", repeat: 1, yoyo: true, onUpdate: () => {
			CosmosThree.camControls.zoomTo(Repository.mesh!.camZoomDummy, false);
		}}), 0);
	}

	stopIdleMode(){
		if(this.intoIdleMode){
			this.intoIdleMode = false;

			this.enableInteraction();
			useAppStore.getState().enableUIInteractions();

			gsap.globalTimeline.clear(true);

			gsap.to((Repository.symbolsLegendsMesh!.material as THREE.Material), {duration: INTERACTION_ANIMATION_SPEED_FAST, opacity: this.initialSymbolsLegendsOpacity});
			gsap.to((Repository.groupsLegendsMesh!.material as THREE.Material), {duration: INTERACTION_ANIMATION_SPEED_FAST, opacity: this.initialGroupsLegendsOpacity});

			CosmosThree.camControls.normalizeRotations();
			CosmosThree.camControls.reset(true);

			this.idleModeStopped.dispatch();
		}
	}

	spotlightOnFilterItem(filterItem: FilterItem | null) {
		if(!this._selected){ // If an item is selected we don't want to trigger the spotlight
			let symbolsToFilter: Symbol[] = [];
			if (filterItem) {
				const activeFilter = [{
					id: filterItem.groupDefinition.id,
					definition: filterItem.groupDefinition,
					label: filterItem.groupDefinition.label,
					items: [filterItem],
					isOpen: filterItem.groupDefinition.open ?? false,
					priority: filterItem.groupDefinition.priority ?? 1
				}];
				const filterResults = FilterManager.filterNodes(
					{symbols: this.symbols, links: []},
					activeFilter,
					true
				);
				if (filterResults.symbols.length) {
					symbolsToFilter = filterResults.symbols;
				}
			}

			this.spotlight(!filterItem ? [] : symbolsToFilter, false, false, 0);
		}
	}

	private onSymbolHovered(symbol: Symbol){
		if(!this._selected){
			this.spotlight([symbol], true, true, 1);
		}

		// positions and shows the full legend
		this._fullLegendDelayedCall?.kill();
		this._fullLegendDelayedCall = gsap.delayedCall(INTERACTION_SPOTLIGHT_DELAY, () => {
			this.fullLegend.setFrom(symbol);
		});
	}

	private onSymbolUnhovered(){
		if(!this._selected){
			this.spotlight([], true, false, 1);
		}

		// hides the full legend
		this._fullLegendDelayedCall?.kill();
		this._fullLegendDelayedCall = gsap.delayedCall(INTERACTION_SPOTLIGHT_DELAY, () => {
			this.fullLegend.setFrom(null);
			if(this._selected instanceof Symbol){
				this.fullLegend.setFrom(this._selected as Symbol);
			}
		});
	}

	private onSymbolClicked(symbol: Symbol){
		if(!this.cosmos.inputManager.keyControlCommandDown){
			if (!this._selected || this._selected !== symbol) {
				symbol.select = true;
				this._multiSelected = [symbol];
			}
		}else{
			if(this._selected && this._selected !== symbol){
				// Check if the element already exists in the array, if it does remove it instead of adding it
				if(this._multiSelected.includes(symbol)){
					this._multiSelected.splice( this._multiSelected.indexOf(symbol), 1);
				}else{
					this._multiSelected.push(symbol);
				}

				this.spotlight(this._multiSelected, false, true, 1, true);
			}
		}
	}

	private onSymbolDoubleClicked(symbol: Symbol){
		this.showUsages = true;
		this.onSymbolSelected(symbol);
	}

	private onSymbolSelected(symbol: Symbol){
		if(this._selected){
			this._selected.select = false;
		}
		this._selected = symbol;
		if(this.cosmos.inputManager.keyShiftDown || this.showUsages){
			this.showUsages = false;

			const duplicates = symbol.showDuplicatesLinks();

			this.spotlight([symbol, ...(duplicates as Symbol[])], false, true, 1, true);
		}else{
			this.spotlight([symbol], false, true, 1, true);
		}

		// positions and shows the selection indicator
		this.selectionIndicator.setFrom(symbol);
		// positions and shows the full legend
		this._fullLegendDelayedCall?.kill();
		this.fullLegend.setFrom(symbol);
		// opens right side panel
		useAppStore.getState().setNodeDetail(symbol);
	}

	private onSymbolUnselected(){
		
		if(this._selected && (this._selected as Symbol).isSymbol) {
			(this._selected as Symbol).hideDuplicatesLinks();
		}

		this._selected = null;
		this.spotlight([], false, true, 1, true);
		// hides the selection indicator
		this.selectionIndicator.hide();
		// hides the full legend
		this._fullLegendDelayedCall?.kill();
		this.fullLegend.setFrom(null);
		// closes right side panel
		useAppStore.getState().setNodeDetail(null);
	}
	
	private onLinkClicked(link: Link){
		if(!this.cosmos.inputManager.keyControlCommandDown){
			link.select = true;
			this._multiSelected = [link];
		}
	}

	private onLinkSelected(link: Link){
		if(this._selected){
			this._selected.select = false;
		}
		this._selected = link;
		
		this.spotlight([link], false, true, 0, true);

		// opens right side panel
		useAppStore.getState().setNodeDetail(link);
	}

	private onLinkUnselected(){
		this._selected = null;
		this.spotlight([], false, true, 1, true);

		// closes right side panel
		useAppStore.getState().setNodeDetail(null);
	}

	unselectAll(){
		if(this._selected){
			this._selected.select = false;
			this._selected = null;
			this._multiSelected = [];
		}
	}

	spotlight(elems: (Symbol | Link) [], delayed = true, staggered = true, level = 1, zoomTo = false){
		// If there are delayed calls from previous spotlight, cancel them
		this._spotlightDelayedCalls.forEach((call: gsap.core.Tween) => call.kill());
		this._spotlightDelayedCalls = [];

		const delayedCall = gsap.delayedCall(delayed ? INTERACTION_SPOTLIGHT_DELAY : 0, () => {
			if(elems.length){
				// We get the elements to highlight
				let elemsToSpotlight: {level: number, symbols: Symbol[], links: Link[]}[] = [];
				const symbolsToSpotlight: Symbol[] = [];
				const linksToSpotlight: Link[] = [];

				if(elems.length === 1 && elems[0] instanceof Link){
					symbolsToSpotlight.push((elems[0].source as Symbol)!);
					symbolsToSpotlight.push((elems[0].target as Symbol)!);
					linksToSpotlight.push(elems[0]);
					elemsToSpotlight.push({level: 0, symbols: [], links: [elems[0]]});
				}else{
					elemsToSpotlight = getElementsToSpotlight(elems as Symbol[], level);
					// Now we get the elements to un-highlight
					elemsToSpotlight.forEach(({ symbols, links }) => {
						symbols.forEach(symbol => symbolsToSpotlight.push(symbol));
						links.forEach(link => linksToSpotlight.push(link));
					});
				}

				const elemsToUnSpotlight = getUnmatchedSymbolsAndLinks(symbolsToSpotlight, linksToSpotlight);

				// zoom to the elements
				if(zoomTo){
					if(CosmosThree.userControlledCamera) this.saveCamState();
					CosmosThree.userControlledCamera = false;
					this.pendingCameraReset = true && !this.preventCameraReset;
					this.preventCameraReset = false;
					this.fitToElems(symbolsToSpotlight);
				}

				// first mute and un-spotlight links
				for(let i = 0 ; i < elemsToUnSpotlight.unmatchedLinks.length; i++){
					elemsToUnSpotlight.unmatchedLinks[i].spotlight = false;
					elemsToUnSpotlight.unmatchedLinks[i].muted = true;
				}

				// then mute and un-spotlight all symbols
				for(let i = 0 ; i < elemsToUnSpotlight.unmatchedSymbols.length; i++){
					elemsToUnSpotlight.unmatchedSymbols[i].spotlight = false;
					elemsToUnSpotlight.unmatchedSymbols[i].muted = true;
				}

				// And finally check the elements to spotlight to unmute and highlight them
				for(let j = 0 ; j < elemsToSpotlight.length; j++){
					const delay = j * INTERACTION_SPOTLIGHT_STAGGERING; // Adjust delay multiplier as needed
					const delayedCall = gsap.delayedCall(staggered ? delay : 0, () => {
						for(let i = 0 ; i < elemsToSpotlight[j].symbols.length; i++) {
							elemsToSpotlight[j].symbols[i].spotlightLevel = ((elemsToSpotlight.length -1) === 0) ? 0 : j / (elemsToSpotlight.length -1);
							elemsToSpotlight[j].symbols[i].spotlight = true;
						}
		
						for(let i = 0 ; i < elemsToSpotlight[j].links.length; i++) {
							elemsToSpotlight[j].links[i].spotlight = true;
						}

						// We want to change the color of the groups side if all symbols in it are muted, so we traverse them
						for(let i = 0 ; i < this.groups.length; i++) {
							const group = this.groups[i];
							let muteGroup = true;
							for(let n = 0 ; n < group.symbols.length; n++) {
								if(!group.symbols[n].muted){
									muteGroup = false;
									break;
								}
							}
							group.muted = muteGroup;
						}
					});
					this._spotlightDelayedCalls.push(delayedCall);
				}
			}else{
				// first unmute all links
				for(let i = 0 ; i < this.links.length; i++){
					this.links[i].spotlight = false;
				}

				// then unmute all symbols
				for(let i = 0 ; i < this.symbols.length; i++){
					this.symbols[i].spotlight = false;
				}

				// then unmute all groups
				for(let i = 0 ; i < this.groups.length; i++){
					this.groups[i].muted = false;
				}

				// restore camera
				if(zoomTo && this.pendingCameraReset && !CosmosThree.userControlledCamera){
					this.resetCamState();
				}
			}
		});
		this._spotlightDelayedCalls.push(delayedCall);
	}

	async filterNodes(activeFilterGroups?: ActiveFilters) {
		this.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		let forceLayout = false;

		if(this._selected){
			this.unselectAll();
		}

		if(activeFilterGroups){
			this._activeFilterGroups = activeFilterGroups;
		}else{
			forceLayout = true;
		}

		const symbols = this.symbols;
		const links = this.links;

		this._filterResult = FilterManager.filterNodes({symbols, links}, this._activeFilterGroups);

		const newFilterList = FilterManager.getUpdatedFilterList(
			this._filterResult.symbols.length ? this._filterResult.symbols : [],
			symbols,
			useAppStore.getState().filterList || [],
			this._activeFilterGroups
		);
		useAppStore.getState().setFilterList(newFilterList);

		// The order of these two lines is very important, do not change
		this._filterResult.links = this._filterResult.symbols.length ? this._filterResult.links : this.links;
		this._filterResult.symbols = this._filterResult.symbols.length ? this._filterResult.symbols : this.symbols;

		// Apply the filter state to all objects
		// Elements to filter
		for(let i = 0; i < this.groups.length; i++){
			this.groups[i].filtered = true;
		}

		for(let i = 0; i < this.symbols.length; i++){
			this.symbols[i].filtered = true;
		}

		for(let i = 0; i < this.links.length; i++){
			this.links[i].filtered = true;
		}

		// Elements to un-filter
		for(let i = 0; i < this._filterResult.symbols.length; i++){
			this._filterResult.symbols[i].filtered = false;
		}

		for(let i = 0; i < this._filterResult.links.length; i++){
			this._filterResult.links[i].filtered = false;
		}

		for(let i = 0; i < this._filterResult.symbols.length; i++){
			Repository.groups.get(this._filterResult.symbols[i].groupId)!.filtered = false;
		}

		let shouldAnimateCam = true;

		// first layout for the mesh or the mesh should re-layout
		if(
			forceLayout
			|| this.layoutAfterLoad
			|| (this._layoutManager.shouldReLayout( this._filterResult.symbols )
				|| (
					this._baseLayoutBackgroundPromise
					&& !this._activeFilterGroups.length
				)
			)
		) {
			shouldAnimateCam = false;
			await this.layout();
			// Now that all elements are in their right place we compute the size of the mesh
			this.updateSize();
		}

		// Fits the camera to the filtered symbols
		CosmosThree.camControls.minZoom = 0.02;

		this.initialZoom = this.fitToElems(this._filterResult.symbols, shouldAnimateCam).zoom;

		// The zoom should never be less than the value calculated by fitToElems so we set it like that, otherwise the user may 'get lost'.
		gsap.delayedCall(shouldAnimateCam? INTERACTION_ANIMATION_SPEED_MEDIUM : 0, ()=>{
			CosmosThree.camControls.minZoom = Math.min(this.initialZoom, 1) ;

			if(shouldAnimateCam){
				this.enableInteraction();
				useAppStore.getState().enableUIInteractions();
			}
		});

		if(!shouldAnimateCam){
			gsap.delayedCall(PAUSE_TO_FREE_RESOURCES, () => {
				// Shows all elements if they are hidden
				this.showAll();
			});
		}
	}

	search(query: string){
		if (!this._searchManager) {
			Logger.warn('Mesh Search Manager is not initialized');
			return [];
		}
		return this._searchManager.searchFuse(query, this._filterResult);
	}

	disableInteraction(){
		this._interactionDelayedCall?.kill();
		this.cosmos.inputManager.enabled = false;
		CosmosThree.camControls.enabled = false;
	}

	enableInteraction(){
		this.cosmos.inputManager.enabled = true;
		CosmosThree.camControls.enabled = true;
	}

	setCamera(mode: "3d" | "2d", animated = true){
		if(mode === this.cameraMode){ return }

		this.cameraMode = mode;
		this.pendingCameraReset = false;
		this.cosmos.setCameraMode(mode, animated);
	}

	setTheme(activeTheme: "cosmosLight" | "cosmosDark", animated = true){
		this.activeTheme = activeTheme;

		if(activeTheme === "cosmosLight"){
			gsap.to(CosmosThree.groupsTopColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUPS_TOP_COLOR_LIGHT.r, g: GROUPS_TOP_COLOR_LIGHT.g, b: GROUPS_TOP_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
                if(Repository.groupsShapesTopMesh) (Repository.groupsShapesTopMesh.material as any).color = CosmosThree.groupsTopColor;
            }});

			gsap.to(CosmosThree.groupsSideColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUPS_SIDE_COLOR_LIGHT.r, g: GROUPS_SIDE_COLOR_LIGHT.g, b: GROUPS_SIDE_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
                if(Repository.groupsShapesSideMesh) (Repository.groupsShapesSideMesh.material as any).color = CosmosThree.groupsSideColor;
            }});

			gsap.to(CosmosThree.groupsSideMutedTint, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUPS_SIDE_TINT_COLOR_LIGHT.r, g: GROUPS_SIDE_TINT_COLOR_LIGHT.g, b: GROUPS_SIDE_TINT_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
				if(Repository.groupsShapesSideMesh) (Repository.groupsShapesSideMesh.material as any).tintColor = CosmosThree.groupsSideMutedTint;
            }});

			gsap.to(CosmosThree.symbolsPadColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: SYMBOLS_PAD_COLOR_LIGHT.r, g: SYMBOLS_PAD_COLOR_LIGHT.g, b: SYMBOLS_PAD_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
                if(Repository.symbolsPadsMesh) (Repository.symbolsPadsMesh.material as any).color = CosmosThree.symbolsPadColor;
            }});

			gsap.to(CosmosThree.symbolsMutedTint, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: SYMBOLS_MUTED_TINT_LIGHT.r, g: SYMBOLS_MUTED_TINT_LIGHT.g, b: SYMBOLS_MUTED_TINT_LIGHT.b, ease: "none", onUpdate: () => {
                Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) => {
                    (symbolShapeMesh.material as any).tintColor = CosmosThree.symbolsMutedTint;
                });
            }});

			gsap.to(CosmosThree.linksPortColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_PORT_COLOR_LIGHT.r, g: LINK_PORT_COLOR_LIGHT.g, b: LINK_PORT_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
				if(Repository.linksPortsMesh) (Repository.linksPortsMesh.material as any).color = CosmosThree.linksPortColor;
            }});

			gsap.to(CosmosThree.linksDefaultColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_DEFAULT_COLOR_LIGHT.r, g: LINK_DEFAULT_COLOR_LIGHT.g, b: LINK_DEFAULT_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
				for(let i = 0 ; i < this.links.length; i++){
					if(!this.links[i].isTrigger) this.links[i].color = CosmosThree.linksDefaultColor;
				}
            }});

			gsap.to(CosmosThree.linksTriggerColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_TRIGGER_COLOR_LIGHT.r, g: LINK_TRIGGER_COLOR_LIGHT.g, b: LINK_TRIGGER_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
				for(let i = 0 ; i < this.links.length; i++){
					if(this.links[i].isTrigger) this.links[i].color = CosmosThree.linksTriggerColor;
				}
            }});

			gsap.to(CosmosThree.linksMutedTint, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINKS_MUTED_TINT_LIGHT.r, g: LINKS_MUTED_TINT_LIGHT.g, b: LINKS_MUTED_TINT_LIGHT.b, ease: "none", onUpdate: () => {
                if(Repository.linksShapesMesh) (Repository.linksShapesMesh.material as any).tintColor = CosmosThree.linksMutedTint;
            }});

			gsap.to(CosmosThree.linksIndicatorColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_INDICATOR_COLOR_LIGHT.r, g: LINK_INDICATOR_COLOR_LIGHT.g, b: LINK_INDICATOR_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
				if(Repository.linksIndicatorsMesh) (Repository.linksIndicatorsMesh.material as any).color = CosmosThree.linksIndicatorColor;
            }});

			gsap.to(CosmosThree, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, legendBlendFactor: 0, ease: "none", onUpdate: () => {
                if(Repository.symbolsLegendsMesh) (Repository.symbolsLegendsMesh.material as any).blendFactor = CosmosThree.legendBlendFactor;
				if(Repository.groupsLegendsMesh) (Repository.groupsLegendsMesh.material as any).blendFactor = CosmosThree.legendBlendFactor;
				(this.fullLegend.material as any).blendFactor = CosmosThree.legendBlendFactor;
			}});

			gsap.to(CosmosThree.selectionIndicatorColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: SELECCTION_INDICATOR_COLOR_LIGHT.r, g: SELECCTION_INDICATOR_COLOR_LIGHT.g, b: SELECCTION_INDICATOR_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
                (this.selectionIndicator.material as any).color = CosmosThree.selectionIndicatorColor;
            }});

            gsap.to(CosmosThree.fogColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUND_COLOR_LIGHT.r, g: GROUND_COLOR_LIGHT.g, b: GROUND_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
                CosmosThree.fogColor.convertLinearToSRGB();
                if(Repository.groupsShapesSideMesh) (Repository.groupsShapesSideMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.groupsShapesTopMesh) (Repository.groupsShapesTopMesh.material as any).fogColor = CosmosThree.fogColor;
				if(Repository.linksPortsMesh) (Repository.linksPortsMesh.material as any).fogColor = CosmosThree.fogColor;
				if(Repository.linksShapesMesh) (Repository.linksShapesMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.symbolsIconsMesh) (Repository.symbolsIconsMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.symbolsPadsMesh) (Repository.symbolsPadsMesh.material as any).fogColor = CosmosThree.fogColor;
                Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) => {
                    (symbolShapeMesh.material as any).fogColor = CosmosThree.fogColor;
                });
            }});
		}else if(activeTheme === "cosmosDark"){
			gsap.to(CosmosThree.groupsTopColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUPS_TOP_COLOR_DARK.r, g: GROUPS_TOP_COLOR_DARK.g, b: GROUPS_TOP_COLOR_DARK.b, ease: "none", onUpdate: () => {
                if(Repository.groupsShapesTopMesh) (Repository.groupsShapesTopMesh.material as any).color = CosmosThree.groupsTopColor;
            }});

			gsap.to(CosmosThree.groupsSideColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUPS_SIDE_COLOR_DARK.r, g: GROUPS_SIDE_COLOR_DARK.g, b: GROUPS_SIDE_COLOR_DARK.b, ease: "none", onUpdate: () => {
                if(Repository.groupsShapesSideMesh) (Repository.groupsShapesSideMesh.material as any).color = CosmosThree.groupsSideColor;
            }});

			gsap.to(CosmosThree.groupsSideMutedTint, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUPS_SIDE_TINT_COLOR_DARK.r, g: GROUPS_SIDE_TINT_COLOR_DARK.g, b: GROUPS_SIDE_TINT_COLOR_DARK.b, ease: "none", onUpdate: () => {
				if(Repository.groupsShapesSideMesh) (Repository.groupsShapesSideMesh.material as any).tintColor = CosmosThree.groupsSideMutedTint;
            }});

			gsap.to(CosmosThree.symbolsPadColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: SYMBOLS_PAD_COLOR_DARK.r, g: SYMBOLS_PAD_COLOR_DARK.g, b: SYMBOLS_PAD_COLOR_DARK.b, ease: "none", onUpdate: () => {
                if(Repository.symbolsPadsMesh) (Repository.symbolsPadsMesh.material as any).color = CosmosThree.symbolsPadColor;
            }});

			gsap.to(CosmosThree.symbolsMutedTint, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: SYMBOLS_MUTED_TINT_DARK.r, g: SYMBOLS_MUTED_TINT_DARK.g, b: SYMBOLS_MUTED_TINT_DARK.b, ease: "none", onUpdate: () => {
                Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) => {
                    (symbolShapeMesh.material as any).tintColor = CosmosThree.symbolsMutedTint;
                });
            }});

			gsap.to(CosmosThree.linksPortColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_PORT_COLOR_DARK.r, g: LINK_PORT_COLOR_DARK.g, b: LINK_PORT_COLOR_DARK.b, ease: "none", onUpdate: () => {
				if(Repository.linksPortsMesh) (Repository.linksPortsMesh.material as any).color = CosmosThree.linksPortColor;
            }});

			gsap.to(CosmosThree.linksDefaultColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_DEFAULT_COLOR_DARK.r, g: LINK_DEFAULT_COLOR_DARK.g, b: LINK_DEFAULT_COLOR_DARK.b, ease: "none", onUpdate: () => {
				for(let i = 0 ; i < this.links.length; i++){
					if(!this.links[i].isTrigger) this.links[i].color = CosmosThree.linksDefaultColor;
				}
            }});

			gsap.to(CosmosThree.linksTriggerColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_TRIGGER_COLOR_DARK.r, g: LINK_TRIGGER_COLOR_DARK.g, b: LINK_TRIGGER_COLOR_DARK.b, ease: "none", onUpdate: () => {
				for(let i = 0 ; i < this.links.length; i++){
					if(this.links[i].isTrigger) this.links[i].color = CosmosThree.linksTriggerColor;
				}
            }});

			gsap.to(CosmosThree.linksMutedTint, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINKS_MUTED_TINT_DARK.r, g: LINKS_MUTED_TINT_DARK.g, b: LINKS_MUTED_TINT_DARK.b, ease: "none", onUpdate: () => {
                if(Repository.linksShapesMesh) (Repository.linksShapesMesh.material as any).tintColor = CosmosThree.linksMutedTint;
            }});

			gsap.to(CosmosThree.linksIndicatorColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: LINK_INDICATOR_COLOR_DARK.r, g: LINK_INDICATOR_COLOR_DARK.g, b: LINK_INDICATOR_COLOR_DARK.b, ease: "none", onUpdate: () => {
				if(Repository.linksIndicatorsMesh) (Repository.linksIndicatorsMesh.material as any).color = CosmosThree.linksIndicatorColor;
            }});

			gsap.to(CosmosThree, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, legendBlendFactor: 1, ease: "none", onUpdate: () => {
                if(Repository.symbolsLegendsMesh) (Repository.symbolsLegendsMesh.material as any).blendFactor = CosmosThree.legendBlendFactor;
				if(Repository.groupsLegendsMesh) (Repository.groupsLegendsMesh.material as any).blendFactor = CosmosThree.legendBlendFactor;
				(this.fullLegend.material as any).blendFactor = CosmosThree.legendBlendFactor;
			}});

			gsap.to(CosmosThree.selectionIndicatorColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: SELECCTION_INDICATOR_COLOR_DARK.r, g: SELECCTION_INDICATOR_COLOR_DARK.g, b: SELECCTION_INDICATOR_COLOR_DARK.b, ease: "none", onUpdate: () => {
                (this.selectionIndicator.material as any).color = CosmosThree.selectionIndicatorColor;
            }});

            gsap.to(CosmosThree.fogColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUND_COLOR_DARK.r, g: GROUND_COLOR_DARK.g, b: GROUND_COLOR_DARK.b, ease: "none", onUpdate: () => {
                CosmosThree.fogColor.convertLinearToSRGB();
                if(Repository.groupsShapesSideMesh) (Repository.groupsShapesSideMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.groupsShapesTopMesh) (Repository.groupsShapesTopMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.linksPortsMesh) (Repository.linksPortsMesh.material as any).fogColor = CosmosThree.fogColor;
				if(Repository.linksShapesMesh) (Repository.linksShapesMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.symbolsIconsMesh) (Repository.symbolsIconsMesh.material as any).fogColor = CosmosThree.fogColor;
                if(Repository.symbolsPadsMesh) (Repository.symbolsPadsMesh.material as any).fogColor = CosmosThree.fogColor;
                Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) => {
                    (symbolShapeMesh.material as any).fogColor = CosmosThree.fogColor;
                });
            }});
		}

		this.cosmos.setTheme(activeTheme, animated);
	}

	override clear(): this{
		return this.dispose();
	}

	dispose(){
		if (!this.loaded) {
			return this;
		}

		this._layoutManager.destroy();

		this.cosmos.inputManager.hovered = undefined;

		// Send all gsap animations to Valhalla
        gsap.globalTimeline.clear(true);

		super.clear();

		CosmosThree.graphScene.remove(this);
		CosmosThree.guiScene.clear();

		this.symbols.forEach((symbol) =>{
			symbol.dispose();
		});

		this.symbols = [];

		this.links.forEach((link) =>{
			link.dispose();
		});

		this.links = [];

		this.groups.forEach((group) =>{
			group.dispose();
		});

		this.groups = [];

		this.selectionIndicator.dispose();

		this.fullLegend.dispose();

		Repository.clear();

		this.sharedSymbols = [];
		this.sharedLinks = [];

		this._activeFilterGroups = [];

		BaseObject.idCount = 0;

		return this;
	}
}