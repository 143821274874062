import type {SpotlightFromUrl, FiltersFromUrl} from '@/store/Filter.ts';
import {FEATURES} from '../store/Features.ts';

export type FeaturesFromUrl = {
  [key in FEATURES]?: string
}

export const prepareQuery = (filtersFromUrl: FiltersFromUrl, spotlightFromUrl: SpotlightFromUrl | null): string => {
  const query = [];
  const filterQuery: string[] = [];

  for (const [groupId, itemIds] of Object.entries(filtersFromUrl)) {
    filterQuery.push(`${groupId}:(${itemIds.join(' OR ')})`);
  }

  if (filterQuery.length) {
    query.push(`filters=${encodeURIComponent(filterQuery.join(' '))}`);
  }
  if (spotlightFromUrl) {
    query.push(`spotlightType=${encodeURIComponent(spotlightFromUrl.symbolType)}`);
    query.push(`spotlightExternalId=${encodeURIComponent(spotlightFromUrl.symbolExternalId)}`);
  }

  return query.join('&');
}

export const decodeFiltersFromQuery = (searchParams: URLSearchParams): Record<string, string[]> => {
  const decodedFilters: Record<string, string[]> = {};
  for (const [key, value] of searchParams.entries()) {
    switch (key) {
      case 'filters': {
        const filterGroups = value.split(')');
        filterGroups.forEach((group) => {
          if (!group.length) {
            return;
          }
          const [id, items] = group.split(':(');
          const groupId = id.trim();
          if (!decodedFilters[groupId]) {
            decodedFilters[groupId] = [];
          }
          const filterItems = items?.length ? items.split(' OR ') : [];
          filterItems.forEach((item) => {
            decodedFilters[groupId].push(item);
          })
        })
        break;
      }
    }
  }
  return decodedFilters;
}

export const decodeSpotlightFromQuery = (searchParams: URLSearchParams): SpotlightFromUrl | null => {
  const spotlightType = searchParams.get('spotlightType');
  const spotlightExternalId = searchParams.get('spotlightExternalId');

  return spotlightType && spotlightExternalId ? {
    symbolType: spotlightType,
    symbolExternalId: spotlightExternalId,
  } : null;
}

export const updateQuery = (filtersFromUrl: FiltersFromUrl, spotlightFromUrl: SpotlightFromUrl | null) => {
  const query = prepareQuery(filtersFromUrl, spotlightFromUrl);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  window.history.replaceState({}, '', query.length ? `${url.pathname}?${query}` : url.pathname);
}