import {Logger} from '@/utils/Logger.ts';


interface SpriteSheetItemData {
    fileName: string;
    frame: {
        x: number;
        y: number;
        w: number;
        h: number;
    }
}

class SpriteSheetIcons {

    private jsonUrl = '/textures/atlases/iconpack64.json';
    private jsonData: Map<string, SpriteSheetItemData> = new Map();
    private sheetWidth = 0;
    private sheetHeight = 0;

    async init() {
        try {
            const jsonRes = await fetch(this.jsonUrl);
            const data = await jsonRes.json();
            if (data.frames?.length) {
                for (let i = 0; i < data.frames.length; i++) {
                    const frame = data.frames[i];
                    this.jsonData.set(frame.filename, frame);
                }
            }
            if (data.meta) {
                this.sheetHeight = data.meta.size.h;
                this.sheetWidth = data.meta.size.w;
            }
        } catch (e) {
            Logger.error('Error while initializing SpriteSheetIcons', e);
        }
    }


    getStyle(name: string, width: number, height: number): Record<string, string> | null {
        const data = this.jsonData.get(name);
        if (!data) {
            return null;
        }
        const scaleFactorX = data.frame.w / width;
        const scaleFactorY = data.frame.h / height;

        return {
            width: `${width}px`,
            height: `${height}px`,
            backgroundImage: 'url("/textures/atlases/iconpack64.png")',
            backgroundPosition: `-${data.frame.x / scaleFactorX}px -${data.frame.y / scaleFactorY}px`,
            backgroundSize: `${this.sheetWidth / scaleFactorX}px ${this.sheetHeight / scaleFactorY}px`,
            backgroundRepeat: 'no-repeat',
        }
    }

}

export const spriteSheetIcons = new SpriteSheetIcons();