type Props = {
    className?: string,
}

export const VerticalLine = ({className}: Props) => {
    return (
        <svg className={'w-0.5 h-6' + (className || '')} viewBox="0 0 2 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.07031 0.994141V24.9941" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round"/>
        </svg>
    );
}