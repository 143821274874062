interface Radius {
    tl: number;
    tr: number;
    br: number;
    bl: number;
}

export function drawRoundedRect( ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number | Radius ): void {
    // If radius is a number, use it for all corners
    let rad: Radius;
    if (typeof radius === "number") {
      rad = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      rad = { tl: radius.tl || 0, tr: radius.tr || 0, br: radius.br || 0, bl: radius.bl || 0 };
    }

    ctx.beginPath();
    ctx.moveTo(x + rad.tl, y); // Move to the top-left corner
    ctx.lineTo(x + width - rad.tr, y); // Top side
    ctx.arcTo(x + width, y, x + width, y + rad.tr, rad.tr); // Top-right corner
    ctx.lineTo(x + width, y + height - rad.br); // Right side
    ctx.arcTo(x + width, y + height, x + width - rad.br, y + height, rad.br); // Bottom-right corner
    ctx.lineTo(x + rad.bl, y + height); // Bottom side
    ctx.arcTo(x, y + height, x, y + height - rad.bl, rad.bl); // Bottom-left corner
    ctx.lineTo(x, y + rad.tl); // Left side
    ctx.arcTo(x, y, x + rad.tl, y, rad.tl); // Top-left corner
    ctx.closePath();
}