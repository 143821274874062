export enum FilterMappingType {
    BOOLEAN = 'boolean',
    STRING = 'string',
}

export interface FilterDefinitionMapping {
    mapping: string
}

export interface FilterGroupDefinition {
    id: string,
    label: string,
    open?: boolean,
    priority?: number
}

export interface FilterDefinition {
    groupId: string;
    label: string | FilterDefinitionMapping;
    id: string | FilterDefinitionMapping;
    inputType: string;
    filterType: 'boolean' | 'string';
    filterMapping: string;
    filterMappingType: FilterMappingType;
    undefinedLabel?: string;
    value?: boolean | string;
    priority?: number;
}

export const symbolFilterGroupMap: Map<string, FilterGroupDefinition[]> = new Map([
    ["make:scenario",
        [{
            id: 'scenarios',
            label: 'Scenario status',
            open: true,
            priority: 100
        },
        {
            id: 'scenarioFolder',
            label: 'Scenario folder',
            priority: 90
        },
        {
            id: 'scenarioApps',
            label: 'Scenario apps',
            priority: 80
        }]
    ],
    ["make:webhook",
        [{
            id: 'webhooks',
            label: 'Webhooks'
        }]
    ],

]);

export const symbolFilterMap: Map<string, FilterDefinition[]> = new Map([
    ["make:scenario",
        [{
            groupId: 'scenarios',
            label: 'Active scenarios',
            id: 'activeScenarios',
            inputType: 'checkbox',
            filterType: 'boolean',
            filterMapping: 'attributes.active',
            filterMappingType: 'boolean' as FilterMappingType,
            value: true
        }, {
            groupId: 'scenarios',
            label: 'Inactive scenarios',
            id: 'inactiveScenarios',
            inputType: 'checkbox',
            filterType: 'boolean',
            filterMapping: 'attributes.active',
            filterMappingType: 'boolean' as FilterMappingType,
            value: false
        }, {
            groupId: 'scenarioFolder',
            label: {
                mapping: 'attributes.folder.name',
            },
            id: {
                mapping: 'attributes.folder.id',
            },
            inputType: 'checkbox',
            filterType: 'string',
            filterMapping: 'attributes.folder.id',
            filterMappingType: 'string' as FilterMappingType,
            undefinedLabel: 'Uncategorized'
        }, {
            groupId: 'scenarioApps',
            label: {
                mapping: 'attributes.apps[].title',
            },
            id: {
                mapping: 'attributes.apps[].slug',
            },
            inputType: 'checkbox',
            filterType: 'string',
            filterMapping: 'attributes.apps[].slug',
            filterMappingType: 'string' as FilterMappingType
        }]
    ],
    ["make:webhook",
        [{
            groupId: 'webhooks',
            label: 'Enabled webhooks',
            id: 'enabledWebhooks',
            inputType: 'checkbox',
            filterType: 'boolean',
            value: true,
            filterMapping: 'attributes.enabled',
            filterMappingType: 'boolean' as FilterMappingType,
            priority: 2
        },
        {
            groupId: 'webhooks',
            label: 'Disabled webhooks',
            id: 'disabledWebhooks',
            inputType: 'checkbox',
            filterType: 'boolean',
            value: false,
            filterMapping: 'attributes.enabled',
            filterMappingType: 'boolean' as FilterMappingType
        }]
    ],

]);