import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { LinkPort } from "./LinkPort";

export class LinksPortsIM extends BaseInstancedMesh{

    constructor(size : number){
        super(LinkPort.getGeometry(), LinkPort.getMaterial(), size);

        this.castShadow = false;
        this.receiveShadow = true;

        this.name = "portsInstancedMesh";
    }
}