import Tooltip from '../utils/Tooltip.tsx';
import {Fragment} from 'react';
import AppIcon from '@/components/utils/AppIcon.tsx';

interface AppItem {
    slug: string,
    theme: string,
    title?: string
}

type Props = {
    apps: AppItem[];
    allowTooltip?: boolean
}

export default function AppsCircles({apps, allowTooltip}: Props) {

    const renderCircle = (index: number, app: AppItem) => {
        return (
            <div
                key={index}
                className={'h-6 w-6 rounded-full p-1'}
                style={{
                    backgroundColor: app.theme || 'transparent'
                }}
            >
                <AppIcon
                    iconSlug={app.slug}
                    iconWidth={16}
                    iconHeight={16}
                    iconSrc={`https://eu1.make.com/static/img/packages/${app.slug}_32.png`}
                />
            </div>
        );
    }

    return (
        <div
            className={'flex flex-row gap-1'}
        >
            {apps.map((app, i) => {
                if (!app) {
                    return;
                }
                return (
                    <Fragment key={i}>
                        {allowTooltip ? (
                            <Tooltip message={app.title || app.slug} position={'top'}>{renderCircle(i, app)}</Tooltip>
                        ) : renderCircle(i, app)}
                    </Fragment>
                )
            })}
        </div>
    )
}