import type {Symbol} from '@/three/symbols/Symbol';
import {classNames} from '@/utils/helpers.ts';
import {symbolHasUrl} from '@/utils/symbol.ts';
import AppIcon from '@/components/utils/AppIcon.tsx';

type Props = {
    symbol: Symbol;
}

export default function NodeTitle({symbol}: Props) {
    return (
        <div className={'flex flex-row grow items-top gap-3'}>
            <AppIcon
                className={'rounded-btn w-10 h-10 shrink-0 p-2'}
                iconSlug={symbol.iconSlug}
                iconWidth={24}
                iconHeight={24}
                gradientColor={`#${symbol.color.getHexString()}`}
                iconSrc={symbol.iconURL}
            />
            <div className="flex flex-col justify-center">
                <div className={'text-xl font-semibold leading-5 [word-break:break-word]'}>
                  <span
                      className={classNames('inline', !symbol.originalData!.title ? 'text-base-content-300' : '')}
                      onClick={() => {
                          if (symbolHasUrl(symbol)) {
                              window.open(symbol.originalData!.url, '_blank');
                          }
                      }}
                  >{
                      symbol.originalData!.title ? symbol.originalData!.title : 'Name unknown'}
                  </span>
                </div>
            </div>
        </div>
    );
}