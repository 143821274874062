import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { GroupShapeSide } from './GroupShapeSide';

export class GroupsShapesSideBM extends BaseBatchedMesh{
    constructor(size : number){
        const group = new GroupShapeSide();

        // check geometry.attributes.position.count to figure out the needed max vertex count
        // the geometry is non-indexed so no need for max index count
        super(size, size * 612, 0, group.getMaterial());

        this.layers.set(2);

        this.castShadow = false;
        this.receiveShadow = true;

        group.dispose();
    }
}