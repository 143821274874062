import { Symbol } from "@/three/symbols/Symbol";
import { Repository } from "../../common/Repository";

export const assignSymbolsToGroups = (symbols: Symbol[]) => {
    Repository.groups.forEach((group) => {
        const groupSymbols: Symbol[] = [];
        for(let i = 0; i < symbols.length; i++){
            if(symbols[i].groupId === group.id){
                groupSymbols.push(symbols[i]);
            }
        }
        group.symbols = groupSymbols;
    });
}