import * as THREE from 'three';
import { Symbol } from "../symbols/Symbol";

export const rndColor = () => new THREE.Color().setHex(Math.random() * 0xffffff);

export const toggleClass = (elm: HTMLElement, name: string) => {
  if (elm.classList.contains(name)) {
    elm.classList.remove(name);
  } else {
    elm.classList.add(name);
  }
}

export const getMakeIconUrl = (slug: string) => {
  const path = /^app#/.test(slug) ? `apps/${slug.substr(4)}` : `packages/${slug}`;
  return `https://eu1.make.com/static/img/${path}_32.png`;
}

export const sleep = (duration: number) => {
  return new Promise(resolve => setTimeout(resolve, duration));
}

/**
 * Creates a throttled function that only invokes the provided function (`func`) at most once
 * given number of milliseconds (`wait`)
 * @param fn - A function to be to be throttled.
 * @param wait - Wait time between each execution.
 * @returns The throttle function.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(fn: Function, wait = 20): any {
  let lastTime: number;

  return function throttled(this: any) {
    const now = Date.now();
    if (!lastTime || now - lastTime >= wait) {
      lastTime = now;
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    }
  };
}

export const getBoundsRectBySymbols = (symbols: Symbol[], offset = 0): THREE.Vector4 => {
  const unfilteredSymbols = [];

  for(let i = 0 ; i < symbols.length; i++){
    if(!symbols[i].filtered){
      unfilteredSymbols.push(symbols[i]);
    }
  }
  
  // Check if there are no points
  if(unfilteredSymbols.length === 0){
    return new THREE.Vector4( 0, 0, 0.001, 0.001 );
  }

  // Check if there's only one point
  if (unfilteredSymbols.length === 1) {
    const symbol = unfilteredSymbols[0];
    return new THREE.Vector4( symbol.three.position.x - offset, symbol.three.position.z - offset, 2 * offset, 2 * offset );
  }

  // Initialize the min/max values with the first point
  let minX = unfilteredSymbols[0].three.position.x;
  let maxX = unfilteredSymbols[0].three.position.x;
  let minY = unfilteredSymbols[0].three.position.z;
  let maxY = unfilteredSymbols[0].three.position.z;

  // Iterate over all points to find the min/max x and y values
  for (let i = 1; i < unfilteredSymbols.length; i++) {
    const symbol = unfilteredSymbols[i];
    
    if (symbol.three.position.x < minX) minX = symbol.three.position.x;
    if (symbol.three.position.x > maxX) maxX = symbol.three.position.x;
    if (symbol.three.position.z < minY) minY = symbol.three.position.z;
    if (symbol.three.position.z > maxY) maxY = symbol.three.position.z;
  }

  // Calculate the width and height of the rectangle with the offset applied
  const width = maxX - minX + 2 * offset;
  const height = maxY - minY + 2 * offset;

  // Return the bounding rectangle in the form (x, y, width, height) with offset applied
  return new THREE.Vector4( minX - offset, minY - offset, width, height );
}
