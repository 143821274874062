import * as THREE from 'three';

import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { CosmosThree } from '../CosmosThree';
import { SymbolIcon } from './SymbolIcon';

export class SymbolsIconsIM extends BaseInstancedMesh{

    texturePositions: THREE.InstancedBufferAttribute;
    textureSizes: THREE.InstancedBufferAttribute;

	iconNeedsUpdate = false;

    constructor(size : number){
        const geometry = SymbolIcon.getGeometry();
        
        const texturePositions = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);
        const textureSizes = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);

		geometry.setAttribute('texOffset', texturePositions);
        geometry.setAttribute('texSize', textureSizes);

        const material = SymbolIcon.getMaterial();

        material.uniforms.atlasSize.value = new THREE.Vector2(CosmosThree.iconsAtlasJson!.meta.size.w, CosmosThree.iconsAtlasJson!.meta.size.h);

        super(geometry, material, size);

        this.texturePositions = texturePositions;
        this.textureSizes = textureSizes;

        this.name = "iconsInstancedMesh";
	}

	override sync (){
		super.sync();

		if(this.iconNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i] as SymbolIcon;
                if(elem.iconNeedsUpdate){
                    this.syncIcon(elem);
                }
            }
        }
	}

	syncIcon(elem: SymbolIcon){
        elem.iconNeedsUpdate = false;
		const iconImageData = CosmosThree.iconsAtlasMap?.get(elem.slug);
		if(iconImageData){
			this.setIconAt(elem.instanceId, iconImageData.frame.x, iconImageData.frame.y, iconImageData.frame.w, iconImageData.frame.h);
		}
    }

	setIconAt(instanceId: number, x: number, y: number, width: number, height: number){
        this.texturePositions.array [ instanceId * 2 ] = x;
        this.texturePositions.array [ instanceId * 2 + 1 ] = y;
        this.texturePositions.needsUpdate = true;
        this.textureSizes.array [ instanceId * 2 ] = width;
        this.textureSizes.array [ instanceId * 2 + 1 ] = height;
        this.textureSizes.needsUpdate = true;
    }
}