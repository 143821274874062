import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { SymbolPad } from "./SymbolPad";

export class SymbolsPadsIM extends BaseInstancedMesh{

    constructor(size : number){
        super(SymbolPad.getGeometry(), SymbolPad.getMaterial(), size);

        this.receiveShadow = true;

        this.name = "padsInstancedMesh";
    }
}