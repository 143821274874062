import {useEffect, useRef} from 'react';
import { useLazyEffect } from '../utils/hooks.ts';
import { useAppStore } from '../store/Store';
import { InfoNotifications, LoadingNotifications, NotificationType } from '../store/Notification.ts';
import FilterManager from '../three/utils/filter/FilterManager.ts';
import { FEATURES } from '../store/Features.ts';
import { Logger } from '../utils/Logger';
import { Repository } from '@/three/common/Repository.ts';

import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import { Mesh } from '../three/Mesh.ts';

import UseFiltersState from './utils/filters/FiltersState.ts';
import { CosmosThree } from '../three/CosmosThree.ts';
import { SysInfoResolver } from '@/three/utils/misc/SysInfo.ts';

function Cosmos({spaceId}: {spaceId?: string}) {

	const ref = useRef<HTMLDivElement>(null);
	const cosmosInstance = useRef<CosmosThree | null>(null);
	const onceLoaded = useRef(false);

	const { activeFilters } = UseFiltersState();

	const {
		cosmosStoreData,
		setCosmosReady,
		userSettings,
		addNotification,
		removeNotification,
		currentMeshInstance,
		setCurrentMeshInstance,
		activeSpace,
		setFilterList,
		features,
		filtersFromUrl,
		setFiltersFromUrl,
		setUserSetting,
		closeSettings,
		uiInteractive,
		activeTheme,
		spotlightFromUrl,
		setFirstNodeDetail,
	} = useAppStore((state) => {
		return {
			cosmosStoreData: state.cosmos,
			setCosmosReady: state.setCosmosReady,
			userSettings: state.userSettings,
			addNotification: state.addNotification,
			removeNotification: state.removeNotification,
			currentMeshInstance: state.currentMeshInstance,
			setCurrentMeshInstance: state.setCurrentMeshInstance,
			activeSpace: state.activeSpace,
			setFilterList: state.setFilterList,
			features: state.features,
			filtersFromUrl: state.filtersFromUrl,
			setFiltersFromUrl: state.setFiltersFromUrl,
			setUserSetting: state.setUserSetting,
			closeSettings: state.closeSettings,
			uiInteractive: state.uiInteractive,
			activeTheme: state.activeTheme,
			spotlightFromUrl: state.spotlightFromUrl,
			setFirstNodeDetail: state.setFirstNodeDetail,
		}
	});

	const loadMesh = async (spaceId: string, cosmosThree: CosmosThree, ) => {
		if (currentMeshInstance) {
			await currentMeshInstance.hideAll();
			currentMeshInstance.dispose();
		}

		// activeFilter listening hooks are now properly cached, if you reload mesh with same filters, it would not trigger layout at all
		setFilterList(null);

		const mesh = new Mesh(spaceId, cosmosThree);
		mesh.setTheme(activeTheme.id as "cosmosLight" | "cosmosDark", false);
		mesh.setCamera(userSettings.cameraMode, false);
		CosmosThree.graphScene.attach(mesh);

		mesh.loadingStarted.add(() => {
			addNotification(NotificationType.LOADING, 'Loading Space', '', LoadingNotifications.MESH_LOADING);
		});

		mesh.loadingEnded.add(() => {
			removeNotification(LoadingNotifications.MESH_LOADING);
			if (!mesh.loaded) {
				return;
			}
			addNotification(NotificationType.SUCCESS, 'Space loaded');

			// The symbols are loaded and instantiated so we can generate the filter list
			// This will trigger the useEffect() with 'activeFilters' dependency below
			setFilterList(FilterManager.collectFiltersFromNodes((mesh.symbols), filtersFromUrl));
		});

		mesh.layoutStarted.add(() => {
			addNotification(NotificationType.LOADING, 'Performing layout', '', LoadingNotifications.MESH_LAYOUTING);
		});

		mesh.layoutEnded.add(() => {
			removeNotification(LoadingNotifications.MESH_LAYOUTING);
			if (!mesh.loaded) {
				return;
			}
			addNotification(NotificationType.SUCCESS, 'Layout completed');

			setFirstNodeDetail(mesh.symbols[0] ?? null);
			// set spotlighted from url
			if (spotlightFromUrl && !onceLoaded.current) {
				const spotlightSymbol = Repository.symbolsByTypeAndExternalId.get(`${spotlightFromUrl.symbolType}:${spotlightFromUrl.symbolExternalId}`);
				if (spotlightSymbol && !spotlightSymbol.filtered) {
					setTimeout(() => {
						spotlightSymbol.select = true;
					}, 1000)
				}
			}

			onceLoaded.current = true;
		});

		mesh.idleModeStarted.add(() => {
			addNotification(NotificationType.INFO, 'Idle Mode Activated', 'Move your mouse of press any key to exit', InfoNotifications.IDLE_MODE);
		});

		mesh.idleModeStopped.add(() => {
			removeNotification(InfoNotifications.IDLE_MODE);
		});

		setCurrentMeshInstance(mesh);

		try {
			await mesh.load();
		} catch (e) {
			addNotification(NotificationType.ERROR, 'Failed to load Space')
			Logger.error(e);
		}
	}

	useEffect(() => {
		// listen for active theme change, you can get current colors and vars from activeTheme.variables
		// colors are store as rgb values separated by comma "255, 255, 255" (because of tailwind config <alpha value> compatibility)
		// Logger.log('theme changed', activeTheme);
		currentMeshInstance?.setTheme(activeTheme.id as "cosmosLight" | "cosmosDark");
	}, [activeTheme.id]);

	useLazyEffect(() => {
		currentMeshInstance?.layout();
	}, [
		features[FEATURES.LAYOUT_COMPACTNESS_FACTOR],
		features[FEATURES.LAYOUT_GROUP_COMPACTNESS_FACTOR],
		features[FEATURES.LAYOUT_QUALITY_TIME_RATIO],
		features[FEATURES.LAYOUT_GROUP_SUBSTRUCTURE_SCOPE],
	], 300);


	useEffect(() => {
		if (currentMeshInstance && activeFilters) {
			const urlFiltersObject = activeFilters.reduce((acc: {[key: string]: string[]}, curr) => {
				const itemsIds = curr.items.map(i => i.id);
				if (itemsIds.length) {
					acc[curr.id] = curr.items.map(i => i.id);
				}
				return acc;
			}, {});
			setFiltersFromUrl(urlFiltersObject);
			currentMeshInstance.filterNodes(activeFilters);
		}
	}, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentMeshInstance) {
			setUserSetting('cameraMode', userSettings.cameraMode);
			currentMeshInstance.setCamera(userSettings.cameraMode);
		}
	}, [userSettings.cameraMode]); // eslint-disable-line react-hooks/exhaustive-deps

	useLazyEffect(() => {
		if (cosmosInstance.current && cosmosStoreData.ready) {
			if (activeSpace) {
				loadMesh(activeSpace.id, cosmosInstance.current);
			}
			closeSettings();
		}
	}, [cosmosStoreData, activeSpace?.id], 100)

	const handleClickOnCanvas = (/*event:  MouseEvent<HTMLElement>*/) => {
		if (uiInteractive) closeSettings();
	};

	useEffect(() => {

		if(typeof Window !== 'undefined'){
			gsap.registerPlugin(MotionPathPlugin);
		}

		/*
			// this was moved to RootLayout.tsx, parse query as soon as possible
			// load filters from URL on component init
			const filtersFromQuery = decodeFiltersFromQuery(searchParams);

			if (Object.keys(filtersFromQuery).length) {
				setFiltersFromUrl(filtersFromQuery);
			}
		*/

		let cosmosThree: CosmosThree;

		const sysInfoResolver = new SysInfoResolver();
		sysInfoResolver.getSysInfo(() => {
			cosmosThree = new CosmosThree(ref.current!, sysInfoResolver.systemInfo);
			cosmosThree.setCameraMode(userSettings.cameraMode, false);
			cosmosInstance.current = cosmosThree;

			if (spaceId) {
				loadMesh(spaceId, cosmosThree);
			}

			setCosmosReady(true);
		}, 3000); // Sets a timeout of 3 seconds in case the benchmark fetching from the internet stalls

		return () => {
			Logger.log("------------ CLEANUP ----------", );
			setCosmosReady(false);
			setCurrentMeshInstance(null);
			cosmosThree.destroy();
			cosmosInstance.current = null;
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className='fixed left-0 top-0 w-full h-full' ref={ref} onClick={handleClickOnCanvas}>
		</div>
	);
}

export default Cosmos;