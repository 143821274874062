import type { StateCreator } from 'zustand'
import { Symbol } from '../three/symbols/Symbol';
import { Link } from '../three/links/Link';
import {FilterSlice} from '@/store/Filter.ts';
import {BaseSymbol} from '@/three/symbols/BaseSymbol.ts';


export interface NodeDetailSlice {
    nodeDetail: Symbol | Link | null,
    firstNodeDetail: Symbol | Link | null,
    setNodeDetail: (node: Symbol | Link | null) => void,
    setFirstNodeDetail: (node: Symbol | Link | null) => void,
}

export const createNodeDetailSlice: StateCreator<NodeDetailSlice & FilterSlice, [], [], NodeDetailSlice> = (set, get) => ({
    nodeDetail: null,
    firstNodeDetail: null,
    setNodeDetail: (node: Symbol | Link | null) => {

        if (node && node instanceof BaseSymbol) {
            get().setSpotlightFromUrl({
                symbolType: node.originalData!.type,
                symbolExternalId: node.originalData!.external_id
            });
        } else {
            get().setSpotlightFromUrl(null);
        }

        set(() => (
            {
                nodeDetail: node
            }
        ));
    },
    setFirstNodeDetail: (node: Symbol | Link | null) => {
        return set(() => (
            {
                firstNodeDetail: node
            }
        ));
    }
})
