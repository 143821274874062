import type {
  WorkerLayoutRequestMessage,
  WorkerLayoutResponseMessage,
  WorkerLink,
  WorkerSymbol,
  WorkerGroup,
} from './LayoutWorker';
import LayoutWorker from './LayoutWorker?worker';
import {LayoutWorker as Layout} from './LayoutWorker';
import {REQUEST_MESSAGE_TYPE, RESPONSE_MESSAGE_TYPE} from './LayoutWorker.ts';
import {Repository} from '../../common/Repository.ts';
import type {OrganicLayoutOptions, YFilesLayoutOptions} from './yfiles/YFilesLayout.ts';
import {GRID_SHORT_SUBDIV, SCALE_FACTOR, SYMBOL_PAD_SIZE} from '../../common/constants.ts';
import {v4 as uuidv4} from 'uuid';
import { useAppStore } from '../../../store/Store.ts';
import { FEATURES } from '../../../store/Features.ts';
import { Logger } from '../../../utils/Logger';
import { OrganicLayoutClusteringPolicy } from '@celonis/yfiles';
import { LayoutCacheManager } from './LayoutCacheManager.ts';
import { Group } from '../../groups/Group.ts';
import { Symbol } from '@/three/symbols/Symbol.ts';
import { Link } from '@/three/links/Link.ts';

export interface LayoutCoordinates {
  [key: string]: {
    x: number,
    y: number
  }
}

export interface LayoutOptions {
  animated?: boolean;
  yfiles: YFilesLayoutOptions;
}

class DeferredPromise {
  public promise: Promise<any>;
  public reject: (reason: any) => void;
  public resolve: (value: any) => void;

  constructor() {
    this.reject = () => {};
    this.resolve = () => {};
    this.promise = new Promise((resolve, reject)=> {
      this.reject = reject
      this.resolve = resolve
    })
  }
}

type LayoutWorkerInstance = {
  id: number,
  worker: Worker
  inUse: boolean,
  symbolsAndLinksIndex?: string,
}

export class LayoutManager {

  /*private worker: Worker;*/
  private promises: Map<string, DeferredPromise>;
  private _useWorker = true;
  private _layoutCacheManager: LayoutCacheManager;
  private _layoutedSymbolIds: Set<string>;

  // refactor to multiple workers
  private _numberOfWorkers = 2;
  private _lastUsedWorkerId = 0;
  private _workers: LayoutWorkerInstance[] = [];

  constructor() {

    this._layoutCacheManager = new LayoutCacheManager();
    this.promises = new Map();
    /*this.worker = new LayoutWorker();*/
    this._layoutedSymbolIds = new Set();

    const onLayoutDone = (e: MessageEvent<unknown>) => {
      const data = e.data as WorkerLayoutResponseMessage;
      switch (data.message) {
        case RESPONSE_MESSAGE_TYPE.LAYOUT_DONE: {
          // move symbols
          this.applyFromWorker(data.symbols, data.symbolsAndLinksIndex, data.cacheOnly);

          // resolve promise
          const promise = this.promises.get(data.promiseId);
          if (promise) {
            promise.resolve(data.promiseId);
            const layoutWorker = this._workers.find((worker) => worker.id === data.workerId);
            if (layoutWorker) {
              layoutWorker.inUse = false;
            }
          }
          break;
        }
      }
    }

    /*this.worker.onmessage = onLayoutDone;*/

    for (let i = 0; i < this._numberOfWorkers; i++) {
      const worker = new LayoutWorker();
      worker.onmessage = onLayoutDone;
      this._workers.push({
        id: i,
        inUse: false,
        worker
      });
    }
  }

  public async layout(
    symbols: Symbol[],
    groups: Group[],
    links: Link[],
    cacheOnly: boolean = false
  ) {

    if (!cacheOnly) {
      this._layoutedSymbolIds = new Set();
    }

    // try to load cached layout
    const { symbolsAndLinksIndex, layoutCoordinates } = this._layoutCacheManager.load(symbols, links);
    if (layoutCoordinates) {
      if (cacheOnly) {
        return;
      }
      Logger.log('applying layout coordinates from cache');
      this.applyFromCache(layoutCoordinates);
      return;
    }

    const organicLayoutOptions: OrganicLayoutOptions = {
			clusteringPolicy: OrganicLayoutClusteringPolicy.USER_DEFINED,
			compactnessFactor: useAppStore.getState().getFeatureValue(FEATURES.LAYOUT_COMPACTNESS_FACTOR) as number,
			groupNodeCompactness: useAppStore.getState().getFeatureValue(FEATURES.LAYOUT_GROUP_COMPACTNESS_FACTOR) as number,
			qualityTimeRatio: useAppStore.getState().getFeatureValue(FEATURES.LAYOUT_QUALITY_TIME_RATIO) as number,
			groupSubstructureScope: useAppStore.getState().getFeatureValue(FEATURES.LAYOUT_GROUP_SUBSTRUCTURE_SCOPE) as number,
		};

    const options: LayoutOptions = {
      yfiles: {
        organicLayoutOptions,
      }
    } as LayoutOptions;

    // some symbols/links data cannot be copied/serialized and pass to worker
    // need to create serializable data
    const workerSymbols: WorkerSymbol[] = symbols.map((item) => {
      return {
        id: item.id,
        x: 0,
        y: 0,
        width: SYMBOL_PAD_SIZE * SCALE_FACTOR,
        height: SYMBOL_PAD_SIZE * SCALE_FACTOR,
        groupId: item.groupId
      }
    });

    const workerLinks: WorkerLink[] = links.map((item) => {
      return {
        id: item.id,
        source: {
          id: item.source!.id
        },
        target: {
          id: item.target!.id
        }
      }
    });

    const workerGroups: WorkerGroup[] = groups.map((item) => {
      return {
        id: item.id,
        parentId: null,
      }
    });
    if (!this._useWorker) {
      const result = await Layout.layout(
          workerSymbols,
          workerGroups,
          workerLinks,
          options
      );
      this.applyFromWorker(result.symbols, symbolsAndLinksIndex, cacheOnly);
      
      return;
    }

    const deferredPromise = new DeferredPromise();
    const promiseId = uuidv4();
    this.promises.set(promiseId, deferredPromise);

    const layoutWorker = this.getAvailableWorker();
    layoutWorker.worker.postMessage({
      workerId: layoutWorker.id,
      message: REQUEST_MESSAGE_TYPE.LAYOUT,
      promiseId: promiseId,
      symbols: workerSymbols,
      groups: workerGroups,
      links: workerLinks,
      symbolsAndLinksIndex,
      options,
      cacheOnly
    } as WorkerLayoutRequestMessage);

    /*this.worker.postMessage({
      message: REQUEST_MESSAGE_TYPE.LAYOUT,
      promiseId: promiseId,
      symbols: workerSymbols,
      links: workerLinks,
      options
    } as WorkerLayoutRequestMessage);*/

    return deferredPromise.promise;
  }

  private getAvailableWorker(): LayoutWorkerInstance {
    const workerFound = this._workers.find((worker) => worker.inUse === false);
    if (workerFound) {
      this._lastUsedWorkerId = workerFound.id;
      workerFound.inUse = true;
      return workerFound;
    }

    let workerId = this._lastUsedWorkerId + 1;

    if (workerId > this._workers.length - 1) {
      workerId = 0;
    }

    const worker= this._workers[workerId];

    this._lastUsedWorkerId = workerId;
    worker.inUse = true;
    return worker;
  }

  private applyFromWorker(symbols: WorkerSymbol[], symbolsAndLinksIndex: string, cacheOnly: boolean): void {
    if (symbols.length) {
      Logger.time(`[perf] mesh: applying graph layout`);

      for(let i = 0 ; i < symbols.length; i++){
        const workerSymbol = symbols[i];
        workerSymbol.x = workerSymbol.x / SCALE_FACTOR;
        workerSymbol.y = workerSymbol.y / SCALE_FACTOR;
      }

      if (!cacheOnly) {
        this.updateGraph(symbols);
      }

      Logger.timeEnd(`[perf] mesh: applying graph layout`);

      this._layoutCacheManager.persist(symbols, symbolsAndLinksIndex);
    }
  }

  private applyFromCache(coordinates: LayoutCoordinates): void {
    const symbols = [];

    for (const [symbolId, coords] of Object.entries(coordinates)) {
      symbols.push({id: symbolId, x: coords.x, y: coords.y});
      this._layoutedSymbolIds.add(symbolId);
    }

    this.updateGraph(symbols);
  }

  private updateGraph(symbols: WorkerSymbol[]){
    // First we update all symbols positions
    for(let i = 0 ; i < symbols.length; i++){
      const symbol = Repository.symbols.get(symbols[i].id);
        if (symbol) {
          symbol.three.position.x = Math.round(symbols[i].x / GRID_SHORT_SUBDIV) * GRID_SHORT_SUBDIV;
          symbol.three.position.z = Math.round(symbols[i].y / GRID_SHORT_SUBDIV) * GRID_SHORT_SUBDIV;
          symbol.matrixNeedsUpdate = true;

          this._layoutedSymbolIds.add(symbol.id);
        }
    }

    // Now that all symbols have their position we update all the links, which involve generating all their geometries and position them
    Repository.links.forEach(link => {
      link.update();
    });

    Repository.groups.forEach(group => {
      group.update();
    });

    // Sort the array based on x and z coordinates.
    // First, sort by x (left to right, which means descending order of x in this case)
    const zTolerance = 0.5;
    Repository.mesh?.groups.sort((a, b) => {
      // If 'z' positions are close (within the tolerance), sort by 'x'
      if (Math.abs((a.three.position.z - (a.height / 2)) - (b.three.position.z - (b.height / 2))) <= zTolerance) {
        return (a.three.position.x - (a.width / 2)) - (b.three.position.x - (b.width / 2));  // Sort by 'x' (left to right)
      }
      // Otherwise, sort by 'z' (top to bottom)
      return (a.three.position.z - (a.height / 2)) - (b.three.position.z - (b.height / 2));
    });
  }

  public shouldReLayout(symbols: Symbol[]): boolean {
    if (!this._layoutedSymbolIds.size || !symbols.length) {
      return false;
    }

    // if any of symbol id is not in already layouted symbol id = layout
    for (const symbol of symbols) {
      if (!this._layoutedSymbolIds.has(symbol.id)) {
        return true;
      }
    }

    return false;
  }

  public destroy() {
    this.promises.clear();
    /*this.worker.terminate();*/
    for (const layoutWorker of this._workers) {
      layoutWorker.worker.terminate();
    }
  }

}