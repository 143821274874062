import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionBatchedOpacity } from '../utils/materials/extensions/ExtensionBatchedOpacity';
import { LINK_PORT_RADIUS, LINK_WIDTH } from '../common/constants';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { CosmosThree } from '../CosmosThree';
import { ExtensionBatchedTint } from '../utils/materials/extensions/ExtensionBatchedTint';

export class LinkShape extends BaseObject{

    static interactionLayer = 1;

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionBatchedTint, ExtensionFog, ExtensionBatchedOpacity],
            {
				fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
				fActive: true,
				tintColor: CosmosThree.linksMutedTint,
				transparent: true,
				alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                // wireframe: false
            },
            { debug: false }
        );

		return material;
    }

    updateGeometry(pointSourceLocal: THREE.Vector3, pointTargetLocal: THREE.Vector3){
		if (!this.instancedOrBatchedMesh) return;

		// Generate vertices, normals, and UVs for a single segment
		const vertices = [];
		const normals = [];
		const indices = [0, 1, 2, 2, 1, 3];
		const uv = [];

		// Calculate the tangent and normal
		const tangent = new THREE.Vector3().subVectors(pointTargetLocal, pointSourceLocal).normalize();
		const up = new THREE.Vector3(0, 1, 0);
		const normal = new THREE.Vector3().crossVectors(tangent, up).normalize().multiplyScalar(LINK_WIDTH / 2);

		const leftSource = new THREE.Vector3().copy(pointSourceLocal).sub(normal);
		const rightSource = new THREE.Vector3().copy(pointSourceLocal).add(normal);
		const leftTarget = new THREE.Vector3().copy(pointTargetLocal).sub(normal);
		const rightTarget = new THREE.Vector3().copy(pointTargetLocal).add(normal);

		vertices.push(leftSource.x, leftSource.y, leftSource.z);
		vertices.push(rightSource.x, rightSource.y, rightSource.z);
		vertices.push(leftTarget.x, leftTarget.y, leftTarget.z);
		vertices.push(rightTarget.x, rightTarget.y, rightTarget.z);

		// Normals for lighting
		const faceNormal = new THREE.Vector3().crossVectors(normal, tangent).normalize();
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For left source vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For right source vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For left target vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For right target vertex

		uv.push(0, 0);
		uv.push(1, 0);
		uv.push(0, 1);
		uv.push(1, 1);

		const sourcePointGeo = new THREE.CircleGeometry(LINK_PORT_RADIUS, 16);
		sourcePointGeo.rotateX(-Math.PI / 2);
		sourcePointGeo.translate(pointSourceLocal.x, 0, pointSourceLocal.z);

		const targetPointGeo = new THREE.CircleGeometry(LINK_PORT_RADIUS, 16);
		targetPointGeo.rotateX(-Math.PI / 2);
		targetPointGeo.translate(pointTargetLocal.x, 0, pointTargetLocal.z);

		// Create the geometry
		let geometry = new THREE.BufferGeometry();
		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uv, 2));
		geometry.setIndex(indices);

		geometry = BufferGeometryUtils.mergeGeometries([sourcePointGeo, targetPointGeo, geometry]);

		if(this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh){
			if(this.instanceId === -1){
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry( geometry );
				this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			}else{
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt( this.instanceId, geometry );
			}
		}
	}
}