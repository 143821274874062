import { DefaultSymbol } from "./DefaultSymbol";
import { MakeScenarioSymbol } from "./MakeScenarioSymbol";
import { StorageSymbol } from "./StorageSymbol";
import { WebhookSymbol } from "./WebhookSymbol";

export const symbolTypesMap: Map<string, {key: string, class: new (...args: any[]) => any}> = new Map([
    ["default", {key: "default", class: DefaultSymbol}],
    ["airtable:base", {key: "storage", class: StorageSymbol}],
    ["airtable:table", {key: "storage", class: StorageSymbol}],
    ["clickup:list", {key: "default", class: DefaultSymbol}],
    ["email:mailbox", {key: "default", class: DefaultSymbol}],
    ["freshdesk:agents", {key: "default", class: DefaultSymbol}],
    ["freshdesk:canned_responses", {key: "default", class: DefaultSymbol}],
    ["freshdesk:companies", {key: "default", class: DefaultSymbol}],
    ["freshdesk:contacts", {key: "default", class: DefaultSymbol}],
    ["freshdesk:groups", {key: "default", class: DefaultSymbol}],
    ["freshdesk:tickets", {key: "default", class: DefaultSymbol}],
    ["google:calendar", {key: "default", class: DefaultSymbol}],
    ["google:file", {key: "storage", class: StorageSymbol}],
    ["google:folder", {key: "storage", class: StorageSymbol}],
    ["google:spreadsheet", {key: "storage", class: StorageSymbol}],
    ["hubspot:calls", {key: "default", class: DefaultSymbol}],
    ["hubspot:companies", {key: "default", class: DefaultSymbol}],
    ["hubspot:contacts", {key: "default", class: DefaultSymbol}],
    ["hubspot:custom_objects", {key: "default", class: DefaultSymbol}],
    ["hubspot:deals", {key: "default", class: DefaultSymbol}],
    ["hubspot:products", {key: "default", class: DefaultSymbol}],
    ["hubspot:tickets", {key: "default", class: DefaultSymbol}],
    ["jira:project", {key: "default", class: DefaultSymbol}],
    ["jotform:form", {key: "default", class: DefaultSymbol}],
    ["make:datastore", {key: "storage", class: StorageSymbol}],
    ["make:module", {key: "default", class: DefaultSymbol}],
    ["make:scenario", {key: "make_scenario", class: MakeScenarioSymbol}],
    ["make:webhook", {key: "make_webhook", class: WebhookSymbol}],
    ["monday:board", {key: "default", class: DefaultSymbol}],
    ["mysql:table", {key: "storage", class: StorageSymbol}],
    ["salesforce:records", {key: "default", class: DefaultSymbol}],
    ["slack:channel", {key: "default", class: DefaultSymbol}],
    ["tally:form", {key: "default", class: DefaultSymbol}],
]);