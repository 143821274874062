import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { SYMBOL_PAD_RADIUS, SYMBOL_PAD_SIZE } from '../common/constants';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { CosmosThree } from '../CosmosThree';

export class SymbolPad extends BaseObject{

    type: "round" | "rect" = "round";

    static getGeometry(): THREE.BufferGeometry {
        const shape = new THREE.Shape();
		const angleStep = Math.PI * 0.5;
		const size = SYMBOL_PAD_SIZE;
		const radius = SYMBOL_PAD_RADIUS;

		shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
		shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
		shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
		shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: 0,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 12
		}).rotateX(-Math.PI/2);

        geometry.clearGroups();

        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionFog],
            {
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
                fActive: true,
                color: CosmosThree.symbolsPadColor,
                // wireframe: false
            },
            { debug: false }
        );

        return material;
    }
}