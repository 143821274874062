import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { GroupShapeTop } from './GroupShapeTop';

export class GroupsShapesTopBM extends BaseBatchedMesh{
    constructor(size : number){
        const group = new GroupShapeTop();

        // check geometry.attributes.position.count to figure out the needed max vertex count
        // the geometry is indexed max index count is needed
        super(size, size * 52, size * 150, group.getMaterial());

        this.castShadow = false;
        this.receiveShadow = true;

        group.dispose();
    }
}