import * as THREE from 'three';
import { LEGEND_BACKGROUND_COLOR_DARK, LEGEND_BACKGROUND_COLOR_LIGHT, LEGEND_BACKGROUND_RADIUS, LEGEND_BORDER_COLOR_DARK, LEGEND_BORDER_COLOR_LIGHT, LEGEND_BORDER_RADIUS, LEGEND_BORDER_SIZE, LEGEND_FONT_SIZE, LEGEND_GROUPS_TEXT_WEIGHT, LEGEND_HEIGHT, LEGEND_HORIZONTAL_PADDING, LEGEND_MAX_UNTRIMMED_CHARS, LEGEND_TEXT_COLOR_DARK, LEGEND_TEXT_COLOR_LIGHT, LEGEND_TEXT_WEIGHT, LEGEND_WIDTH, LEGENDS_ATLAS_HEIGHT, LEGENDS_ATLAS_SCALE, LEGENDS_ATLAS_WIDTH } from '../../common/constants';
import { Logger } from '../../../utils/Logger';
import { CosmosThree } from '../../CosmosThree';
import { drawRoundedRect } from '../misc/Canvas2DUtils';

export class SymbolLegendsTextureGenerator{

    canvasLight: OffscreenCanvas;
    ctxLight: OffscreenCanvasRenderingContext2D | null;

    canvasDark: OffscreenCanvas;
    ctxDark: OffscreenCanvasRenderingContext2D | null;

    width = LEGENDS_ATLAS_WIDTH;
    height = LEGENDS_ATLAS_HEIGHT;

    // Evrything will be double size to upscale the generated bitmap and avoid blurring of text as much as possible.
    legendWidth = LEGEND_WIDTH * LEGENDS_ATLAS_SCALE;
    legendHeight = LEGEND_HEIGHT * LEGENDS_ATLAS_SCALE;

    private _paddingX = 2;
    private _paddingY = 2;

    private _accuX = 0 + this._paddingX;
    private _accuY = 0 + this._paddingY;

    legendsPositions: Map<string, {x: number, y: number}> = new Map();
    legendsSizes: Map<string, {width: number, height: number}> = new Map();

    textureLight: THREE.CanvasTexture;
    textureDark: THREE.CanvasTexture;

    constructor (){
        this.canvasLight = new OffscreenCanvas(this.width, this.height);
        this.ctxLight = this.canvasLight.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.canvasDark = new OffscreenCanvas(this.width, this.height);
        this.ctxDark = this.canvasDark.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.ctxLight.font = `${LEGEND_TEXT_WEIGHT} ${LEGEND_FONT_SIZE * LEGENDS_ATLAS_SCALE}px "Inter var", sans`;
        this.ctxLight.textAlign = "center";
        this.ctxLight.textBaseline = "middle";
        this.ctxLight.direction = "inherit";

        this.textureLight = new THREE.CanvasTexture(this.canvasLight);
        this.textureLight.colorSpace = THREE.SRGBColorSpace;
        this.textureLight.generateMipmaps = false;
        this.textureLight.anisotropy = CosmosThree.anisotropy;

        this.ctxDark.font = `${LEGEND_TEXT_WEIGHT} ${LEGEND_FONT_SIZE * LEGENDS_ATLAS_SCALE}px "Inter var", sans`;
        this.ctxDark.textAlign = "center";
        this.ctxDark.textBaseline = "middle";
        this.ctxDark.direction = "inherit";

        this.textureDark = new THREE.CanvasTexture(this.canvasDark);
        this.textureDark.colorSpace = THREE.SRGBColorSpace;
        this.textureDark.generateMipmaps = false;
        this.textureDark.anisotropy = CosmosThree.anisotropy;

        // this.texture.minFilter = THREE.NearestMipmapNearestFilter;
        // this.texture.magFilter = THREE.NearestFilter;
    }

    private getMeasuredWidth(text: string, ctx: OffscreenCanvasRenderingContext2D | null): number {
        if (!ctx) return 0;
    
        ctx.font = `${LEGEND_GROUPS_TEXT_WEIGHT} ${LEGEND_FONT_SIZE * LEGENDS_ATLAS_SCALE}px "Inter var", sans`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.direction = "inherit";

        return ctx.measureText(text).width;
    }

    createLegendAt(instanceId: number, title: string){
        if(this.ctxLight && this.ctxDark){
            this.ctxLight.save();
            this.ctxDark.save();

            this.ctxLight.translate(this._accuX, this._accuY);
            this.ctxDark.translate(this._accuX, this._accuY);

            /* this.ctx.fillStyle = "#0f0";
            this.ctx.fillRect(0, 0, this.legendWidth, this.legendHeight); */

            // Debug red border
            /* this.ctxLight.fillStyle = "#f00";
            this.ctxLight.fillRect(0, 0, this.legendWidth, 1);
            this.ctxLight.fillRect(this.legendWidth - 1, 0, 1, this.legendHeight -1);
            this.ctxLight.fillRect(0, 0, 1, this.legendHeight -1);
            this.ctxLight.fillRect(0, this.legendHeight - 1, this.legendWidth , 1); */

            let ellipsis = "";
            if (title.length > LEGEND_MAX_UNTRIMMED_CHARS){
                ellipsis = "...";
            }else{
                ellipsis = "";
            }
            title = title.substring(0, LEGEND_MAX_UNTRIMMED_CHARS) + ellipsis;

            const textWidth = (Math.ceil(this.getMeasuredWidth(title, this.ctxLight)) || 1) + (LEGEND_BACKGROUND_RADIUS * LEGENDS_ATLAS_SCALE) * 2 + LEGEND_HORIZONTAL_PADDING * LEGENDS_ATLAS_SCALE;

            // This block is super important so the premultipliedAlpha result from the canvas is done correctly and we get no grey oulines when rendered
            // This is to prevent what is commonly known as 'Alpha bleeding'
            // Light
            this.ctxLight.fillStyle = "rgba(255, 255, 255, 0.002)"; // rgba(255, 255, 255, 0.002)
            this.ctxLight.fillRect((this.legendWidth - textWidth) / 2, 0, textWidth, this.legendHeight);
            // Dark
            this.ctxDark.fillStyle = "rgba(0, 0, 0, 0.002)"; // rgba(0, 0, 0, 0.002)
            this.ctxDark.fillRect((this.legendWidth - textWidth) / 2, 0, textWidth, this.legendHeight);

            // Light
            this.ctxLight.fillStyle = LEGEND_BORDER_COLOR_LIGHT;
            drawRoundedRect(this.ctxLight, (this.legendWidth - textWidth) / 2 + 2, 2, textWidth - 4 , this.legendHeight - 4, LEGEND_BORDER_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxLight.fill();
            // Dark
            this.ctxDark.fillStyle = LEGEND_BORDER_COLOR_DARK;
            drawRoundedRect(this.ctxDark, (this.legendWidth - textWidth) / 2 + 2, 2, textWidth - 4 , this.legendHeight - 4, LEGEND_BORDER_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxDark.fill();

            // Light
            this.ctxLight.fillStyle = LEGEND_BACKGROUND_COLOR_LIGHT;
            drawRoundedRect(this.ctxLight, (this.legendWidth - textWidth) / 2 + 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, textWidth - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, this.legendHeight - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, LEGEND_BACKGROUND_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxLight.fill();
            // Dark
            this.ctxDark.fillStyle = LEGEND_BACKGROUND_COLOR_DARK;
            drawRoundedRect(this.ctxDark, (this.legendWidth - textWidth) / 2 + 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, 2 + LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE, textWidth - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, this.legendHeight - 4 - LEGEND_BORDER_SIZE * LEGENDS_ATLAS_SCALE * 2, LEGEND_BACKGROUND_RADIUS * LEGENDS_ATLAS_SCALE);
            this.ctxDark.fill();

            // Light
            this.ctxLight.fillStyle = LEGEND_TEXT_COLOR_LIGHT;
            this.ctxLight.fillText(title, this.legendWidth / 2, this.legendHeight / 2);
            // Dark
            this.ctxDark.fillStyle = LEGEND_TEXT_COLOR_DARK;
            this.ctxDark.fillText(title, this.legendWidth / 2, this.legendHeight / 2);

            this.ctxLight.restore();
            this.ctxDark.restore();

            this.legendsPositions.set(instanceId + "", {x: this._accuX, y: this._accuY});
            this.legendsSizes.set(instanceId + "", {width: textWidth, height: this.legendHeight});

            this._accuX += this.legendWidth + this._paddingX;

            if(this._accuX + this.legendWidth + this._paddingX > this.width){
                this._accuX = 0 + this._paddingX;

                this._accuY += this.legendHeight + this._paddingY;

                if(this._accuY + this.legendHeight + this._paddingY > this.height){
                    Logger.error(`The Legend with id: ${instanceId} doesn't fit in the ${this.canvasLight.width}x${this.canvasLight.height} px canvas.`);
                }
            }
        }
    }

    // For debugging
    downloadTexture(){
        // Downloads the offscreen canvas
        this.downloadAsImage('canvas_image');
    }

    downloadAsImage(filename: string) {
        // Convert OffscreenCanvas to Blob
        this.canvasLight.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename + "Light.png";
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });

        // Convert OffscreenCanvas to Blob
        this.canvasDark.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename + "Dark.png";
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    }

    dispose(){
        this.textureLight?.dispose();
        this.textureDark?.dispose();
    }
}