import { SymbolsShapesIM } from "../symbols/SymbolsShapesIM";
import { LinksShapesBM } from "../links/LinksShapesBM";
import { SymbolsIconsIM } from "../symbols/SymbolsIconsIM";
import { SymbolsLegendsIM } from "../symbols/SymbolsLegendsIM";
import type { Mesh } from "../Mesh";
import { LinksIndicatorsIM } from "../links/LinksIndicatorsIM";
import { Logger } from "../../utils/Logger";
import { Group } from "../groups/Group";
import { md5 } from 'js-md5';
import { SymbolsPadsIM } from "../symbols/SymbolsPadsIM";
import { GroupsLegendsIM } from "../groups/GroupsLegendsIM";
import { GroupsShapesTopBM } from "../groups/GroupShapeTopBM";
import { GroupsShapesSideBM } from "../groups/GroupShapeSideBM";
import { LinksPortsIM } from "../links/LinksPortsIM";
import { DefaultLink } from "../links/types/DefaultLink";
import { symbolTypesMap } from "../symbols/types/SymbolTypeMap";
import { Symbol } from "../symbols/Symbol";
import { SerializedSymbol } from "../symbols/BaseSymbol";
import { Link } from "../links/Link";
import { SerializedLink } from "../links/BaseLink";
import { SymbolShared } from "../symbols/SymbolShared";
import { LinkShared } from "../links/LinkShared";
import { LinksDuplicatesShapesBM } from "../links/LinksDuplicatesShapesBM";

export class Repository {
	static symbols: Map<string, Symbol> = new Map();
    static symbolsByTypeAndExternalId: Map<string, Symbol> = new Map();
	static links: Map<string, Link> = new Map();
	static groups: Map<string, Group> = new Map();

	static mesh: Mesh | null = null;

	static sharedSymbols: Map<string, SymbolShared> = new Map();
	static sharedLinks: Map<string, LinkShared> = new Map();

	static symbolsPadsMesh: SymbolsPadsIM | null = null;
	static symbolsIconsMesh: SymbolsIconsIM | null = null;
	static symbolsLegendsMesh: SymbolsLegendsIM | null = null;
	static symbolsShapesMeshes: Map<string, SymbolsShapesIM> = new Map();

	static linksPortsMesh: LinksPortsIM | null = null;
	static linksShapesMesh: LinksShapesBM | null = null;
	static linksIndicatorsMesh: LinksIndicatorsIM | null = null;

	static groupsShapesTopMesh: GroupsShapesTopBM |null = null;
	static groupsShapesSideMesh: GroupsShapesSideBM |null = null;
	static groupsLegendsMesh: GroupsLegendsIM | null = null;

	static linksDuplicatesShapesMesh: LinksDuplicatesShapesBM | null = null;

	static uniqueSymbolTypeGroups: Map<string, {serializedSymbols: SerializedSymbol[], class: new (...args: any[]) => any}> = new Map();

	static assignSymbolTypes(symbols: SerializedSymbol[]){
		for(let i = 0; i < symbols.length; i++){
			let type = symbols[i].type;
			if (!symbolTypesMap.get(type)) {
				Logger.warn(`Symbol definition for '${type}' does not exist.`);
				type = 'default';
			}

			const symbolType = symbolTypesMap.get(type)!;

			if(!Repository.uniqueSymbolTypeGroups.has(symbolType.key)){
				Repository.uniqueSymbolTypeGroups.set(symbolType.key, {serializedSymbols: [symbols[i]], class: symbolType.class});
			}else{
				Repository.uniqueSymbolTypeGroups.get(symbolType.key)!.serializedSymbols.push(symbols[i]);
			}
		}

		return Repository.uniqueSymbolTypeGroups;
	}

	static getTotalLinks(links: SerializedLink[]): number {
		let totalLinksCount = 0;
		const uniqueLinks = [];
		for (let i = 0; i < links.length; i++){
			if (!links[i].source || !links[i].target) continue;

			if(Repository.symbols.get(links[i].source!) === undefined) {
				// console.error(`Link source Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}
			if(Repository.symbols.get(links[i].target!) === undefined) {
				// console.error(`Link target Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}

			// Links can be duplicated so we have to check the 'source'/'target' key
			const key = `${links[i].source}:${links[i].target}`;
			if(uniqueLinks.indexOf(key) !== -1){
				// link is duplicated
				continue;
			}

			uniqueLinks.push(key);
			totalLinksCount++;
		}

		return totalLinksCount;
	}

	static getTotalGroups(symbols: SerializedSymbol[]): number {
		let totalGroupsCount = 0;
		const uniqueGroups: string[] = [];

		for(let i = 0; i < symbols.length; i++){
			const groupId = (symbols[i].attributes as any).folder.id;

			if(uniqueGroups.indexOf(groupId) !== -1){
				// group is not unique
				continue;
			}

			uniqueGroups.push(groupId);
			totalGroupsCount++;
		}

		return totalGroupsCount;
	}

	static getMaxDuplicatesLinks(symbols: SerializedSymbol[]): number {
		return symbols.length;
	}

	static buildSymbols(serializedSymbols: SerializedSymbol[]): Symbol[] {
		Symbol.globalCount = 0;

		// We create the necessary 'shared' instanced meshes
		Logger.time('[perf] mesh: init icons and legends');

		Repository.symbolsPadsMesh = new SymbolsPadsIM(serializedSymbols.length);
		Repository.symbolsIconsMesh = new SymbolsIconsIM(serializedSymbols.length);
		Repository.symbolsLegendsMesh = new SymbolsLegendsIM(serializedSymbols.length);

		Logger.timeEnd('[perf] mesh: init icons and legends');

		const symbols: Symbol[] = [];

		Repository.uniqueSymbolTypeGroups.forEach((item, type) => {
			const klass = item.class;

			let shapeMesh = Repository.symbolsShapesMeshes.get(type);
			if(!shapeMesh){
				shapeMesh = new SymbolsShapesIM(klass, item.serializedSymbols.length);
				Repository.symbolsShapesMeshes.set(type, shapeMesh);
			}

			for (let i = 0; i < item.serializedSymbols.length;i++){
				const instance = new klass(item.serializedSymbols[i], shapeMesh);
				this.symbols.set(item.serializedSymbols[i].id, instance);

				// @TODO create symbols indexes move convenient way also for filtering in the future
				const symbolByTypeAndExternalIdIndex = `${(instance as Symbol).originalData?.type}:${(instance as Symbol).originalData?.external_id}`;
				this.symbolsByTypeAndExternalId.set(symbolByTypeAndExternalIdIndex, instance);

				symbols.push(instance);
			}
		});

		return symbols;
	}

	static buildSharedSymbols(serializedSharedSymbols: SerializedSymbol[]): SymbolShared[] {
		const sharedSymbols: SymbolShared[] = [];

		for(let i = 0; i < serializedSharedSymbols.length; i++){
			const sharedSymbol = new SymbolShared(serializedSharedSymbols[i]);
			this.sharedSymbols.set(serializedSharedSymbols[i].id, sharedSymbol);
			sharedSymbols.push(sharedSymbol);
		}

		return sharedSymbols;
	}

	static buildLinks(serializedLinks: SerializedLink[]): Link[] {
		if (!serializedLinks.length) {
			return [];
		}

		const totalLinks = Repository.getTotalLinks(serializedLinks);

		Repository.linksPortsMesh = new LinksPortsIM(totalLinks * 2); // two ports per link
		Repository.linksShapesMesh = new LinksShapesBM(totalLinks);
		Repository.linksIndicatorsMesh = new LinksIndicatorsIM(totalLinks);

		const links: Link[] = [];

		for (let i = 0; i < serializedLinks.length;i++){
			// Links sometimes don't have source AND target set, so we have to check that and skip the loop step in that case
			if (!serializedLinks[i].source || !serializedLinks[i].target) continue;

			if(Repository.symbols.get(serializedLinks[i].source!) === undefined) {
				// Logger.error(`Link source Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}
			if(Repository.symbols.get(serializedLinks[i].target!) === undefined) {
				// Logger.error(`Link target Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}

			// Links can be duplicated so we have to check the 'source'/'target' key
			const key = `${serializedLinks[i].source}:${serializedLinks[i].target}`;
			if(this.links.has(key)){
				// link is duplicated
				continue;
			}

			const instance = new DefaultLink(serializedLinks[i]);
			this.links.set(key, instance);
			links.push(instance);
		}

		return links;
	}

	static buildSharedLinks(serializedSharedLinks: SerializedLink[]): LinkShared[] {
		const sharedLinks: LinkShared[] = [];

		for(let i = 0; i < serializedSharedLinks.length; i++){
			const sharedLink = new LinkShared(serializedSharedLinks[i]);
			this.sharedLinks.set(serializedSharedLinks[i].id, sharedLink);
			sharedLinks.push(sharedLink);
		}

		return sharedLinks;
	}

	static buildGroups(serializedSymbols: SerializedSymbol[]): Group[] {
		const totalGroups = Repository.getTotalGroups(serializedSymbols);

		Repository.groupsShapesTopMesh = new GroupsShapesTopBM(totalGroups);
		Repository.groupsShapesSideMesh = new GroupsShapesSideBM(totalGroups);
		Repository.groupsLegendsMesh = new GroupsLegendsIM(totalGroups);

		const groupsMap = new Map<string, SerializedSymbol[]>();

		const groups: Group[] = [];

		serializedSymbols.forEach(symbol => {
			const folderId = (symbol.attributes as any).folder.id;
			const folderName = (symbol.attributes as any).folder.name;
			if (!groupsMap.has(folderId)) {
				groupsMap.set(folderId, []);

				const group = new Group();
				group.id = folderId;
				group.title = folderName;
				this.groups.set(folderId, group);
				groups.push(group);
			}
			groupsMap.get(folderId)!.push(symbol);
		});

		return groups;
	}

	static getSymbolsIndex(symbols?: Symbol[]) {
		const indexes = [];
		for (const symbol of (symbols ?? this.symbols.values())) {
			indexes.push(`${symbol.id}:${symbol.groupId}`);
		}
		return md5(indexes.sort().join(':'));
	}

	static getLinksIndex(links?: Link[]) {
		const indexes = [];
		for (const link of (links ?? this.links.values())) {
			indexes.push(`${link.id}:${link.source?.id || ''}:${link.target?.id || ''}`);
		}
		return md5(indexes.sort().join(':'));
	}

	static clear(){
		Repository.uniqueSymbolTypeGroups.clear();

		Repository.symbols.clear();
		Repository.symbolsByTypeAndExternalId.clear();
		Repository.links.clear();
		Repository.groups.clear();

		Repository.sharedSymbols.clear();
		Repository.sharedLinks.clear();

		Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
			symbolShapeMesh.dispose();
		});

		Repository.symbolsShapesMeshes.clear();

		Repository.linksPortsMesh?.dispose();
		Repository.linksShapesMesh?.dispose();
		Repository.linksIndicatorsMesh?.dispose();
		Repository.symbolsPadsMesh?.dispose();
		Repository.symbolsIconsMesh?.dispose();
		Repository.symbolsLegendsMesh?.dispose();
		Repository.groupsLegendsMesh?.dispose();
		Repository.groupsShapesTopMesh?.dispose();
		Repository.groupsShapesSideMesh?.dispose();
		Repository.linksDuplicatesShapesMesh?.dispose();
		Repository.symbolsPadsMesh = null;
		Repository.linksPortsMesh = null;
		Repository.linksShapesMesh = null;
		Repository.linksIndicatorsMesh = null;
		Repository.symbolsIconsMesh = null;
		Repository.symbolsLegendsMesh = null;
		Repository.groupsLegendsMesh = null;
		Repository.groupsShapesTopMesh = null;
		Repository.groupsShapesSideMesh = null;
		Repository.linksDuplicatesShapesMesh = null;

		Repository.mesh = null;
	}
}
