import type {StateCreator} from 'zustand'
import {v4 as uuidv4} from 'uuid';

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    LOADING = 'loading',
}

export enum LoadingNotifications {
    MESH_LOADING = 'meshLoading',
    MESH_LAYOUTING = 'meshLayouting',
}

export enum InfoNotifications {
    IDLE_MODE = 'idleMode',
}

export type Notification = {
    id: string;
    type: NotificationType;
    title: string;
    show: boolean;
    message?: string;
}

export interface NotificationSlice {
    notifications: Notification[];
    addNotification: (type: NotificationType, title: string, message?: string, id?: string) => Notification | undefined;
    removeNotification: (id: string) => void;
}

export const createNotificationSlice: StateCreator<NotificationSlice> = (set, get) => ({
    notifications: [],
    addNotification: (type, title, message, id) => {

        let update = false;
        // check if already exists
        if (id && get().notifications.find((item) => {
            return id === item.id
        })) {
            update = true;
        }

        const newNotification: Notification = {
            id: id ?? uuidv4(),
            type,
            title,
            message,
            show: false
        };

        set((state) => (
            {notifications: update ? state.notifications.map((item) => {
                if (item.id === id) {
                    item.title = title;
                    item.message = message;
                }
                return item;
                }) : [...state.notifications, newNotification]}
        ))

        if (update) {
            return;
        }

        // need this because transitions don't work on newly added elements
        setTimeout(() => {
            set((state) => (
                {
                    notifications: state.notifications.map((notification) => {
                        if (notification.id === newNotification.id) {
                            notification.show = true;
                        }
                        return notification;
                    })
                }
            ))
        }, 0);

        // remove after 3 sec
        if (type !== NotificationType.LOADING && type !== NotificationType.INFO) {
            setTimeout(() => {
                get().removeNotification(newNotification.id);
            }, 2000);
        }

        return newNotification;
    },
    removeNotification: (id: string) => {

        // set show to false
        set((state) => (
            {
                notifications: state.notifications.map((notification) => {
                    if (notification.id === id) {
                        notification.show = false;
                    }
                    return notification;
                })
            }
        ))

        setTimeout(() => {
            // remove
            set((state) => (
                {notifications: state.notifications.filter((notification) => notification.id !== id)}
            ))
        }, 500)
    }
})
