export interface SymbolDisplayableProp {
	key: string,
	label: string
}

export const defaultDisplayProps: SymbolDisplayableProp[] = [
    {
        key: 'type',
        label: 'Type',
    },
    {
        key: 'url',
        label: 'Url',
    }, {
        key: 'external_id',
        label: 'ID'
    }
];

export const symbolDisplayPropsMap: Map<string, SymbolDisplayableProp[]> = new Map([
    ["airtable:table",
    [{
        key: 'attributes.base.title',
        label: 'Base'
    }]
    ],
    ["make:datastore",
    [{
        key: 'attributes.size',
        label: 'Size'
    },
    {
        key: 'attributes.maxSize',
        label: 'Max Size'
    }]
    ],
    ["make:scenario",
    [{
        key: 'attributes.organization.name',
        label: 'Organization'
    },
    {
        key: 'attributes.team.name',
        label: 'Team'
    },
    {
        key: 'attributes.folder.name',
        label: 'Folder'
    }]
    ],
    ["make:webhook",
    [{
        key: 'attributes.limit',
        label: 'Limit'
    },
    {
        key: 'attributes.queue',
        label: 'Queue'
    },
    {
        key: 'attributes.url',
        label: 'Webhook'
    }]
    ],

]);