import * as THREE from 'three';

import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { LEGENDS_ATLAS_SCALE, LEGENDS_FINAL_ZOOM, LEGENDS_INITIAL_ZOOM, LEGEND_HEIGHT, LEGEND_POSITION_Y_OFFSET, LEGEND_WIDTH, SCALE_FACTOR } from '../common/constants';
import { CosmosThree } from '../CosmosThree';
import { SymbolLegendsTextureGenerator } from '../utils/three/SymbolLegendsTextureGenerator';
import { BaseObject } from '../common/BaseObject';
import { SymbolLegend } from './SymbolLegend';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';
import { ExtensionAtlasThemeable } from '../utils/materials/extensions/ExtensionAtlasThemeable';
import { Repository } from '../common/Repository';

export class SymbolsLegendsIM extends BaseInstancedMesh{

	textureGenerator: SymbolLegendsTextureGenerator;
    texturePositions: THREE.InstancedBufferAttribute;
	textureSizes: THREE.InstancedBufferAttribute;

	textNeedsUpdate = false;

    constructor(size : number){

		const textureGenerator = new SymbolLegendsTextureGenerator();

        const geometry = new THREE.PlaneGeometry(LEGEND_WIDTH / SCALE_FACTOR, LEGEND_HEIGHT / SCALE_FACTOR, 1, 1);
        
        const texturePositions = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);
		const textureSizes = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);

		geometry.setAttribute('texOffset', texturePositions);
		geometry.setAttribute('texSize', textureSizes);

		// We use a THREE.MeshBasicMaterial so the lighting doesn't affect the legends
		const material = new (ExtendedMaterial as any)(
            THREE.MeshBasicMaterial,
            [ExtensionAtlasThemeable, ExtensionOpacity],
            {
				color: new THREE.Color("#ffffff"),
                mapLight: textureGenerator.textureLight,
				mapDark: textureGenerator.textureDark,
                atlasSize: new THREE.Vector2(textureGenerator.width, textureGenerator.height),
				blendFactor: CosmosThree.legendBlendFactor,
                transparent: true,
				// ----------------------
				// Seems canvas textures always have premultiplied alpha,
				// for some reason setting premultipliedAlpha to the material doesn't work
				// so we have to override the blending funtion
				/* blending: THREE.CustomBlending,
				blendEquation: THREE.AddEquation,
				blendSrc: THREE.OneFactor,
				blendDst: THREE.OneMinusSrcAlphaFactor, */
				// -----------------------
				depthWrite: false, // If this is removed something weird happens with the legends when they overlap each other
                //wireframe: true
            },
            { debug: false }
        );

        super(geometry, material, size);

		this.textureGenerator = textureGenerator;
        this.texturePositions = texturePositions;
		this.textureSizes = textureSizes;

		this.name = "symbolLegendsInstancedMesh";
    }

	override sync (){
		/* Logger.time('[perf] mesh: update matrices'); */
		for(let i = 0 ; i < this.elems.length; i++){
			if(this.visible){
				const elem = this.elems[i];
				this.syncMatrix(elem);
				if(elem.opacityNeedsUpdate){
                    this.syncOpacity(elem);
                }
			}
		}
		/* Logger.timeEnd('[perf] mesh: update matrices'); */

		if(this.textNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i] as SymbolLegend;
                if(elem.textNeedsUpdate){
                    this.syncText(elem);
                }
            }
        }

		// We want to make the legends disappear depending on camera zoom value
		this.updateOpacityFromZoom();
		
	}

	override syncMatrix(elem: BaseObject){
		if(elem.three.parent){
			this.vector.setFromMatrixPosition(elem.three.parent.matrix);

			this.vector.add(CosmosThree.meshOffset);
			this.vector.project(CosmosThree.graphCamera);

			/* // Convert normalized device coordinates (NDC) to screen coordinates
			this.vector.x = (this.vector.x * 0.5 + 0.5) * CosmosThree.renderer.domElement.width;
			this.vector.y = (this.vector.y * -0.5 + 0.5) * CosmosThree.renderer.domElement.height;
		
			// Round to the nearest pixel
			this.vector.x = Math.round(this.vector.x);
			this.vector.y = Math.round(this.vector.y);
		
			// Convert back to normalized device coordinates
			this.vector.x = (this.vector.x / CosmosThree.renderer.domElement.width) * 2 - 1;
			this.vector.y = -(this.vector.y / CosmosThree.renderer.domElement.height) * 2 + 1; */

			this.vector.unproject(CosmosThree.guiCamera);

			// Update the matrices of the objects in the scene
			this.vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

			this.mtx.makeTranslation(this.vector);

			// We apply the scale of the legend to efectively 'hide' the legend if necessary
			// The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
			this.mtx.scale(elem.three.scale);

			this.setMatrixAt(elem.instanceId, this.mtx);

			this.instanceMatrix.needsUpdate = true;
		}
	}

	syncText(elem: SymbolLegend){
        elem.textNeedsUpdate = false;
		const legendImageData = this.textureGenerator.legendsPositions.get(elem.instanceId + "");
        if(legendImageData){
			this.setTextAt(elem.instanceId, legendImageData.x, legendImageData.y, LEGEND_WIDTH * LEGENDS_ATLAS_SCALE, LEGEND_HEIGHT * LEGENDS_ATLAS_SCALE);
		}
    }

	setTextAt(instanceId: number, x: number, y: number, width: number, height: number){
        this.texturePositions.array [ instanceId * 2 ] = x;
        this.texturePositions.array [ instanceId * 2 + 1 ] = y;
		this.texturePositions.needsUpdate = true;
		this.textureSizes.array [ instanceId * 2 ] = width;
        this.textureSizes.array [ instanceId * 2 + 1 ] = height;
		this.textureSizes.needsUpdate = true;
    }

	updateOpacityFromZoom() {
		const currentZoom = CosmosThree.graphCamera.zoom;
		if(!Repository.mesh?.intoIdleMode){
			if(currentZoom < LEGENDS_FINAL_ZOOM){
				if(currentZoom < LEGENDS_INITIAL_ZOOM){
					this.visible = false;
					(this.material as THREE.Material).opacity = 0;
				}else{
					this.visible = true;
					// Ensure the zoom level is clamped within the initial and final zoom values
					const clampedZoom = Math.min(Math.max(currentZoom, LEGENDS_INITIAL_ZOOM), LEGENDS_FINAL_ZOOM);
				
					// Calculate the interpolation factor based on the current zoom level
					const interpolatedOpacity = (clampedZoom - LEGENDS_INITIAL_ZOOM) / (LEGENDS_FINAL_ZOOM - LEGENDS_INITIAL_ZOOM);
				
					// Set the opacity of the mesh
					(this.material as THREE.Material).opacity = interpolatedOpacity;
				}
			}else{
				this.visible = true;
				(this.material as THREE.Material).opacity = 1;
			}
		}
	}

	override dispose(): this{
		this.textureGenerator.dispose();

		return super.dispose();
	}
}