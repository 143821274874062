import { orderBy } from 'lodash'

export function capitalizeFirstLetter(string: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const orderByCreated = <T>(data: ArrayLike<T> | null): T[] => {
  return orderBy(data, 'created_at', 'desc');
}

export const orderByName = <T>(data: ArrayLike<T> | null): T[] => {
  return orderBy(data, 'name', 'asc');
}

export function collectionToMap(collection: ArrayLike<any>, map?: Map<string, any>, index = 'id'): Map<string, any> {
  if (!map) {
    map = new Map<string, any>();
  }
  for (let i = 0; i < collection.length; i++) {
    map.set(collection[i][index], collection[i]);
  }
  return map;
}

export function camelToKebab(camel: string): string {
  return camel.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
}

export function isMac() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMacOS = /macintosh|mac os x/.test(userAgent);
  return isMacOS;
}