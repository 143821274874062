import {spriteSheetIcons} from '@/utils/SpriteSheetIcons.ts';
import {changeHue} from '@/utils/color.ts';

type Props = {
    iconSlug?: string;
    iconWidth?: number;
    iconHeight?: number;
    iconSrc?: string;
    gradientColor?: string;
    className?: string;
    containerStyle?: Record<string, string>;
    title?: string;
}

export default function AppIcon(
    {
        iconSlug,
        iconWidth,
        iconHeight,
        iconSrc,
        gradientColor,
        className,
        containerStyle,
        title
    }: Props) {

    let spriteStyle = null;
    if (iconSlug && iconWidth && iconHeight) {
        spriteStyle = spriteSheetIcons.getStyle(iconSlug, iconWidth, iconHeight);
    }

    const cStyle: Record<string, string> = containerStyle ?? {};
    if (gradientColor) {
        cStyle.background = `linear-gradient(${changeHue(gradientColor, 15)}, ${gradientColor})`;
    }

    return (
        <div
            className={'' + className || ''}
            style={cStyle}
        >
            {spriteStyle ? (
                <div
                    style={spriteStyle}
                >
                </div>
            ) : (
                <img
                    alt={title || 'Name unknown'}
                    src={iconSrc}
                />
            )}
        </div>
    );
}