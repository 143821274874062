import * as THREE from 'three';

export const ExtensionBatchedTint = {
    name: "extension-batched-tint",
    uniforms: {
      tintAmount: 0,
      tintAmountsTexture: new THREE.Texture(),
      tintColor: new THREE.Color()
    },
    vertexShader: (shader: any) => {
      shader = `
        uniform highp sampler2D tintAmountsTexture; // Define tint amounts texture
        varying float vTintAmount; // Define varying variable to pass to fragment shader
        ${
            shader.replace(
              `#include <batching_vertex>`,
              `
              #include <batching_vertex>

              #ifdef USE_BATCHING
                vTintAmount = texelFetch( tintAmountsTexture, ivec2( 0, getIndirectIndex( gl_DrawID ) ), 0 ).r;
              #endif
				      `
            )
        }
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
      shader = `
        uniform float tintAmount;
        uniform vec3 tintColor;
        varying float vTintAmount; // Receive tint amount value from vertex shader
        ${
            shader.replace(
              '#include <color_fragment>',
              `
              #include <color_fragment>
              
              vec3 color = diffuseColor.rgb;
              vec3 finalColor = mix(color, tintColor, (vTintAmount != 0.0 ? vTintAmount : tintAmount));
              diffuseColor.rgb = finalColor;
              `
            )
        }
      `;
      return shader;
    },
};