export const ExtensionOpacity = {
    name: "extension-opacity",
    vertexShader: (shader: any) => {
      shader = `
        attribute float instOpacity;
        varying float vInstOpacity;
        ${
            shader.replace(
              '#include <uv_vertex>',
              `
              #include <uv_vertex>

              // Pass opacity to the fragment shader
              vInstOpacity = instOpacity;
              `
            )
        }
        `;
      return shader;
    },
    fragmentShader: (shader: any) => {
        shader = `
            varying float vInstOpacity;
            ${
                shader.replace(
                    '#include <color_fragment>',
                    `
                    #include <color_fragment>

                    diffuseColor.a *= vInstOpacity * opacity;
                    `
                )
            }
          `;
        return shader;
    },
};