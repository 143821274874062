export const ExtensionAtlasThemeable = {
    name: "extension-atlas-themeable",
    uniforms: {
      atlasSize: { value: { x: 1, y: 1 } },
      mapLight: { value: null },  // Light theme texture
      mapDark: { value: null },   // Dark theme texture
      blendFactor: { value: 0.0 } // Controls transition between maps
    },
    vertexShader: (shader: any) => {
      shader = `
        uniform vec2 atlasSize;

        attribute vec2 texOffset;
        attribute vec2 texSize;  // Size of the individual texture for this instance

        varying vec2 vMapUv2;     // Declare the varying variable to pass UVs to the fragment shader

        ${shader.replace(
          '#include <uv_vertex>',
          `
          #include <uv_vertex>

          // Calculate the UV offset based on the texture position and size
          float uOffset = texOffset.x / atlasSize.x;
          float vOffset = 1.0 - ((texOffset.y + texSize.y) / atlasSize.y);

          // Adjust the UV coordinates based on the individual texture size
          vMapUv2 = (uv * (texSize / atlasSize)) + vec2(uOffset, vOffset);
          `
        )}
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
      shader = `
        uniform sampler2D mapLight;
        uniform sampler2D mapDark;
        uniform float blendFactor; // 0.0 for light, 1.0 for dark
        varying vec2 vMapUv2;

        ${shader.replace(
          '#include <map_fragment>',
          `
          vec4 finalColor;

          // Conditional sampling: avoid unnecessary texture lookups
          if (blendFactor <= 0.0) {
            // Only sample from the light map
            finalColor = texture2D(mapLight, vMapUv2);
          } else if (blendFactor >= 1.0) {
            // Only sample from the dark map
            finalColor = texture2D(mapDark, vMapUv2);
          } else {
            // Sample from both and blend based on blendFactor
            vec4 lightColor = texture2D(mapLight, vMapUv2);
            vec4 darkColor = texture2D(mapDark, vMapUv2);
            finalColor = mix(lightColor, darkColor, blendFactor);
          }

          // Apply final color to the fragment
          diffuseColor *= finalColor;
          `
        )}
      `;
      return shader;
    }
};
