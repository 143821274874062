import * as THREE from 'three';

import { SYMBOL_HEIGHT, SYMBOL_ROUNDED_TRIANGLE_RADIUS, SYMBOL_SIZE } from '../../common/constants';
import { Symbol } from '@/three/symbols/Symbol';
import { SerializedSymbol } from '../BaseSymbol';

export interface SerializedWebhook extends SerializedSymbol {
	attributes: {
		url: string;
		limit: number;
		queue: number;
		enabled?: boolean
	}
}

export class WebhookSymbol extends Symbol {

	static override getGeometry() {
		const r = SYMBOL_SIZE / 1.25;
		const ha = THREE.MathUtils.degToRad(30);
		const h = SYMBOL_ROUNDED_TRIANGLE_RADIUS * (Math.sin(ha) + Math.cos(ha) * Math.sqrt(3));
		const center = r - Math.min(r, h);

		const base = new THREE.Vector2(0, center);
		const c = new THREE.Vector2();
		const v = new THREE.Vector2();

		const angleStep = Math.PI / 6;

		const shape = new THREE.Shape();
		for (let i = 0; i < 3; i++) {
			const a = angleStep * 4 * i;
			v.copy(base).rotateAround(c, a);
			shape.absarc(v.x, v.y, SYMBOL_ROUNDED_TRIANGLE_RADIUS, angleStep + a, angleStep * 5 + a);
		}

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: SYMBOL_HEIGHT,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).rotateX(-Math.PI/2);

		geometry.clearGroups();

        return geometry;
    }
}