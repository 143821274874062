import * as THREE from 'three';

import { gsap } from "gsap";

import { INTERACTION_ANIMATION_SPEED_FAST, SELECCTION_INDICATOR_Y_POSITION, SELECTION_INDICATOR_INNER_RADIUS, SELECTION_INDICATOR_OUTER_RADIUS } from './constants';
import { Symbol } from '../symbols/Symbol';
import { CosmosThree } from '../CosmosThree';

export class SelectionIndicator extends THREE.Mesh{

    private _tlAnim: gsap.core.Timeline;

    constructor(){
        super();

        this.geometry = this.createGeometry();
        this.material = this.createMaterial();

        this.scale.x = 0;
        this.scale.z = 0;

        this.receiveShadow = true;

        this._tlAnim = new gsap.core.Timeline({paused: true});
		this._tlAnim.add(gsap.fromTo(this.material, {opacity: 1}, {delay: INTERACTION_ANIMATION_SPEED_FAST, duration: INTERACTION_ANIMATION_SPEED_FAST * 2, opacity: 0, ease: "sine.inOut", repeatDelay: INTERACTION_ANIMATION_SPEED_FAST, repeat: -1, yoyo:true }), 0);
    }

    setFrom(symbol: Symbol){
        this.position.copy(symbol.three.position);
		this.position.y += SELECCTION_INDICATOR_Y_POSITION;

        this.scale.x = 0;
        this.scale.z = 0;

        this._tlAnim.play(0);

        gsap.to(this.scale, {duration: INTERACTION_ANIMATION_SPEED_FAST, x: 1, z: 1, ease: "power2.inOut"});
    }

    hide(){
        this._tlAnim.pause();
        gsap.to(this.scale, {duration: INTERACTION_ANIMATION_SPEED_FAST, x: 0, z: 0, ease: "power2.inOut"});
    }

    createGeometry(){
        const geometry = new THREE.RingGeometry( SELECTION_INDICATOR_INNER_RADIUS, SELECTION_INDICATOR_OUTER_RADIUS, 64 ).rotateX(-Math.PI / 2);

        return geometry;
    }

    createMaterial(){
        const material = new THREE.MeshLambertMaterial(
            {
                color: CosmosThree.selectionIndicatorColor,
                transparent: true,
                // wireframe: false
            },
        );

        return material;
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        this.geometry.dispose();
        (this.material as THREE.Material).dispose();

        super.clear();

        return this;
    }

}